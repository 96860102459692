import {
  graphql
} from 'react-relay'






const query = graphql`
    query getContactQuery($id: String!) {
        getContact (id: $id){
            id
            name
            customerId
            professionId
            customer {id, name}
            profession{id, name}
            contactItems{
              id
              information
              contactMediumId
              contactMedium{
                id
                name
              }
            }
            
      }
    }
  `

export default query;