import palette from '../palette';
export default {
    root: {
        paddingLeft: 2,
        paddingRight: 1,
    },
    highlight: {
        color: palette.text.primary,
        backgroundColor: palette.secondary.dark,
    },
    title: {
        flex: '1 1 100%',
    },
};