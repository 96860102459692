/**
 * @flow
 * @relayHash 5432b164ecc9b5d58be8509a8876f099
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getSettlementQueryVariables = {|
  id: string
|};
export type getSettlementQueryResponse = {|
  +getSettlement: {|
    +id: string,
    +date: any,
    +ref: ?string,
    +amount: number,
    +paymentModeId: string,
    +accountId: string,
    +currencyId: string,
    +customerId: string,
    +createdAt: any,
    +updatedAt: any,
    +deletedAt: ?any,
    +customer: ?{|
      +id: string,
      +name: string,
    |},
    +paymentMode: ?{|
      +id: string,
      +name: string,
    |},
    +account: ?{|
      +id: string,
      +name: string,
    |},
    +currency: ?{|
      +id: string,
      +name: string,
    |},
    +settlementItem: ?$ReadOnlyArray<{|
      +id: string,
      +settlementId: string,
      +invoiceId: ?string,
      +amount: number,
      +gapAmount: ?number,
      +invoice: ?{|
        +id: string,
        +date: any,
        +num: string,
        +tax: ?number,
        +total: number,
        +totalTTC: number,
        +resteToPayed: ?number,
      |},
    |}>,
  |}
|};
export type getSettlementQuery = {|
  variables: getSettlementQueryVariables,
  response: getSettlementQueryResponse,
|};
*/


/*
query getSettlementQuery(
  $id: String!
) {
  getSettlement(id: $id) {
    id
    date
    ref
    amount
    paymentModeId
    accountId
    currencyId
    customerId
    createdAt
    updatedAt
    deletedAt
    customer {
      id
      name
    }
    paymentMode {
      id
      name
    }
    account {
      id
      name
    }
    currency {
      id
      name
    }
    settlementItem {
      id
      settlementId
      invoiceId
      amount
      gapAmount
      invoice {
        id
        date
        num
        tax
        total
        totalTTC
        resteToPayed
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "date",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amount",
  "args": null,
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
],
v5 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getSettlement",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "SettlementModel",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ref",
        "args": null,
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "paymentModeId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "accountId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "currencyId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "customerId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "updatedAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "customer",
        "storageKey": null,
        "args": null,
        "concreteType": "CustomerModel",
        "plural": false,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "paymentMode",
        "storageKey": null,
        "args": null,
        "concreteType": "PaymentModeModel",
        "plural": false,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "account",
        "storageKey": null,
        "args": null,
        "concreteType": "AccountModel",
        "plural": false,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currency",
        "storageKey": null,
        "args": null,
        "concreteType": "CurrencyModel",
        "plural": false,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "settlementItem",
        "storageKey": null,
        "args": null,
        "concreteType": "SettlementItemModel",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "settlementId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "invoiceId",
            "args": null,
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "gapAmount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "invoice",
            "storageKey": null,
            "args": null,
            "concreteType": "InvoiceModel",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "num",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "tax",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "total",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalTTC",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "resteToPayed",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getSettlementQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v5/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getSettlementQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v5/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getSettlementQuery",
    "id": null,
    "text": "query getSettlementQuery(\n  $id: String!\n) {\n  getSettlement(id: $id) {\n    id\n    date\n    ref\n    amount\n    paymentModeId\n    accountId\n    currencyId\n    customerId\n    createdAt\n    updatedAt\n    deletedAt\n    customer {\n      id\n      name\n    }\n    paymentMode {\n      id\n      name\n    }\n    account {\n      id\n      name\n    }\n    currency {\n      id\n      name\n    }\n    settlementItem {\n      id\n      settlementId\n      invoiceId\n      amount\n      gapAmount\n      invoice {\n        id\n        date\n        num\n        tax\n        total\n        totalTTC\n        resteToPayed\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aac8c65f2e20e849571f57aa2b181f81';
module.exports = node;
