/**
 * @flow
 * @relayHash 45b14da0a1cd10d07ee284e87642946d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaginationInput = {|
  limit: number,
  offset: number,
|};
export type OrderInput = {|
  modele?: ?string,
  field: string,
  direction: string,
|};
export type getAllSettlementQueryVariables = {|
  pagination?: ?PaginationInput,
  sorts?: ?$ReadOnlyArray<OrderInput>,
  where?: ?any,
|};
export type getAllSettlementQueryResponse = {|
  +getAllSettlement: {|
    +count: number,
    +items: $ReadOnlyArray<{|
      +id: string,
      +date: any,
      +ref: ?string,
      +amount: number,
      +paymentModeId: string,
      +accountId: string,
      +currencyId: string,
      +customerId: string,
      +createdAt: any,
      +updatedAt: any,
      +deletedAt: ?any,
      +customer: ?{|
        +id: string,
        +name: string,
      |},
      +paymentMode: ?{|
        +id: string,
        +name: string,
      |},
      +account: ?{|
        +id: string,
        +name: string,
      |},
      +currency: ?{|
        +id: string,
        +name: string,
      |},
    |}>,
  |}
|};
export type getAllSettlementQuery = {|
  variables: getAllSettlementQueryVariables,
  response: getAllSettlementQueryResponse,
|};
*/


/*
query getAllSettlementQuery(
  $pagination: PaginationInput
  $sorts: [OrderInput!]
  $where: JSON
) {
  getAllSettlement(pagination: $pagination, sorts: $sorts, where: $where) {
    count
    items {
      id
      date
      ref
      amount
      paymentModeId
      accountId
      currencyId
      customerId
      createdAt
      updatedAt
      deletedAt
      customer {
        id
        name
      }
      paymentMode {
        id
        name
      }
      account {
        id
        name
      }
      currency {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "pagination",
    "type": "PaginationInput",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sorts",
    "type": "[OrderInput!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "JSON",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getAllSettlement",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "pagination",
        "variableName": "pagination"
      },
      {
        "kind": "Variable",
        "name": "sorts",
        "variableName": "sorts"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "SettlementWithCountModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "count",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "items",
        "storageKey": null,
        "args": null,
        "concreteType": "SettlementModel",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "date",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "ref",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "amount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "paymentModeId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "accountId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "currencyId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "customerId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "deletedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customer",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerModel",
            "plural": false,
            "selections": (v2/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "paymentMode",
            "storageKey": null,
            "args": null,
            "concreteType": "PaymentModeModel",
            "plural": false,
            "selections": (v2/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "account",
            "storageKey": null,
            "args": null,
            "concreteType": "AccountModel",
            "plural": false,
            "selections": (v2/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "currency",
            "storageKey": null,
            "args": null,
            "concreteType": "CurrencyModel",
            "plural": false,
            "selections": (v2/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getAllSettlementQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getAllSettlementQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getAllSettlementQuery",
    "id": null,
    "text": "query getAllSettlementQuery(\n  $pagination: PaginationInput\n  $sorts: [OrderInput!]\n  $where: JSON\n) {\n  getAllSettlement(pagination: $pagination, sorts: $sorts, where: $where) {\n    count\n    items {\n      id\n      date\n      ref\n      amount\n      paymentModeId\n      accountId\n      currencyId\n      customerId\n      createdAt\n      updatedAt\n      deletedAt\n      customer {\n        id\n        name\n      }\n      paymentMode {\n        id\n        name\n      }\n      account {\n        id\n        name\n      }\n      currency {\n        id\n        name\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f8e94e95f129b01a34db9cfa7472f20';
module.exports = node;
