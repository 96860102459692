import { graphql } from "react-relay";

const query = graphql`
  query getInvoiceQuery($id: String!) {
    getInvoice(id: $id) {
      id
      title
      description
      address
      date
      num
      tax
      advancePayment
      postStamp
      discountType
      discountValue
      total
      totalTTC
      resteToPayed
      billingAddressId
      currencyId
      customerId
      quotationId
      createdAt
      updatedAt
      deletedAt
      currency {
        id
        name
        code
      }
      customer {
        id
        name
      }
      invoiceItem {
        id
        projectId
        designation
        description
        quantity
        unityId
        discount
        unitPrice
        total
        unity {
          id
          name
          code
        }
        project {
          id
          name
        }
      }
    }
  }
`;

export default query;
