/**
 * @flow
 * @relayHash ecee2a3bd34690fd2a8ec0697b257c7a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BillingAddressUpdateInput = {|
  id: string,
  address: string,
  isDefault: boolean,
  countryId: string,
  governorateId: string,
  cityId: string,
  customerId?: ?string,
|};
export type UpdateBillingAddressMutationVariables = {|
  data: BillingAddressUpdateInput
|};
export type UpdateBillingAddressMutationResponse = {|
  +updateBillingAddress: {|
    +id: string
  |}
|};
export type UpdateBillingAddressMutation = {|
  variables: UpdateBillingAddressMutationVariables,
  response: UpdateBillingAddressMutationResponse,
|};
*/


/*
mutation UpdateBillingAddressMutation(
  $data: BillingAddressUpdateInput!
) {
  updateBillingAddress(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "BillingAddressUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateBillingAddress",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "BillingAddressModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateBillingAddressMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateBillingAddressMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateBillingAddressMutation",
    "id": null,
    "text": "mutation UpdateBillingAddressMutation(\n  $data: BillingAddressUpdateInput!\n) {\n  updateBillingAddress(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c68b91a34cf48b42384036f1a77f173d';
module.exports = node;
