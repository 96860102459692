import axios from 'axios';
const URL_API = process.env.REACT_APP_API_URL;
const END_POINTS_PDF_GENERATOR =  `${URL_API}public/generatePdf` ;


const options = {
	headers: {
		'Accept': 'application/pdf',
	},
	responseType: 'blob'
};

const apiGeneratePdf = (data) => {
	return axios.post(END_POINTS_PDF_GENERATOR,  data, options);     
};



export {
	apiGeneratePdf
};