import {
    graphql
} from 'react-relay'






const query = graphql`
    query getAllProjectQuery($pagination: PaginationInput, $sorts: [OrderInput!], $where: JSON) {
        getAllProject (pagination: $pagination, sorts: $sorts, where: $where){
            count,
            items{
                id, 
                name, 
                createdAt, 
                updatedAt, 
                customer{id, name}
                }
      }
    }
  `

export default query;