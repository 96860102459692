/**
 * @flow
 * @relayHash 2648937aa35cae5e3857d591d43ed4d7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaginationInput = {|
  limit: number,
  offset: number,
|};
export type OrderInput = {|
  modele?: ?string,
  field: string,
  direction: string,
|};
export type getAllProfessionQueryVariables = {|
  pagination?: ?PaginationInput,
  sorts?: ?$ReadOnlyArray<OrderInput>,
  where?: ?any,
|};
export type getAllProfessionQueryResponse = {|
  +getAllProfession: {|
    +count: number,
    +items: $ReadOnlyArray<{|
      +id: string,
      +name: string,
      +createdAt: any,
      +updatedAt: any,
    |}>,
  |}
|};
export type getAllProfessionQuery = {|
  variables: getAllProfessionQueryVariables,
  response: getAllProfessionQueryResponse,
|};
*/


/*
query getAllProfessionQuery(
  $pagination: PaginationInput
  $sorts: [OrderInput!]
  $where: JSON
) {
  getAllProfession(pagination: $pagination, sorts: $sorts, where: $where) {
    count
    items {
      id
      name
      createdAt
      updatedAt
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "pagination",
    "type": "PaginationInput",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sorts",
    "type": "[OrderInput!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "JSON",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getAllProfession",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "pagination",
        "variableName": "pagination"
      },
      {
        "kind": "Variable",
        "name": "sorts",
        "variableName": "sorts"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "ProfessionWithCountModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "count",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "items",
        "storageKey": null,
        "args": null,
        "concreteType": "ProfessionModel",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getAllProfessionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getAllProfessionQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getAllProfessionQuery",
    "id": null,
    "text": "query getAllProfessionQuery(\n  $pagination: PaginationInput\n  $sorts: [OrderInput!]\n  $where: JSON\n) {\n  getAllProfession(pagination: $pagination, sorts: $sorts, where: $where) {\n    count\n    items {\n      id\n      name\n      createdAt\n      updatedAt\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12145e536bd8feba018c36bd479d1e41';
module.exports = node;
