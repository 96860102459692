import React, { Component } from "react";
import Event from "./Event";



class Events extends Component {
    constructor(props) {
        super(props)
        this.enableResizing = {
            bottom: false,
            bottomLeft: false,
            bottomRight: false,
            left: true,
            right: true,
            top: false,
            topLeft: false,
            topRight: false
        };
    }

    render() {
        return (
            <>
                {
                    (this.props.events || []).map(
                        (event, i) => {
                            if (Object.keys(event).indexOf('position') >= 0) {
                                const width = event.position.end.x - event.position.start.x;
                                return (
                                    <Event
                                        size={{ width: width, height: this.props.eventDefaultHeight }}
                                        position={{ ...event.position.start }}
                                        key={i}
                                        onDragStop={(e, position) => this.props.onDragStop(position, event)}
                                        onResizeStop={(e, dir, size, position) => this.props.onResizeStop(e, dir, size, position, event)}
                                        // onResize={(e, dir, size, position) => this.props.onResizeStop(e, dir, size, position, event)}
                                        handelClickEvent={(e) => this.props.handelClickEvent(event)}
                                        enableResizing={{ ...this.enableResizing, ...event._resizable }}
                                        draggable={event._draggable}>


                                        <div className="learfix">
                                            <div className="float-left">{(!event._resizable.left) ? '....' : ''} {event.name}</div>
                                            <div className="float-right">{(!event._resizable.right) ? '....' : ''}</div>
                                        </div>

                                    </Event>
                                )
                            }
                        })
                }
            </>
        )
    }
}
Events.defaultProps = {
    handelClickEvent: (event) => console.log('event:', event)
}
export default Events;