
import React from "react";
import Quotation from '../../components/Quotation/Quotation';

import SalesWrapper from '../../components/SalesWrapper';

export default (props) => {

    return (
        <SalesWrapper {...props}>
            <Quotation borderCard={false} />
        </SalesWrapper>
    );

}

