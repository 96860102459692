import {
  graphql
} from 'react-relay'






const query = graphql`
    query getSupplierContactQuery($id: String!) {
        getSupplierContact (id: $id){
            id
            name
            supplierId
            professionId
            supplier {id, name}
            profession{id, name}
            supplierContactItems{
              id
              information
              contactMediumId
              contactMedium{
                id
                name
              }
            }
            
      }
    }
  `

export default query;