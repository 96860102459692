export const OPERATOR = {
    and: 'and',
    or: 'or',
    gt: 'gt',
    gte: 'gte',
    lt: 'lt',
    lte: 'lte',
    ne: 'ne',
    eq: 'eq',
    is: 'is',
    not: 'not',
    between: 'between',
    notBetween: 'notBetween',
    in: 'in',
    notIn: 'notIn',
    like: 'like',
    notLike: 'notLike',
    iLike: 'iLike',
    notILike: 'notILike',
    startsWith: 'and',
    endsWith: 'startsWith',
    substring: 'substring',
    regexp: 'regexp',
    notRegexp: 'notRegexp',
    iRegexp: 'iRegexp',
    notIRegexp: 'notIRegexp',
    like: 'like',
    overlap: 'overlap',
    contains: 'contains',
    contained: 'contained',
    any: 'any',
};
