import React, { useState, useEffect } from "react";

/** Graphql */
import { fetchQuery } from 'react-relay';
import getAllContactMediumQuery from '../../../graphql/contact-medium/queries/getAllContactMediumQuery';
import getAllProfessionQuery from '../../../graphql/profession/queries/getAllProfessionQuery';
import getAllSupplierQuery from '../../../graphql/supplier/queries/getAllSupplierQuery';
import UpdateSupplierContactMutation from '../../../graphql/supplier-contact/mutations/UpdateSupplierContactMutation';
import getSupplierContactQuery from '../../../graphql/supplier-contact/queries/getSupplierContactQuery';
import environment from '../../../graphql/Environment';



import { useForm, useFieldArray } from 'react-hook-form';


import { ButtonSave, ButtonCancel } from '../../../components/Buttons';
import DeleteIcon from '@material-ui/icons/Delete';


const itemModel = {
    information: "",
    contactMediumId: null
};

const Form = (props) => {


    const { defaultData, contactMediums, professions, suppliers } = props;

    const {
        register,
        control,
        handleSubmit,
        watch, errors,
        getValues,
        setValue
    } = useForm({
        mode: "onChange",
        defaultValues: defaultData
    });


    const {
        fields,
        append,
        remove } = useFieldArray(
            {
                control,
                name: "supplierContactItems"
            }
        );


    /**
* 
*/
    useEffect(() => { }, [register]);


    /**
     * 
     * @param {*} data 
     */
    const update = data => {
        UpdateSupplierContactMutation({ ...data }, (response) => {
            if (response.status === 'OK') {
                props.doClose({
                    ...response, msgSnackbar: {
                        message: 'C bon c fait',
                        variant: 'success',
                        open: true,
                    }
                });
            } else {
                console.log({ error: 'Erreur' });
            }

        });
    }


    /**
     * 
     * @param {*} index 
     */
    const removeItem = (index) => {
        remove(index);
    }


    /**
     * 
     * @param {*} data 
     */
    const onSubmit = data => update(data);

    return (


        <form onSubmit={handleSubmit(onSubmit)} className="row p-5" >
            <input type="hidden" name="id" ref={register({})} />
            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Client:</label>
                <div className="">

                    <select
                        name="supplierId"
                        className={
                            errors.supplierId
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} >
                        <option value=""></option>
                        {
                            suppliers.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>

                    {errors.supplierId && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>




            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Profession:</label>
                <div className="">
                    <select
                        name="professionId"
                        className={
                            errors.professionId
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} >

                        {
                            professions.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>

                    {errors.professionId && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>


            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Nom:</label>
                <div className="">
                    <input
                        type="text"
                        name="name"
                        className={
                            errors.name
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} />
                    {errors.name && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>


            <fieldset className="form-group col-md-12 border-top mt-3">
                <legend className="col-form-label text-secondary p-1" style={{ width: 'auto' }}>Les informations</legend>

                <table className="table table-default">
                    <thead className="">
                        <tr>
                            <th className="text-secondary text-capitalize">Media</th>
                            <th className="text-secondary text-capitalize">Information</th>
                            <th style={{ width: '4%' }} className="text-secondary text-capitalize"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((item, index) => {
                            return (
                                <tr key={item.id}>

                                    <td>
                                        <select
                                            name={`supplierContactItems[${index}].contactMediumId`}
                                            className={
                                                errors?.supplierContactItem && errors?.supplierContactItems[index]?.contactMediumId
                                                    ? "form-control is-invalid"
                                                    : "form-control"}
                                            ref={register({ required: true })} >
                                            <option value=""></option>
                                            {
                                                contactMediums.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                            }
                                        </select>
                                    </td>

                                    <td>
                                        <input
                                            type="text"
                                            name={`supplierContactItems[${index}].information`}
                                            className={
                                                errors?.supplierContactItem && errors?.supplierContactItems[index]?.information
                                                    ? "form-control is-invalid"
                                                    : "form-control"}
                                            ref={register({ required: true })} />
                                    </td>

                                    <td>
                                        <div className="">
                                            {fields.length > 1 && <button type="button" className="btn btn-sm btn-danger" onClick={() => removeItem(index)}>
                                                <DeleteIcon />
                                            </button>}
                                        </div>
                                    </td>


                                </tr>

                            );
                        })}

                        <tr className="border-0 pb-0">
                            <td className="border-0" colSpan={'3'}>
                                <button
                                    className="btn btn-sm btn-dark"
                                    type="button"
                                    onClick={() => append(itemModel)}
                                >
                                    Add
                                </button>
                            </td>
                        </tr>

                    </tbody>
                </table>

            </fieldset>

            <div>

            </div>


            <div className="form-group col-md-12 text-right mt-2">

                <ButtonCancel onClick={() => props.doClose()}></ButtonCancel>
                <ButtonSave type="submit"></ButtonSave>

            </div>
        </form>
    );
}


export default (props) => {

    const supplier = props?.data?.supplier || null;


    /**
    * 
    */
    const [state, setState] = useState({
        dataLoaded: false,
        defaultData: {},
        contactMediums: [],
        professions: [],
        suppliers: [],
    });


    useEffect(() => {
        loadData();
    }, []);



    /**
       * 
       */
    const loadData = () => {
        Promise.all([loadContactMediums(), loadPrefessions(), loadSupplier(), loadDataSupplierContact()])
            .then(data => {
                const [{ getAllContactMedium }, { getAllProfession }, { getAllSupplier }, { getSupplierContact }] = data;

                const defaultData = getDefaultData(getSupplierContact);
                setState({
                    ...state,
                    dataLoaded: true,
                    contactMediums: getAllContactMedium.items,
                    professions: getAllProfession.items,
                    suppliers: getAllSupplier.items,
                    defaultData: defaultData
                });
            })
            .catch(error => {
                console.log('error :', error.message);
            });
    }







    /**
* 
*/
    const loadContactMediums = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllContactMediumQuery, gqlQueryParam);

    }

    /**
    * 
    */
    const loadPrefessions = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllProfessionQuery, gqlQueryParam)
    }

    /**
      * 
      */
    const loadSupplier = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllSupplierQuery, gqlQueryParam)
    }

    /**
   * 
   */
    const loadDataSupplierContact = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getSupplierContactQuery, { ...gqlQueryParam, id: props.data.id });

    }


    const getDefaultData = (data) => {

        return {
            id: data.id,
            name: data.name,
            supplierId: data.supplierId,
            professionId: data.professionId,
            supplierContactItems: data.supplierContactItems.map(item => {
                return {
                    information: item.information,
                    contactMediumId: item.contactMediumId
                }
            })
        };

    }



    return (
        <>
            {
                state.dataLoaded ?
                    (<Form
                        defaultData={state.defaultData}
                        contactMediums={state.contactMediums}
                        professions={state.professions}
                        suppliers={state.suppliers}
                        {...props} />)
                    : '...Loading'
            }
        </>
    )

}