import React from "react";

class ProjectsVersions extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

 

  render() {
    return (
       <div className="content">
          <div className="row">
            <div className="col-md-12">
            ProjectsVersions
            </div>
          </div>
        </div>
    );
  }
}

export default ProjectsVersions;
