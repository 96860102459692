import React, { Component } from "react";
import { Formik } from 'formik';
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import moment from 'moment';
import momentConfig from '../../../config/moment.config';
import "react-datepicker/dist/react-datepicker.css";
moment.locale('fr', momentConfig);


class FormEditEvent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.data.id,
            name: this.props.data.name,
            resource: this.props.data.resource,
            start: this.props.data.start,
            end: this.props.data.end
        };
    }


    render() {
        return (
            <Formik
                initialValues={{ ...this.props.data }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required("Required"),
                    resource: Yup.number().required("Required"),
                    start: Yup.date().required("Required"),
                    end: Yup.date().required("Required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    this.props.submited(values);
                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                    /* and other goodies */
                }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Task:</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        name="name"
                                        className={
                                            errors.name && touched.name
                                                ? "form-control form-control-sm is-invalid"
                                                : "form-control form-control-sm"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name} />
                                    {errors.name && touched.name && (
                                        <div className="invalid-feedback">{errors.name}</div>
                                    )}
                                </div>

                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Collaborateur:</label>
                                <div className="col-sm-8">
                                    <select id="inputState"
                                        name="resource"
                                        className="form-control form-control-sm"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.resource}>
                                        {
                                            (this.props.resources || []).map(resource => (<option key={resource.id} value={resource.id}>{resource.name}</option>))
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Debut:</label>
                                <div className="col-sm-8">
                                    <DatePicker
                                        type="date"
                                        name="start"
                                        className="form-control form-control-sm"
                                        onChange={date => setFieldValue('start', moment(date).startOf('day'))}
                                        onBlur={handleBlur}
                                        selected={moment(values.start).toDate()}
                                        dateFormat="dd-MM-yyyy"
                                        popperPlacement="right" />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Fin:</label>
                                <div className="col-sm-8">
                                    <DatePicker
                                        type="date"
                                        name="end"
                                        className="form-control form-control-sm"
                                        onChange={date => setFieldValue('end', moment(date).endOf('day'))}
                                        onBlur={handleBlur}
                                        selected={moment(values.end).toDate()}
                                        dateFormat="dd-MM-yyyy"
                                        popperPlacement="right"
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <button type="button" onClick={() => this.props.doClose()} className="btn btn-sm btn-secondary">Annuler</button>
                                <button type="submit" className="btn  btn-sm btn-success" disabled={isSubmitting}>Submit</button>
                            </div>
                        </form>

                    )}
            </Formik>
        )
    }
}

export default FormEditEvent;