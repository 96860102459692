import React, { Component } from "react";
import moment from 'moment';
import momentConfig from '../../../config/moment.config';
moment.locale('fr', momentConfig);

class NavBtnGroupContent extends Component {
   

    dayLayout = () => {
        const {dateContext} = this.props;
        return moment(dateContext).format('DD-MMMM-YYYY');
    }

    monthLayout = () => {
        const {dateContext} = this.props;
        return moment(dateContext).format('MMMM-YYYY');
    }

    weekLayout = () => {
        const {period, dateContext} = this.props;
        return moment(dateContext).startOf(period).format('DD') + ' - '
        + moment(dateContext).endOf(period).format('DD') + ' '
        + moment(dateContext).format('MMMM') + ' '
        + moment(dateContext).format('YYYY');
    }

    yearLayout = () => {
        const {dateContext} = this.props;
        return moment(dateContext).format('YYYY');
    }


    mapLayouts = {
        'day' : this.dayLayout,
        'week' : this.weekLayout,
        'month' : this.monthLayout,
        'year' : this.yearLayout
    }

    render() {
        const {period} = this.props;
        return (
            <>
            { this.mapLayouts[period]() }
            </>
        )
    }
}
NavBtnGroupContent.defaultProps = {
    period : 'month',
    dateContext: moment()
};
export default NavBtnGroupContent;