
import React from "react";
import Avatar from '@material-ui/core/Avatar';

class SidebarBrand extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { firstName, lastName } = this.props.userInfo;
        return (
            <>
              <Avatar>A</Avatar> <span className="ml-2"> {firstName + ' ' + lastName} </span>
            </>

        );
    }
}

export default SidebarBrand;
