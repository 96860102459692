
import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import BlocSearch from './components/BlocBasicSearch';

const useStyles = makeStyles(theme => ({
    title: {
      flexGrow: 1,
    },
  }));

const TableToolbar = (props) => {
    
    const classes = useStyles();
    const {countTotal, title, handelSubmitSearch, handelAddClick} = props;

    return (
        <Toolbar >
            <Typography className={classes.title} variant="h6" id="tableTitle">
              {title} ( <small><b>{countTotal}</b> élements </small> )
            </Typography>
            <Typography>
              <BlocSearch onSubmitSearch={handelSubmitSearch} />
            </Typography>

            <Tooltip title="Add">
              <IconButton aria-label="filter list" onClick={handelAddClick}>
                <i className="material-icons"> playlist_add </i>
              </IconButton>
            </Tooltip>
          </Toolbar>
    );
}

TableToolbar.propTypes = {
    countTotal: PropTypes.number,
    title: PropTypes.string,
    handelSubmitSearch: PropTypes.func,
    handelAddClick: PropTypes.func
  };

  export default TableToolbar;