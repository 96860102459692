import {
  graphql
} from 'react-relay'






const query = graphql`
    query getAllSupplierContactQuery($pagination: PaginationInput, $sorts: [OrderInput!], $where: JSON) {
        getAllSupplierContact (pagination: $pagination, sorts: $sorts, where: $where){
            count,
            items{
                  id
                  name
                  supplierId
                  professionId
                  supplier {id, name}
                  profession{id, name}
                }
      }
    }
  `

export default query;