import React, { Component } from 'react';
import '../assets/task.css';

class Event extends Component {

	constructor(props) {
		super(props);
		this.state = {
			style : {
				width: parseFloat(this.props.size.width) + 'px', 
				height: parseFloat(this.props.size.height) + 'px', 
				left: parseFloat(this.props.position.x) + 'px', 
				top: parseFloat(this.props.position.y) + 'px',
				// transform: `translate(${this.props.position.x}px, ${this.props.position.y}px)` 
			}
		};
		this.ref = React.createRef();
		this.startedResize = false;
		this.startedDrag = false;
		this.selectedBottom = null;
		this.mousePositionX = 0;
		this.mousePositionY = 0;
		this.originalWidth = parseFloat(this.props.size.width) + 'px';
		this.originalHeight = parseFloat(this.props.size.height) + 'px';
		this.originalX = parseFloat(this.props.position.x);
		this.originalY = parseFloat(this.props.position.y);
		this.ratio = parseFloat(this.props.size.width) / parseFloat(this.props.size.height);
		this.draging = false;
	}



	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {

			this.setState({...this.state,
				style : {
					width: parseFloat(this.props.size.width) + 'px', 
					height: parseFloat(this.props.size.height) + 'px', 
					left: parseFloat(this.props.position.x) + 'px', 
					top: parseFloat(this.props.position.y) + 'px',
				}
			});

			this.originalWidth = parseFloat(this.props.size.width) + 'px';
			this.originalHeight = parseFloat(this.props.size.height) + 'px';
			this.originalX = parseFloat(this.props.position.x);
			this.originalY = parseFloat(this.props.position.y);
			this.ratio = parseFloat(this.props.size.width) / parseFloat(this.props.size.height);
		}
	  }


 

	/**
     * 
     */
	componentDidMount() {
		this.ref.current.addEventListener('mousedown', this.handleMouseDown);
		this.ref.current.addEventListener('mouseup', this.handleMouseUp);
		this.ref.current.addEventListener('dblclick', this.handleClick);
	}
 
	componentWillUnmount() {
		this.ref.current.removeEventListener('mousedown', this.handleDragIn);
		window.removeEventListener('mousemove', this.handleMouseMove);
		this.ref.current.removeEventListener('mouseup', this.handleMouseUp);
		this.ref.current.removeEventListener('dblclick', this.handleClick);
	}

    /**
     * 
     */
    resizingDirection = {
    	bottom: {classResizerName : 'bottom', resizing : (e) => { this.bottomResizing(e);}},
    	bottomLeft: {classResizerName : 'bottom-left', resizing : (e) => { this.bottomLeftResizing(e);}},
    	bottomRight: {classResizerName : 'bottom-right', resizing : (e) => { this.bottomRightResizing(e);}},
    	left: {classResizerName : 'left', resizing : (e) => { this.leftResizing(e);}},
    	right: {classResizerName : 'right', resizing : (e) => { this.rightResizing(e);}},
    	top: {classResizerName : 'top', resizing : (e) => { this.topResizing(e);}},
    	topLeft: {classResizerName : 'top-left', resizing : (e) => { this.topLeftResizing(e);}},
    	topRight: {classResizerName : 'top-right', resizing : (e) => { this.topRightResizing(e);}},
    }

    /**
     * 
     */
    getTranslateX = (e) => {
    	return (e.clientX - this.mousePositionX);
    }


    /**
     * 
     */
    getTranslateY = (e) => {
    	return (e.clientY - this.mousePositionY);
    }

    /**
     * 
     */
    bottomResizing = (e) => {
    	/* const translate = (((this.props.rotate / 90) % 2) === 0) ? this.getTranslateY(e) : this.getTranslateX(e);
        const signe = (this.props.rotate > 0 && this.props.rotate < 270) ? -1  : 1; */
    	const height =  parseFloat(this.originalHeight) + this.getTranslateY(e); // 0
    	// const height =  parseFloat(this.originalHeight) - this.getTranslateX(e);  // 90 deg
    	// const height =  parseFloat(this.originalHeight) - this.getTranslateY(e); // 180
    	// const height =  parseFloat(this.originalHeight) + this.getTranslateX(e);  // 270 deg
      
    	//  const height =  parseFloat(this.originalHeight) + (signe * translate);

    	this.setState({style : {...this.state.style, height: height + 'px'}});
    }

    /**
     * 
     */
    leftResizing = (e) => {

    	const width = parseFloat(this.originalWidth) - this.getTranslateX(e);
    	const newPositionX = this.originalX + this.getTranslateX(e);
    	this.setState({style : {...this.state.style, width: width + 'px', left: newPositionX + 'px'}});
    }

    /**
     * 
     */
    rightResizing = (e) => {
    	const width = parseFloat(this.originalWidth) + this.getTranslateX(e);
    	this.setState({style : {...this.state.style, width: width + 'px'}});
    }

    /**
     * 
     */
    topResizing = (e) => {
    	const height = parseFloat(this.originalHeight) - this.getTranslateY(e);
    	const newPositionY = this.originalY + this.getTranslateY(e);
    	this.setState({style : {...this.state.style, height: height + 'px', top: newPositionY + 'px'}});
    }

    /**
     * 
     */
    bottomLeftResizing = (e) => {
    	const width = parseFloat(this.originalWidth) - this.getTranslateX(e);
    	const height = (this.props.lockAspectRatio)
    		? (width / this.ratio)
    		:  parseFloat(this.originalHeight) + this.getTranslateY(e);
    	const newPositionX = this.originalX + this.getTranslateX(e);
       
    	this.setState({style : {...this.state.style,
    		width: width + 'px', 
    		height: height + 'px',
    		left: newPositionX + 'px',
    	}});
    }

    /**
     * 
     */
    bottomRightResizing = (e) => {
    	const width = parseFloat(this.originalWidth) + this.getTranslateX(e);
    	const height = (this.props.lockAspectRatio)
    		? (width / this.ratio)
    		: parseFloat(this.originalHeight) + this.getTranslateY(e);
    	this.setState({style : {...this.state.style, width: width + 'px', height: height + 'px'}});
    }

    /**
     * 
     */
    topLeftResizing = (e) => {
    	const width = parseFloat(this.originalWidth) - this.getTranslateX(e);
    	const height = (this.props.lockAspectRatio)
    		? (width / this.ratio)
    		:  parseFloat(this.originalHeight) - this.getTranslateY(e);

    	const translateY = (this.props.lockAspectRatio)
    		?  parseFloat(this.originalHeight) - height
    		: this.getTranslateY(e);

    	const newPositionX = this.originalX + this.getTranslateX(e);
    	const newPositionY = this.originalY + translateY;
    	this.setState({style : {
    		...this.state.style, 
    		width: width + 'px', 
    		height: height + 'px', 
    		left : newPositionX + 'px',
    		top : newPositionY + 'px' 
    	}});

    }

    /***
     * 
     */
    topRightResizing = (e) => {
    	const width = parseFloat(this.originalWidth) + this.getTranslateX(e);
    	const height = (this.props.lockAspectRatio)
    		? (width / this.ratio)
    		: parseFloat(this.originalHeight) - this.getTranslateY(e);

    	const translateY = (this.props.lockAspectRatio)
    		?  parseFloat(this.originalHeight) - height
    		: this.getTranslateY(e);

    	const newPositionY = this.originalY + translateY;
    	this.setState({style : {...this.state.style, width: width + 'px', height: height + 'px', top : newPositionY + 'px' }});

    }


    handleClick = (e) => {
		this.props.handelClickEvent(e);
	}

    /**
     * 
     */
    handleMouseDown = (e) => {

    	e.preventDefault();
    	this.mousePositionX = e.clientX;
    	this.mousePositionY = e.clientY;
    	if (e.target.classList.contains('resizer')) {
    		const resizingDirection = Object.keys(this.resizingDirection).filter(key => e.target.classList.contains(this.resizingDirection[key].classResizerName))[0];
    		if (resizingDirection) {
    			this.startedResize = true;
				this.selectedBottom = resizingDirection;
    		}
    	} else {
			this.startedDrag = true;
		}
		window.addEventListener('mousemove', this.handleMouseMove);
    }

    /**
     * 
     */
    handleMouseMove = (e) => {

    	if (this.startedResize === true && this.selectedBottom && this.resizingDirection[this.selectedBottom] !== undefined) {
    		this.resizingDirection[this.selectedBottom].resizing(e);
    		this.props.onResize(e, 
    			this.selectedBottom, 
    			{width: this.state.style.width, height: this.state.style.height},
    			{x: this.state.style.left, y: this.state.style.top},
    		);
    	}
    	else if (this.startedDrag === true && this.props.draggable === true) {
			this.draging = true;
    		const newPositionX = this.originalX + this.getTranslateX(e);
    		const newPositionY = this.originalY + this.getTranslateY(e);
    		this.setState({style : {...this.state.style, left : newPositionX + 'px',top : newPositionY + 'px' }});
    	}
    }

    /**
     * 
     */
    handleMouseUp = (e) => {
		e.preventDefault();
		console.log(e.target);
    	if (this.startedDrag === true && this.draging) {
    		this.props.onDragStop(e, {x: this.state.style.left, y: this.state.style.top});
    	} else if (this.startedResize) {

			this.props.onResizeStop(e, 
    			this.selectedBottom, 
    			{width: this.state.style.width, height: this.state.style.height},
    			{x: this.state.style.left, y: this.state.style.top},
    		);
		}
    	this.startedResize = false;
    	this.startedDrag = false;
    	this.originalWidth = this.state.style.width;
    	this.originalHeight = this.state.style.height;
    	this.originalX = parseFloat(this.state.style.left);
		this.originalY = parseFloat(this.state.style.top);
		this.draging = false;
    }

    
    render() {
    	return (
    		<div id={this.props.id} style= {this.state.style} className='resizable' ref={this.ref}>
    			<div className='resizers' onDoubleClick={this.props.onDoubleClick}>
    				{this.props.children}
    				{Object.keys(this.resizingDirection).map(key => {
    					if (this.props.enableResizing && this.props.enableResizing[key]) {
    						const className = this.resizingDirection[key].classResizerName;
    						return  <div key = {key} className={'resizer ' + className}></div>;
    					}
    				})}
    			</div>
				
    		</div>

    	);
    }
}


Event.defaultProps = {
	size : { width: 100,  height: 50 },
	position : { x: 0, y: 0 },
	onDragStop : (e, position) => { console.log('onDragStop ::', position); },
	onResize : (e, direction, size, position) => { 
		// console.log('direction ::', direction, ' ||  size ::', size); 
		// console.log('position ::', position); 
	},
	onResizeStop : (e, direction, size, position) => { 
		console.log('direction ::', direction, ' ||  size ::', size); 
		console.log('position ::', position); 
	},
	lockAspectRatio : true,
	enableResizing : {
		bottom: false,
		bottomLeft: false,
		bottomRight: false,
		left: true,
		right: true,
		top: false,
		topLeft: false,
		topRight: false
	},
	draggable: true,
	rotate: 0

};


export default Event;