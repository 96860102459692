/**
 * @flow
 * @relayHash 37619ff2621b620d8d011c007e29a6de
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getSupplierContactQueryVariables = {|
  id: string
|};
export type getSupplierContactQueryResponse = {|
  +getSupplierContact: {|
    +id: string,
    +name: string,
    +supplierId: ?string,
    +professionId: ?string,
    +supplier: ?{|
      +id: string,
      +name: string,
    |},
    +profession: ?{|
      +id: string,
      +name: string,
    |},
    +supplierContactItems: ?$ReadOnlyArray<{|
      +id: string,
      +information: string,
      +contactMediumId: string,
      +contactMedium: {|
        +id: string,
        +name: string,
      |},
    |}>,
  |}
|};
export type getSupplierContactQuery = {|
  variables: getSupplierContactQueryVariables,
  response: getSupplierContactQueryResponse,
|};
*/


/*
query getSupplierContactQuery(
  $id: String!
) {
  getSupplierContact(id: $id) {
    id
    name
    supplierId
    professionId
    supplier {
      id
      name
    }
    profession {
      id
      name
    }
    supplierContactItems {
      id
      information
      contactMediumId
      contactMedium {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getSupplierContact",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "SupplierContactModel",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplierId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "professionId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "supplier",
        "storageKey": null,
        "args": null,
        "concreteType": "SupplierModel",
        "plural": false,
        "selections": (v3/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "profession",
        "storageKey": null,
        "args": null,
        "concreteType": "ProfessionModel",
        "plural": false,
        "selections": (v3/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "supplierContactItems",
        "storageKey": null,
        "args": null,
        "concreteType": "SupplierContactItemModel",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "information",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "contactMediumId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contactMedium",
            "storageKey": null,
            "args": null,
            "concreteType": "ContactMediumModel",
            "plural": false,
            "selections": (v3/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getSupplierContactQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getSupplierContactQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getSupplierContactQuery",
    "id": null,
    "text": "query getSupplierContactQuery(\n  $id: String!\n) {\n  getSupplierContact(id: $id) {\n    id\n    name\n    supplierId\n    professionId\n    supplier {\n      id\n      name\n    }\n    profession {\n      id\n      name\n    }\n    supplierContactItems {\n      id\n      information\n      contactMediumId\n      contactMedium {\n        id\n        name\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ce6ecd6a26bdcd89b2e783b97ae95888';
module.exports = node;
