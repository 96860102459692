
import React from "react";
/** Components */
import Invoice from '../../components/Invoice/Invoice';
import CustomerWrapper from '../../components/CustomerWrapper';


export default (props) => {
  const customerId = props.match.params.id;
  return (

    <CustomerWrapper {...props}>
      <Invoice customerId={customerId} defaultFilter={{customerId}} />
    </CustomerWrapper>

  );

}

