
import React from "react";
import { Route, Switch } from "react-router-dom";
import Footer from "./components/Footer/Footer.jsx";
import routes from "routes.js";


class Public extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info"
    };
    this.mainPanel = React.createRef();
  }


  routesMap (routeItems) {
    return routeItems.filter(route => (route.layout === '/public')).map((prop, key) => {
      return (prop.children !== undefined && prop.children.length > 0)
      ? this.routesMap(prop.children)
      : (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    })
  }
 
 
  render() {
    return (
      <div className="wrapper">
        <div className="container" ref={this.mainPanel}>
        
          <Switch>
            {this.routesMap(routes)}
          </Switch>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

export default Public;
