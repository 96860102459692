/**
 * @flow
 * @relayHash a8150abe8effa4905ae11cfe7669f0e4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProjectUpdateInput = {|
  id: string,
  name: string,
  customerId: string,
|};
export type UpdateProjectMutationVariables = {|
  data: ProjectUpdateInput
|};
export type UpdateProjectMutationResponse = {|
  +updateProject: {|
    +id: string
  |}
|};
export type UpdateProjectMutation = {|
  variables: UpdateProjectMutationVariables,
  response: UpdateProjectMutationResponse,
|};
*/


/*
mutation UpdateProjectMutation(
  $data: ProjectUpdateInput!
) {
  updateProject(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "ProjectUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateProject",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "ProjectModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateProjectMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateProjectMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateProjectMutation",
    "id": null,
    "text": "mutation UpdateProjectMutation(\n  $data: ProjectUpdateInput!\n) {\n  updateProject(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '57ad7e85003e969b0811c746175653ec';
module.exports = node;
