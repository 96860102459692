import React, { useState } from "react";
import { CompactPicker, BlockPicker, TwitterPicker } from 'react-color';
import CreateUnityMutation from '../../../graphql/unity/mutations/CreateUnityMutation';
import { ButtonSave, ButtonCancel } from '../../../components/Buttons';
import { Formik } from 'formik';
import * as Yup from "yup";


export default (props) => {



    const create = data => {
        CreateUnityMutation({ ...data }, (response) => {
            if (response.status === 'OK') {
                props.doClose({
                    ...response, msgSnackbar: {
                        message: 'C bon c fait',
                        variant: 'success',
                        open: true,
                    }
                });
            } else {
                console.log({ error: 'Erreur' });
            }

        });
    }

    return (
        <Formik
            initialValues={{ code: '', name: '' }}
            validationSchema={Yup.object().shape({
                code: Yup.string().required("Required"),
                name: Yup.string().required("Required")
            })}
            onSubmit={(values, { setSubmitting }) => {
                create({ ...values });
                setSubmitting(false);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
                /* and other goodies */
            }) => (
                    <form onSubmit={handleSubmit} className="p-5">

                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Code:</label>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    name="code"
                                    className={
                                        errors.code && touched.code
                                            ? "form-control form-control-sm is-invalid"
                                            : "form-control form-control-sm"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={(values.code) ? values.code : ''} />
                                {errors.code && touched.code && (
                                    <div className="invalid-feedback">{errors.code}</div>
                                )}
                            </div>

                        </div>

                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">LIBELLE:</label>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    name="name"
                                    className={
                                        errors.name && touched.name
                                            ? "form-control form-control-sm is-invalid"
                                            : "form-control form-control-sm"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={(values.name) ? values.name : ''} />
                                {errors.name && touched.name && (
                                    <div className="invalid-feedback">{errors.name}</div>
                                )}
                            </div>

                        </div>

                        <div className="form-group row">
                            <div className="col-md-12 text-right">
                                <ButtonCancel onClick={() => props.doClose()}></ButtonCancel>
                                <ButtonSave onClick={() => handleSubmit()}></ButtonSave>
                            </div>
                        </div>
                    </form>

                )}
        </Formik>
    )
}

