
import React from "react";
import Quotation from '../../components/Quotation/Quotation';
/** Components */
import ProjectWrapper from '../../components/ProjectWrapper';


export default (props) => {
  const projectId = props.match.params.id;
  return (

    <ProjectWrapper {...props}>
      <Quotation projectId={projectId} defaultFilter={{projectId}} />
    </ProjectWrapper>

  );

}

