import React, { useState, useEffect } from "react";
import { fetchQuery, createFragmentContainer, graphql, QueryRenderer } from 'react-relay';
import getAllCustomerQuery from '../../../graphql/customer/queries/getAllCustomerQuery';
import environment from '../../../graphql/Environment';
import UpdateProjectMutation from '../../../graphql/project/mutations/UpdateProjectMutation';
import { ButtonSave, ButtonCancel } from '../../../components/Buttons';
import { Formik } from 'formik';
import * as Yup from "yup";


export default (props) => {




    const [state, setState] = useState({
        customers: [],
    });


    /**
* 
*/
    useEffect(() => {
        loadData();
    }, []);

    /**
     * 
     */
    const loadData = () => {
        Promise.all([loadCustomers()])
            .then(data => {
                const { 0: { getAllCustomer } } = data;
                setState({
                    ...state,
                    customers: getAllCustomer.items
                });
            })
            .catch(error => {
                console.log('error :', error.message);
            });
    }

    /**
  * 
  */
    const loadCustomers = (propsState = {}) => {
        const gqlQueryParam = {};
        return fetchQuery(environment, getAllCustomerQuery, gqlQueryParam);

    }





    const update = data => {
        UpdateProjectMutation({ ...data }, (response) => {
            if (response.status === 'OK') {
                props.doClose({
                    ...response, msgSnackbar: {
                        message: 'C bon c fait',
                        variant: 'success',
                        open: true,
                    }
                });
            } else {
                console.log({ error: 'Erreur' });
            }

        });
    }

    return (
        <Formik
            initialValues={{  id: props.data.id, name: props.data.name, customerId: (props.data.customer) ? props.data.customer.id : null }}
            validationSchema={Yup.object().shape({
                name: Yup.string().required("Required"),
                customerId: Yup.string().required("Required")
            })}
            onSubmit={(values, { setSubmitting }) => {
                update({ ...values });
                setSubmitting(false);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
                /* and other goodies */
            }) => (
                    <form onSubmit={handleSubmit} className="p-5">
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Libelle:</label>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    name="name"
                                    className={
                                        errors.name && touched.name
                                            ? "form-control is-invalid"
                                            : "form-control"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={(values.name) ? values.name : ''} />
                                {errors.name && touched.name && (
                                    <div className="invalid-feedback">{errors.name}</div>
                                )}
                            </div>

                        </div>


                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Client:</label>
                            <div className="col-sm-8">

                                <select
                                    name="customerId"
                                    className={
                                        errors.customerId && touched.customerId
                                            ? "form-control is-invalid"
                                            : "form-control"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={(values.customerId) ? values.customerId : ''}
                                >
                                    <option value=""></option>
                                    {
                                        state.customers.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                    }
                                </select>

                                {errors.customerId && touched.customerId && (
                                    <div className="invalid-feedback">{errors.customerId}</div>
                                )}
                            </div>

                        </div>

                        <div className="form-group row">
                            <div className="col-md-12 text-right">
                                <ButtonCancel onClick={() => props.doClose()}></ButtonCancel>
                                <ButtonSave onClick={() => handleSubmit()}></ButtonSave>
                            </div>
                        </div>
                    </form>

                )}
        </Formik>
    )
}


