
import React from "react";
/** Components */
import Events from "./components/Events";
import Header from "./components/Header";
import Body from "./components/Body";
import NavBtnGroup from "./components/NavBtnGroup";
import NavBtnGroupContent from "./components/NavBtnGroupContent";
import ToglPeriodBtnGroup from "./components/ToglPeriodBtnGroup";

/** Context */
import SchedulerContext from "./SchedulerContext";

/** Moment */
import moment from 'moment';
import momentConfig from '../../config/moment.config';
moment.locale('fr', momentConfig);



class Scheduler extends React.Component {
  nbrOfUserCheked = 0;
  _UY = {};
  _YU = {};
  _DX = {};
  _XD = {};
  stepByPeriod = {
    year: { step: 'month', toSet: 'month', duration:'seconds', intStepFormat: 'MM', strStepFormat: 'MMMM' },
    month: { step: 'day', toSet: 'date', duration:'seconds',intStepFormat: 'D', strStepFormat: 'dd' },
    week: { step: 'day', toSet: 'date', duration:'seconds',intStepFormat: 'D', strStepFormat: 'dd' },
    day: { step: 'hour', toSet: 'hour', duration:'seconds',intStepFormat: 'H', strStepFormat: 'kk' },
  }
  constructor(props) {
    super(props);
    this.state = {
      dateContext: moment(),
      runingManagerEvents: false,
      events: [],
      resources: this.props.resources,
      period: 'month'
    };
  }

  /**
   * 
   */
  componentDidMount() {
    this.refreshManagerEvents();
  }

  /**
   * 
   * @param {*} prevProps 
   * @param {*} prevState 
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props ||
      prevState.dateContext !== this.state.dateContext ||
      prevState.period !== this.state.period
    ) {
      this.refreshManagerEvents();
    }
  }

  /**
   * 
   */
  refreshManagerEvents() {
    this.nbrOfUserCheked = 0;
    this._UY = {};
    this._YU = {};
    this._DX = {};
    this._XD = {};
    const dataEvents = this.dataEventsAdapter(this.props.events);
    this.setState({ ...this.state, events: dataEvents, runingManagerEvents: true });
  }


  /**
   * 
   * @param {*} events 
   */
  dataEventsAdapter(events) {
    return (events || [])
      .filter(event => this.eventHasIntersection(event))
      .map(event => {
        delete (event.position);
        const dateStartIsIn = (moment(event.start).isBetween(this.startOfPeriod(), this.endOfPeriod()));
        const dateEndIsIn = (moment(event.end).isBetween(this.startOfPeriod(), this.endOfPeriod()));
        return {
          ...event,
          _start: (dateStartIsIn) ? event.start : this.startOfPeriod(),
          _end: (dateEndIsIn) ? event.end : this.endOfPeriod(),
          _draggable: (dateStartIsIn && dateEndIsIn),
          _resizable: {left: dateStartIsIn, right: dateEndIsIn}
        }
      });
  }

  /**
   * 
   * @param {*} event 
   */
  eventHasIntersection(event) {
    return (moment(event.start).isBetween(this.startOfPeriod(), this.endOfPeriod()) ||
      moment(event.end).isBetween(this.startOfPeriod(), this.endOfPeriod()) ||
      moment(this.startOfPeriod()).isBetween(moment(event.start), moment(event.end)) ||
      moment(this.endOfPeriod()).isBetween(moment(event.start), moment(event.end))
    );
  }

  /**
   * 
   */
  runManagerEvents = (date, boundDate, resource) => {
    this.indexation(date, boundDate, resource);
    if (this.endOfPeriod().isSame(date, this.getFromPeriod('step'))) {
      this.nbrOfUserCheked += 1;
    }
    if (this.nbrOfUserCheked === this.state.resources.length) {
      const dataEvents = (this.state.events || []).map(event => this.boundingEvents(event));
      this.setState({ ...this.state, runingManagerEvents: false, events: dataEvents });
    }
  }


  boundingEvents (event) {
    const { resource, _start, _end } = event;
    const dateStart = moment(_start).format(this.getFromPeriod('intStepFormat')).toString();
    const dateEnd = moment(_end).format(this.getFromPeriod('intStepFormat')).toString();
    const posY = this._UY[resource];
    const posStartX = this._DX[dateStart].x;
    const posEndX = this._DX[dateEnd].x + this._DX[dateEnd].width;
    return { ...event, position: { start: { x: posStartX, y: posY }, end: { x: posEndX, y: posY } } };
  }

  /**
   * 
   * @param {*} date 
   * @param {*} boundDate 
   * @param {*} resource 
   */
  indexation(date, boundDate, resource) {
    const dateFormat = moment(date).format(this.getFromPeriod('intStepFormat')).toString();
    
    this._UY[resource] = (this._UY[resource] === undefined) ? {} : this._UY[resource];
    this._UY[resource] = boundDate.y;

    this._YU[boundDate.y.toFixed(2)] = (this._YU[boundDate.y.toFixed(2)] === undefined) ? {} : this._YU[boundDate.y.toFixed(2)];
    this._YU[boundDate.y.toFixed(2)] = resource;

    this._DX[dateFormat] = (this._DX[dateFormat] === undefined) ? {} : this._DX[dateFormat];
    this._DX[dateFormat] = boundDate;
    this._XD[boundDate.x.toFixed(2)] = (this._XD[boundDate.x.toFixed(2)] === undefined) ? {} : this._XD[boundDate.x.toFixed(2)];
    this._XD[boundDate.x.toFixed(2)] = dateFormat;
  }

  /**
   * 
   * @param {*} e 
   * @param {*} direction 
   * @param {*} size 
   * @param {*} position 
   * @param {*} event 
   */
  onResizeEvent (e, direction, size, position, event) {
    const {x, y } = position;
    const posXR = (direction === 'right') ? parseFloat(x) + parseFloat(size.width): parseFloat(x);
    const posSible = Object.keys(this._XD).filter(posX => (parseFloat(posX) <= parseFloat(posXR))).pop();
    const dateSible = (posSible) ? Number(this._XD[posSible]) : undefined;
    if (!dateSible === undefined) {
      this.refreshManagerEvents();
      return;
    }
    const step = this.getFromPeriod('toSet');
    if (direction === 'right') {
      this.props.onResizedEvent({...event, end: moment(event.end).set(step, (step === 'month' ? dateSible - 1 : dateSible))});
    } else {
      this.props.onResizedEvent({...event, start: moment(event.start).set(step, (step === 'month' ? dateSible - 1 : dateSible))});
    }
  }

  /**
   * 
   * @param {*} position 
   * @param {*} event 
   */
  onDropEvent(position, event) {
    const {x, y } = position;
    const posXR = parseFloat(x);
    const posYR = parseFloat(y);
    const margeTolerance = parseFloat(this.props.eventDefaultHeight / 2);
    const posYSible = Object.keys(this._YU).filter(posY => (parseFloat(posY - margeTolerance)  <= parseFloat(posYR))).pop();
    const resourceSible = (posYSible) ? Number(this._YU[posYSible]) : undefined;
    const posXSible = Object.keys(this._XD).filter(posX => (parseFloat(posX) <= parseFloat(posXR))).pop();
    const dateSible = (posXSible) ? Number(this._XD[posXSible]) : undefined;
    if (!dateSible === undefined || resourceSible === undefined) {
      this.refreshManagerEvents();
      return;
    }
    const toSet = this.getFromPeriod('toSet');
    const formatDuration = this.getFromPeriod('duration'); 
    const duration = moment.duration(event.end.diff(event.start));
    const nbrSteps = duration.as(formatDuration);
    const dateStart = moment(event.start).set(toSet, (toSet === 'month' ? dateSible - 1 : dateSible));
    const dateEnd = moment(dateStart).add(nbrSteps, formatDuration);
    const dropedEvent = {...event, 
      resource: resourceSible, 
      start: dateStart,
      end: dateEnd
    }
    this.props.onDropedEvent(dropedEvent);
  }

  /**
   * 
   */
  setContext = (dateContext) => {
    this.setState({ ...this.state, dateContext: dateContext });
  }

  /**
   * 
   */
  setPeriod = (period) => {
    this.setState({ ...this.state, period: period });
  }


  /**
   * 
   */
  nextPeriod = () => {
    const { dateContext } = this.state;
    this.setContext(moment(dateContext).add(1, this.state.period));
  }

  /**
   * 
   */
  prevPeriod = () => {
    const { dateContext } = this.state;
    this.setContext(moment(dateContext).subtract(1, this.state.period));
  }

  /**
   * 
   */
  startOfPeriod() {
    const { dateContext } = this.state;
    return moment(dateContext).startOf(this.state.period);
  }
  /**
   * 
   */
  endOfPeriod() {
    const { dateContext } = this.state;
    return moment(dateContext).endOf(this.state.period);
  }

  getFromPeriod(field) {
    const { period } = this.state;
    return this.stepByPeriod[period][field];
  }
  /**
   * 
   */
  itemsOfPeriod = () => {
    const startOf = this.startOfPeriod();
    const endOf = this.endOfPeriod();
    let fromDate = startOf.clone();
    const items = [];
    do {
      items.push({
        obj: fromDate,
        int: fromDate.format(this.getFromPeriod('intStepFormat')),
        str: fromDate.format(this.getFromPeriod('strStepFormat')),
      });
      fromDate = moment(fromDate).add(1, this.getFromPeriod('step')).clone();
    } while (fromDate < endOf);
    return items;
  }




  /**
   * 
   */
  render() {
    const itemsOfPeriod = this.itemsOfPeriod();
    return (
      <SchedulerContext.Provider value={{
        runManagerEvents: this.runManagerEvents,
        runingManagerEvents: this.state.runingManagerEvents,
        step: this.getFromPeriod('step')
      }}>
        <div className="card p-2">
          <div className="card-header">
            <h5>Planificateur de tâches</h5>
          </div>
          <div className="card-body" style={{ position: "relative" }} id="CardBodyID">
            <div className="row">
              <div className="col-md-4 p-0">
                <NavBtnGroup prevPeriod={this.prevPeriod} nextPeriod={this.nextPeriod}>
                  <NavBtnGroupContent period={this.state.period} dateContext={this.state.dateContext} />
                </NavBtnGroup>
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-4" style={{ textAlign: "right" }}>
                <ToglPeriodBtnGroup setPeriod={this.setPeriod} />
              </div>
            </div>
            
            <Events 
            events={this.state.events} 
            eventDefaultHeight={this.props.eventDefaultHeight} 
            handelClickEvent={(event) => this.props.onClickEvent(event)}
            onResizeStop={(e, dir, size, position, event) => this.onResizeEvent(e, dir, size, position, event)}
            onDragStop={(position, event) => this.onDropEvent(position, event)}
            />
            <Header items={itemsOfPeriod} />
            <Body
              resources={this.props.resources}
              events={this.state.events}
              itemsOfPeriod={itemsOfPeriod}
            />
          </div>
        </div>
      </SchedulerContext.Provider>
    );
  }
}

Scheduler.defaultProps = {
  resources: [],
  events: [],
  eventDefaultHeight: 40,
  onClickEvent: (event) => console.log(event),
  onResizedEvent: (event) => console.log(event),
  onDropedEvent: (event) => console.log(event)
};

export default Scheduler;
