import React from "react";
import { connect } from 'react-redux';
import { doLogIn } from '../../redux/authentication/actions';

import { Formik } from 'formik';
import * as Yup from "yup";
import LoginMutation from "../../graphql/login/mutations/LoginMutation";
import { GC_USER_ID, GC_APP_ID, GC_AUTH_TOKEN } from '../../constants';

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: ''
    }
  }


  sendData = async (values) => {
    LoginMutation(values.email, values.password, (response) => {
      if (response.status === 'OK') {
        const { id, appId, accessToken } = response.pyload;
        this.saveUserData(id, appId, accessToken);
        this.props.history.push(`/private/dashboard`)
      } else {
        this.setState({ error: 'Erreur d\'authentification' });
      }

    });
  }

  saveUserData = (id, appId, token) => {
    localStorage.setItem(GC_USER_ID, id);
    localStorage.setItem(GC_APP_ID, appId);
    localStorage.setItem(GC_AUTH_TOKEN, token);
    this.props.doLogIn(id);
  }

  render() {
    return (

      <div className="content">
        <div className="flash-container flash-container-page sticky">
        </div>

        <div className="row mt-3">
          <div className="col-sm-12">
            <h1 className="mb-3 font-weight-normal">
              CODBOX ERP
      </h1>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-sm-7 order-12 order-sm-1 brand-holder">
            <p>
              Est un outil central dans le Système d’Information des entreprises. <br />
              Ce logiciel de gestion est synonyme de performance, d’optimisation des coûts et des ressources.
            </p>

          </div>
          <div className="col-sm-5 order-1 order-sm-12 new-session-forms-container">
            <div id="signin-container">
              <ul className="nav-links new-session-tabs nav-tabs nav" role="tablist">
                <li className="nav-item" role="presentation">
                  <a className="nav-link active" data-qa-selector="sign_in_tab" data-toggle="tab" href="#login-pane" role="tab">Se connecter</a>
                </li>
              </ul>

              <div className="tab-content mt-5">
                <div className="login-box tab-pane active" id="login-pane" role="tabpanel">
                  <div className="login-body">

                  {
                    (this.state.error !== '')
                    ? (<div className="alert alert-danger">{this.state.error}</div>)
                    : ''
                  }

                    <Formik
                      initialValues={{ email:'me@nest.com', password: '1234' }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string().required("Champ Email est obligatoire").email("Adresse mail non valide"),
                        password: Yup.number().required("Champ Mot de passe est obligatoire")
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        console.log('values', values)
                        this.sendData(values)
                        setSubmitting(false);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                      }) => (

                          <form className="new_user gl-show-field-errors" onSubmit={handleSubmit}>
                            <div className="form-group">
                              <label className="label-bold">Email</label>
                              <input
                                name="email"
                                value={values.email}
                                className={
                                  errors.email && touched.email
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"}
                                type="text"
                                onChange={handleChange} />
                              {errors.email && touched.email && (
                                <p className="invalid-feedback">{errors.email}</p>
                              )}
                            </div>
                            <div className="form-group">
                              <label className="label-bold">Mot de passe</label>
                              <input
                                name="password"
                                value={values.password}
                                className={
                                  errors.password && touched.password
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"}
                                type="password"
                                onChange={handleChange} />
                              {errors.password && touched.password && (
                                <p className="invalid-feedback">{errors.password}</p>
                              )}
                            </div>
                            <div className="remember-me">
                              <label>
                                <input name="remember_me" type="hidden" value="0" />
                                <input className="remember-me-checkbox mr-2" type="checkbox" value="1" name="remember_me" />
                                <span>Souviens-toi de moi</span>
                              </label>
                              <div className="float-right">
                                <a href="/users/password/new">Mot de passe oublié ?</a>
                              </div>
                            </div>
                            <div></div>
                            <div className="submit-container move-submit-down">
                              <button type="submit" className="btn  btn-sm btn-success" disabled={isSubmitting} className="btn btn-success">Se connecter</button>
                            </div>
                          </form>
                        )}
                    </Formik>

                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

    );
  }
}




const mapDispatchToProps = dispatch => ({
	doLogIn: data => dispatch(doLogIn(data)),
});
  
export default connect(
	null,
	mapDispatchToProps
)(Login);


