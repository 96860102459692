
import React from "react";
import TemplateWrapper from '../../components/TemplateWrapper';
import HtmlTemplate from './HtmlTemplate';

export default (props) => {

  return (
    <TemplateWrapper {...props}>
         <HtmlTemplate  />
    </TemplateWrapper>
  );
}