/**
 * @flow
 * @relayHash f492bbf1c3d320e6f9965e07d507a88d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InvoiceCreateInput = {|
  title: string,
  description?: ?string,
  address: string,
  date: any,
  tax?: ?string,
  advancePayment?: ?string,
  postStamp?: ?string,
  discountType?: ?string,
  discountValue?: ?string,
  total: string,
  totalTTC: string,
  billingAddressId?: ?string,
  currencyId: string,
  customerId: string,
  quotationId?: ?string,
  invoiceItem: $ReadOnlyArray<InvoiceItemCreateInput>,
|};
export type InvoiceItemCreateInput = {|
  projectId: string,
  designation: string,
  description?: ?string,
  quantity: string,
  unityId: string,
  discount?: ?string,
  unitPrice: string,
  total: string,
|};
export type CreateInvoiceMutationVariables = {|
  data: InvoiceCreateInput
|};
export type CreateInvoiceMutationResponse = {|
  +createInvoice: {|
    +id: string
  |}
|};
export type CreateInvoiceMutation = {|
  variables: CreateInvoiceMutationVariables,
  response: CreateInvoiceMutationResponse,
|};
*/


/*
mutation CreateInvoiceMutation(
  $data: InvoiceCreateInput!
) {
  createInvoice(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "InvoiceCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createInvoice",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "InvoiceModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateInvoiceMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateInvoiceMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateInvoiceMutation",
    "id": null,
    "text": "mutation CreateInvoiceMutation(\n  $data: InvoiceCreateInput!\n) {\n  createInvoice(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '68c5a4b84069b3b501d26a7b406b7fae';
module.exports = node;
