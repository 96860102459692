import React, { useState, useEffect } from "react";

/** Graphql */
import { fetchQuery } from 'react-relay';
import getAllCountryQuery from '../../../graphql/country/queries/getAllCountryQuery';
import CreateGovernorateMutation from '../../../graphql/governorate/mutations/CreateGovernorateMutation';
import environment from '../../../graphql/Environment';



import { useForm } from 'react-hook-form';
import { ButtonSave, ButtonCancel } from '../../../components/Buttons';


const Form = (props) => {


    const { defaultData, countries } = props;

    const {
        register,
        handleSubmit,
        errors,
    } = useForm({
        mode: "onChange",
        defaultValues: defaultData
    });



    /**
* 
*/
    useEffect(() => { }, [register]);


    /**
     * 
     * @param {*} data 
     */
    const create = data => {
        CreateGovernorateMutation({ ...data }, (response) => {
            if (response.status === 'OK') {
                props.doClose({
                    ...response, msgSnackbar: {
                        message: 'C bon c fait',
                        variant: 'success',
                        open: true,
                    }
                });
            } else {
                console.log({ error: 'Erreur' });
            }

        });
    }


    /**
     * 
     * @param {*} data 
     */
    const onSubmit = data => create(data);

    return (


        <form onSubmit={handleSubmit(onSubmit)} className="row p-5" >
            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Pays:</label>
                <div className="">

                    <select
                        name="countryId"
                        className={
                            errors.countryId
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"}
                        ref={register({ required: true })} >
                        <option value=""></option>
                        {
                            countries.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>

                    {errors.countryId && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>


            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Libelle:</label>
                <div className="">
                    <input
                        type="text"
                        name="name"
                        className={
                            errors.name
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"}
                        ref={register({ required: true })} />
                    {errors.name && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>


            <div className="form-group col-md-12 text-right mt-2">

                <ButtonCancel onClick={() => props.doClose()}></ButtonCancel>
                <ButtonSave type="submit"></ButtonSave>

            </div>
        </form>
    );
}


export default (props) => {


    /**
    * 
    */
    const [state, setState] = useState({
        dataLoaded: false,
        defaultData: {},
        countries: []
    });


    useEffect(() => {
        loadData();
    }, []);



    /**
       * 
       */
    const loadData = () => {
        Promise.all([loadCountries()])
            .then(data => {
                const [{ getAllCountry }] = data;
                const defaultData = getDefaultData();
                setState({
                    ...state,
                    dataLoaded: true,
                    countries: getAllCountry.items,
                    defaultData: defaultData
                });
            })
            .catch(error => {
                console.log('error :', error.message);
            });
    }


    /**
* 
*/
    const loadCountries = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllCountryQuery, gqlQueryParam);

    }

   

    const getDefaultData = () => {

        return {
            name: '',
            countryId: null
        };

    }



    return (
        <>
            {
                state.dataLoaded ?
                    (<Form
                        defaultData={state.defaultData}
                        countries={state.countries}
                        {...props} />)
                    : '...Loading'
            }
        </>
    )

}