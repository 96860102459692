import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from './SnackbarContentWrapper';

export default (props) => {

    return (

        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={props.open}
            autoHideDuration={3000}
            onClose={props.onClose}
        >
            <SnackbarContentWrapper
                onClose={props.onClose}
                variant={props.variant || 'success'}
                message={props.message || ''}
            />
        </Snackbar>

    );
}