/**
 * @flow
 * @relayHash b7c85747bdd8745bd0e9b0316a7df13c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QuotationUpdateInput = {|
  id: string,
  title: string,
  description?: ?string,
  address: string,
  date: any,
  tax?: ?string,
  discountType?: ?string,
  discountValue?: ?string,
  total: string,
  totalTTC: string,
  billingAddressId?: ?string,
  currencyId: string,
  customerId: string,
  quotationItem: $ReadOnlyArray<QuotationItemCreateInput>,
|};
export type QuotationItemCreateInput = {|
  projectId: string,
  designation: string,
  description?: ?string,
  quantity: string,
  unityId: string,
  discount?: ?string,
  unitPrice: string,
  total: string,
|};
export type UpdateQuotationMutationVariables = {|
  data: QuotationUpdateInput
|};
export type UpdateQuotationMutationResponse = {|
  +updateQuotation: {|
    +id: string
  |}
|};
export type UpdateQuotationMutation = {|
  variables: UpdateQuotationMutationVariables,
  response: UpdateQuotationMutationResponse,
|};
*/


/*
mutation UpdateQuotationMutation(
  $data: QuotationUpdateInput!
) {
  updateQuotation(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "QuotationUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateQuotation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "QuotationModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateQuotationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateQuotationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateQuotationMutation",
    "id": null,
    "text": "mutation UpdateQuotationMutation(\n  $data: QuotationUpdateInput!\n) {\n  updateQuotation(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd89f8e8e1419617a4bddc8aaf4c8b818';
module.exports = node;
