import {
    graphql
} from 'react-relay'


const query = graphql`
    query getAllCityQuery ($pagination: PaginationInput, $sorts: [OrderInput!], $where: JSON) {
        getAllCity (pagination: $pagination, sorts: $sorts, where: $where) {
            count,
            items {
                id
                name
                zipCode
                countryId
                governorateId
                country{id, name}
                governorate{id, name}
                createdAt
                updatedAt
            }
      }
    }
  `

  export default query;