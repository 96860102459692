import {
  graphql
} from 'react-relay'






const query = graphql`
    query getSettlementQuery($id: String!) {
        getSettlement (id: $id){
          id
          date
          ref
          amount
          paymentModeId
          accountId
          currencyId
          customerId
          createdAt
          updatedAt
          deletedAt
          customer {id, name}
          paymentMode{id, name}
          account{id, name}
          currency{id, name}
          settlementItem{
            id
            settlementId
            invoiceId
            amount
            gapAmount
            invoice {id, date, num, tax, total, totalTTC, resteToPayed}
            }
            
      }
    }
  `

export default query;