import {
    graphql
} from 'react-relay'






const query = graphql`
    query getQuotationSupplierQuery($id: String!) {
        getQuotationSupplier (id: $id){
            id
            title
            description
            address,
            date
            num
            tax
            discountType
            discountValue
            total
            totalTTC
            currencyId
            supplierId
            createdAt
            updatedAt
            deletedAt
            currency {id, name, code}
            supplier {id, name}
            quotationSupplierItem{
            id
            designation
            description
            quantity
            unityId
            discount
            unitPrice
            total
            unity {id, name, code}
            }
            
      }
    }
  `

export default query;