/**
 * @flow
 * @relayHash a4324a5a73af94c024e0f750c6bb0022
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TenantUpdateInput = {|
  id: string,
  name: string,
  dialect: string,
  host: string,
  port: string,
  username: string,
  password?: ?string,
  database: string,
|};
export type UpdateTenantMutationVariables = {|
  data: TenantUpdateInput
|};
export type UpdateTenantMutationResponse = {|
  +updateTenant: {|
    +id: string
  |}
|};
export type UpdateTenantMutation = {|
  variables: UpdateTenantMutationVariables,
  response: UpdateTenantMutationResponse,
|};
*/


/*
mutation UpdateTenantMutation(
  $data: TenantUpdateInput!
) {
  updateTenant(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "TenantUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateTenant",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "TenantModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateTenantMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateTenantMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateTenantMutation",
    "id": null,
    "text": "mutation UpdateTenantMutation(\n  $data: TenantUpdateInput!\n) {\n  updateTenant(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd2d1e95a617a40421411dc6351d150f3';
module.exports = node;
