import {
	createStore,
	applyMiddleware
} from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducers';

const logger = createLogger();
const saga = createSagaMiddleware();

const store = createStore(
	rootReducer,
	undefined,
	applyMiddleware(saga, logger)
);
export default store;