
import React, { useState, useEffect } from "react";
/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

/** GRAPHQL */
import { fetchQuery } from 'react-relay';
import getCustomerQuery from '../../graphql/customer/queries/getCustomerQuery';
import environment from '../../graphql/Environment';
import { NavLink } from "react-router-dom";



import {
    FaUserTie,
    FaPaste,
    FaMapMarkerAlt,
    FaRegAddressBook,
    FaRegChartBar,
    FaMoneyBill,
    FaMoneyCheckAlt,
    FaMoneyCheck,
    FaHourglassHalf
} from 'react-icons/fa';






export default (props) => {




    const [state, setState] = useState({
        data: null
    });




    /**
     * 
     */
    useEffect(() => {

        if (state.data === null && props.match.params.id) {
            loadData(props.match.params.id);
        }

    });


    /**
   * 
   */
    const loadData = (id) => {

        fetchQuery(environment, getCustomerQuery, { id })
            .then(data => {
                setState({
                    ...state,
                    data: data.getCustomer
                });
            }).catch(error => {
                console.log('error :', error.message);
            });
    }


    return (
        <div >
            <Toolbar  >
                <Typography variant="h5" id="Title">
                    {state.data?.name}
                </Typography>
            </Toolbar>
            <div className="row">
                <div className="col-md-12 p-0">
                    <ul className="nav nav-tabs justify-content-end">
                        <li className="nav-item">
                            <NavLink
                                to={`/private/client/details/${state.data?.id}/dashboard`}
                                className="nav-link text-secondary">
                                <FaRegChartBar size={17} /> Tableau de board
                                                </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={`/private/client/details/${state.data?.id}/contact`}
                                className="nav-link text-secondary">
                                <FaRegAddressBook size={17} /> Contacts
                                                </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={`/private/client/details/${state.data?.id}/address`}
                                className="nav-link text-secondary">
                                <FaMapMarkerAlt size={17} /> Addresses
                                                </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={`/private/client/details/${state.data?.id}/project`}
                                className="nav-link text-secondary">
                                <FaPaste size={15} />  Les projets
                                                </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={`/private/client/details/${state.data?.id}/quotation`}
                                className="nav-link text-secondary">
                                <FaMoneyBill size={17} /> Devis
                                                </NavLink>
                        </li>
                        <li className="nav-item">

                            <NavLink
                                to={`/private/client/details/${state.data?.id}/invoice`}
                                className="nav-link text-secondary">
                                <FaMoneyCheckAlt size={17} /> Factures
                                                </NavLink>
                        </li>
                        <li className="nav-item">

                            <NavLink
                                to="#pablo"
                                className="nav-link text-secondary disabled">
                                <FaMoneyCheck size={17} /> Réglements
                                                </NavLink>

                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="#pablo"
                                className="nav-link text-secondary disabled">
                                <FaHourglassHalf size={17} /> Historiques
                                                </NavLink>
                        </li>
                    </ul>

                </div>

                <div className="col-md-12 p-4">
                    {
                        props.children && React.cloneElement(props.children, { customer: state?.data })
                    }
                </div>
            </div>


        </div>
    );
}