
import React, { useState, useEffect } from "react";
/** GRAPHQL */
import { fetchQuery } from 'react-relay';
import getAllCategoryTemplateQuery from '../../graphql/category-template/queries/getAllCategoryTemplateQuery';
import environment from '../../graphql/Environment';
import { NavLink } from "react-router-dom";
import { 
    FaHtml5, FaTag
   } from 'react-icons/fa';

export default (props) => {




    const [state, setState] = useState({
        categoriesLoaded: false,
        selectedCategory: props?.match?.params?.id || null,
        data: []
    });




    /**
     * 
     */
    useEffect(() => {
        if (state.categoriesLoaded === false) {
            loadData();
        }
        setState({ ...state, selectedCategory: props?.match?.params?.id || null })
    }, [props.match.params.id, state.loaded]);


    /**
   * 
   */
    const loadData = () => {
        fetchQuery(environment, getAllCategoryTemplateQuery, {})
            .then(data => {
                setState({
                    ...state,
                    selectedCategory: (state.selectedCategory) ? state.selectedCategory : (data.getAllCategoryTemplate.items[0]?.id ? data.getAllCategoryTemplate.items[0]?.id : null),
                    categoriesLoaded: true,
                    data: data.getAllCategoryTemplate.items
                });
            }).catch(error => {
                console.log('error :', error.message);
            });
    }


    return (
        <>
            <div className="content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-2">
                            <div className="card-header">
                                <div className="clearfix">
                                    <div className="float-left">
                                        <h5><FaHtml5 size={20} /> Les templates </h5>
                                    </div>
                                    <div className="float-right">



                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">

                                    <div className="col-md-12 p-10">
                                        <p className="text-secondary">
                                            Un template est une page HTML sans contenu dont les éléments statiques sont déjà positionnés et mis en forme.
                                            Leur fonctionnent est très similaire aux modèles de documents Word ou Powerpoint.En français,
                                            on parle plus volontiers de “gabarit” de page plutôt que de “modèle”.
                                            Nous utiliserons indifféremment ces trois termes dans la suite de cet article.
                                                </p>
                                    </div>


                                    <div className="col-md-12 p-0">

                                        <ul className="nav nav-tabs justify-content-end">

                                            {
                                                (state.data || []).map(item => (
                                                    <li className="nav-item" key={item.id}>
                                                        <NavLink
                                                            to={`/private/settings/application/tplDocs/template/${item?.id}`}
                                                            className="nav-link text-secondary">
                                                            <FaTag /> {item.name}
                                                        </NavLink>
                                                    </li>
                                                ))
                                            }
                                        </ul>

                                    </div>

                                    <div className="col-md-12 p-0">
                                        {
                                            props.children && state.selectedCategory && React.cloneElement(props.children, { defaultFilter: state?.selectedCategory ? { categoryTemplateId: state?.selectedCategory } : {} })
                                        }
                                        
                                    </div>









                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
}