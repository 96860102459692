import React from "react";
import Scheduler from "../../components/Scheduler/Scheduler";

/** Components */
import FormEditEvent from './components/FormEditEvent';

/** DIALOG */
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

/** Moment */
import moment from 'moment';
import momentConfig from '../../config/moment.config';
moment.locale('fr', momentConfig);

/** TRANSITION */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Planning extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      resources: [
        { id: 1, name: 'ABDELMONEM' },
        { id: 2, name: 'KHOUBEIB' },
        { id: 3, name: 'RZOUGA' },
        { id: 4, name: 'WASSIM' },
        { id: 5, name: 'ALI' },
        { id: 6, name: 'KHALIL' },
        { id: 7, name: 'BIL9ASEM' },
      ],
      events: [
        { id: 1, name: 'RC JUSTICE', resource: 1, start: moment('2019-10-02'), end: moment('2019-11-20 13:15:15') },
        { id: 2, name: 'B2B', resource: 2, start: moment('2019-12-03'), end: moment('2019-12-15  13:15:15') },
        { id: 3, name: 'Nimassa', resource: 3, start: moment('2019-12-03'), end: moment('2020-01-15  13:15:15') },
        { id: 4, name: 'RC JUSTICE Domaine 2', resource: 1, start: moment('2019-12-12'), end: moment('2019-12-20  13:15:15') },
        { id: 5, name: 'FAAVLY', resource: 4, start: moment('2019-12-03'), end: moment('2019-12-15  13:15:15') },
        { id: 6, name: 'FAAVLY', resource: 4, start: moment('2019-12-16'), end: moment('2019-12-22  13:15:15') },
        { id: 7, name: 'M4S', resource: 5, start: moment('2019-12-23'), end: moment('2019-12-30  13:15:15') },
        { id: 8, name: 'BOOST', resource: 5, start: moment('2019-12-01'), end: moment('2019-12-22  13:15:15') },
        { id: 9, name: 'NIMASSA', resource: 5, start: moment('2020-01-01'), end: moment('2020-01-22  13:15:15') },
        { id: 10, name: 'RECHERCHE', resource: 7, start: moment('2019-11-01'), end: moment('2020-01-22  13:15:15') },
        { id: 11, name: 'TUNISIE BULDING', resource: 6, start: moment('2019-01-01'), end: moment('2020-01-22  13:15:15') },
        { id: 12, name: 'BOUTIQUE', resource: 6, start: moment('2020-01-23'), end: moment('2020-01-25  13:15:15') },
      ],
      selectedEvent : null,
      openDialog : false
    };
  }

  /**
   * 
   * @param {*} data 
   */
  editEvent (data) {
    const {events} = this.state;
    this.setState({
      ...this.state, 
      events: events.map(event => (event.id === data.id) ? data : event),
      selectedEvent : null,
      openDialog : false
    });
  }



  /**
   * 
   */
  handelResizeEvent = (event) => {
    this.editEvent(event);
  }

  handelDropEvent = (event) => {
    this.editEvent(event);
  }

  /**
   * 
   */
  handelClickEvent = (event) => {
    this.setState({ ...this.state, selectedEvent: event, openDialog: true });
  }

  /**
   * 
   */
  handleCloseDialog = () => {
    this.setState({ ...this.state, openDialog: false });
  };


   /**
   * 
   */
  handleFromEditSubmited = (data) => {
    this.editEvent(data);
  }



  /**
   * 
   */
  DialogEditEvent = () => {
    return (

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={this.state.openDialog}
        TransitionComponent={Transition}
        onClose={() => this.handleCloseDialog()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="border" id="alert-dialog-slide-title">{(this.state.selectedEvent) ? this.state.selectedEvent.name : ''}</DialogTitle>
        <DialogContent>
          <FormEditEvent
            data={this.state.selectedEvent}
            resources={this.state.resources}
            doClose={() => this.handleCloseDialog()}
            submited={(data) => this.handleFromEditSubmited(data)} />
        </DialogContent>
      </Dialog>
    )
  }

  render() {
    return (
       <div className="content">
          <div className="row">
            <div className="col-md-12">
              <Scheduler 
              resources={this.state.resources} 
              events={this.state.events} 
              onClickEvent = {(data) => this.handelClickEvent(data)}
              onResizedEvent = {(data) => this.handelResizeEvent(data)}
              onDropedEvent = {(data) => this.handelDropEvent(data)}
              />
               <this.DialogEditEvent />
            </div>
          </div>
        </div>
    );
  }
}

export default Planning;
