import React, { useState, useEffect } from "react";
import { fetchQuery, createFragmentContainer, graphql, QueryRenderer } from 'react-relay';
import getAllCustomerCategoryQuery from '../../../graphql/customer-category/queries/getAllCustomerCategoryQuery';
import getAllCustomerOriginQuery from '../../../graphql/customer-origin/queries/getAllCustomerOriginQuery';
import environment from '../../../graphql/Environment';
import UpdateCustomerMutation from '../../../graphql/customer/mutations/UpdateCustomerMutation';
import { ButtonSave, ButtonCancel } from '../../../components/Buttons';
import { Formik } from 'formik';
import * as Yup from "yup";


export default (props) => {




    const [state, setState] = useState({
        categories: [],
        origines: []
    });


    /**
* 
*/
    useEffect(() => {
        loadData();
    }, []);

    /**
     * 
     */
    const loadData = () => {
        Promise.all([loadCategories(), loadOrigines()])
            .then(data => {
                const { 0: { getAllCustomerCategory }, 1: { getAllCustomerOrigin } } = data;
                setState({
                    ...state,
                    categories: getAllCustomerCategory.items,
                    origines: getAllCustomerOrigin.items,
                });
            })
            .catch(error => {
                console.log('error :', error.message);
            });
    }

    /**
  * 
  */
    const loadCategories = (propsState = {}) => {
        const gqlQueryParam = {};
        return fetchQuery(environment, getAllCustomerCategoryQuery, gqlQueryParam);

    }

    /**
  * 
  */
    const loadOrigines = (propsState = {}) => {
        const gqlQueryParam = {};
        return fetchQuery(environment, getAllCustomerOriginQuery, gqlQueryParam)
    }




    const update = data => {
        UpdateCustomerMutation({ ...data }, (response) => {
            if (response.status === 'OK') {
                props.doClose({
                    ...response, msgSnackbar: {
                        message: 'C bon c fait',
                        variant: 'success',
                        open: true,
                    }
                });
            } else {
                console.log({ error: 'Erreur' });
            }

        });
    }

    return (
        <Formik
            initialValues={{ 
                id : props.data.id,
                name: props.data.name, 
                categoryId: (props.data.category) ? props.data.category.id : null, 
                originId: (props.data.origin) ? props.data.origin.id : null, 
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().required("Required"),
                categoryId: Yup.string().required("Required"),
                originId: Yup.string().required("Required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
                update({ ...values });
                setSubmitting(false);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
                /* and other goodies */
            }) => (
                    <form onSubmit={handleSubmit} className="p-5">
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">LIBELLE:</label>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    name="name"
                                    className={
                                        errors.name && touched.name
                                            ? "form-control form-control-sm is-invalid"
                                            : "form-control form-control-sm"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={(values.name) ? values.name : ''} />
                                {errors.name && touched.name && (
                                    <div className="invalid-feedback">{errors.name}</div>
                                )}
                            </div>

                        </div>


                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Catégorie:</label>
                            <div className="col-sm-8">

                                <select
                                    name="categoryId"
                                    className={
                                        errors.categoryId && touched.categoryId
                                            ? "form-control form-control-sm is-invalid"
                                            : "form-control form-control-sm"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={(values.categoryId) ? values.categoryId : ''}
                                >
                                    <option value=""></option>
                                    {
                                        state.categories.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                    }
                                </select>

                                {errors.categoryId && touched.categoryId && (
                                    <div className="invalid-feedback">{errors.categoryId}</div>
                                )}
                            </div>

                        </div>


                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Origine:</label>
                            <div className="col-sm-8">

                                <select
                                    name="originId"
                                    className={
                                        errors.originId && touched.originId
                                            ? "form-control form-control-sm is-invalid"
                                            : "form-control form-control-sm"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={(values.originId) ? values.originId : ''}
                                >
                                    <option value=""></option>
                                    {
                                        state.origines.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                    }
                                </select>

                                {errors.originId && touched.originId && (
                                    <div className="invalid-feedback">{errors.originId}</div>
                                )}
                            </div>

                        </div>

                        <div className="form-group row">
                            <div className="col-md-12 text-right">
                                <ButtonCancel onClick={() => props.doClose()}></ButtonCancel>
                                <ButtonSave onClick={() => handleSubmit()}></ButtonSave>
                            </div>
                        </div>
                    </form>

                )}
        </Formik>
    )
}


