import React, { Component } from "react";
import ResourcePeriodSteps from "./ResourcePeriodSteps";


class Body extends Component {
   
    render() {
        return (
            <>
                {
                    (this.props.resources || []).map(
                        (resource, indexU) => (
                            <div className="row pt-2 pb-2 border-top" justify="space-between" key={indexU}>
                                <div className="col-sm-2 m-0 p-0" style={{ textAlign: "left" }}>
                                    <span className="align-middle text-muted">{resource.name}</span>
                                    <div className="avatar float-left align-middle mr-2">
                                    <i class="material-icons">account_circle</i>
                                    </div>
                                </div>
                                <div className="col-sm-10  m-0 p-0 row" style={{ textAlign: "center" }}>
                                    <ResourcePeriodSteps
                                        events={(this.props.events || []).filter(event => Number(event.resource) === Number(resource.id))}
                                        itemsOfPeriod={this.props.itemsOfPeriod}
                                        resource={resource.id}
                                    />
                                </div>
                            </div>
                        )
                    )
                }
            </>

        )
    }
}
Body.defaultProps = {
    resources: [],
    events: [],
    itemsOfPeriod: [],
    runingManagerEvents: false,
    runManagerEvents: (events, date) => console.log('events :', events, '| date :', date)
};
export default Body;