/**
 * @flow
 * @relayHash a2756f4e0ac943dfd41819998aa210be
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GovernorateCreateInput = {|
  name: string,
  countryId: string,
|};
export type CreateGovernorateMutationVariables = {|
  data: GovernorateCreateInput
|};
export type CreateGovernorateMutationResponse = {|
  +createGovernorate: {|
    +id: string
  |}
|};
export type CreateGovernorateMutation = {|
  variables: CreateGovernorateMutationVariables,
  response: CreateGovernorateMutationResponse,
|};
*/


/*
mutation CreateGovernorateMutation(
  $data: GovernorateCreateInput!
) {
  createGovernorate(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "GovernorateCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createGovernorate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "GovernorateModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateGovernorateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateGovernorateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateGovernorateMutation",
    "id": null,
    "text": "mutation CreateGovernorateMutation(\n  $data: GovernorateCreateInput!\n) {\n  createGovernorate(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3bbe26f62152d27f7e335bd277e214e7';
module.exports = node;
