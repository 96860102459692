/**
 * @flow
 * @relayHash 355fa22978d91df531bebb2425964c25
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupplierContactUpdateInput = {|
  id: string,
  name: string,
  supplierId?: ?string,
  professionId?: ?string,
  supplierContactItems: $ReadOnlyArray<SupplierContactItemCreateInput>,
|};
export type SupplierContactItemCreateInput = {|
  information: string,
  contactMediumId: string,
|};
export type UpdateSupplierContactMutationVariables = {|
  data: SupplierContactUpdateInput
|};
export type UpdateSupplierContactMutationResponse = {|
  +updateSupplierContact: {|
    +id: string
  |}
|};
export type UpdateSupplierContactMutation = {|
  variables: UpdateSupplierContactMutationVariables,
  response: UpdateSupplierContactMutationResponse,
|};
*/


/*
mutation UpdateSupplierContactMutation(
  $data: SupplierContactUpdateInput!
) {
  updateSupplierContact(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "SupplierContactUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateSupplierContact",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "SupplierContactModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateSupplierContactMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateSupplierContactMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateSupplierContactMutation",
    "id": null,
    "text": "mutation UpdateSupplierContactMutation(\n  $data: SupplierContactUpdateInput!\n) {\n  updateSupplierContact(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '77fca43f9cdee0d68b82191ab9e02322';
module.exports = node;
