import palette from '../palette';
export default {
    root: {
     },
    sticky: {
        display : 'flex',
        justifyContent: 'center',
        paddingTop : '10px',
        paddingBottom : '5px',
        backgroundColor: palette.primary.light,
    }
};