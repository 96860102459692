import {
    graphql
} from 'react-relay'


const query = graphql`
    query WhoamiQuery {
        whoami{
            id,
            firstName,
            lastName,
            email,
            tenant { id, name }
      }
    }
  `

  export default query;