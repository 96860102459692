import {
    commitMutation,
    graphql
} from 'react-relay'
import environment from '../../Environment'


const mutation = graphql`
    mutation CreateContactMutation($data: ContactCreateInput!) {
        createContact(data:$data) {
            id
      }
    }
  `

export default (data, callback) => {
    const variables = {
        data
    }

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback({ status: 'OK', pyload: response.createContact })
            },
            onError: err => callback({ status: 'KO', message: err })
        },
    )
}