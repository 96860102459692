import palette from '../palette';

export default {
  root: {
    '&$selected': {
      backgroundColor: palette.background.default
    },
    '&:hover': {
      backgroundColor: palette.background.default
    },

    '&:nth-of-type(odd)': {
      backgroundColor: palette.background.default,
    },
  }
};