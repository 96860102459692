
import React from "react";
/** Components */
import QuotationSupplier from '../../components/QuotationSupplier/QuotationSupplier';
import SupplierWrapper from '../../components/SupplierWrapper';


export default (props) => {
  const supplierId = props.match.params.id;
  return (

    <SupplierWrapper {...props}>
      <QuotationSupplier supplierId={supplierId} defaultFilter={{supplierId}} />
    </SupplierWrapper>

  );

}

