import React, { Component } from "react";
import SchedulerContext from "../SchedulerContext";
import Step from './Step';


class ResourcePeriodSteps extends Component {

    render() {
        return (
            <SchedulerContext.Consumer>
                {({ runManagerEvents, runingManagerEvents, step}) => (
                    <>
                        <div className="col-sm-12 m-0 p-0 row border-left border-secondary" style={{ textAlign: "center" }}>
                            {(this.props.itemsOfPeriod || []).map(
                                (item, index) => {
                                    return (
                                        <Step
                                            key={index} date={item.obj} {...this.props}
                                            step={step}
                                            runingManagerEvents={runingManagerEvents}
                                            refCallback={(date, boundDate, resource) => runManagerEvents(date,  boundDate, resource)}
                                        />
                                    )
                                }
                            )}
                        </div>
                    </>
                )}
            </SchedulerContext.Consumer>

        )
    }
}

ResourcePeriodSteps.defaultProps = {
    events: [],
    itemsOfPeriod: []
};


export default ResourcePeriodSteps;