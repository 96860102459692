
import React from "react";

/** Components */
import SupplierContact from '../../components/SupplierContact/SupplierContact';
import SupplierWrapper from '../../components/SupplierWrapper';



export default (props) => {
  const supplierId = props.match.params.id;

  return (
    <SupplierWrapper {...props}>
      <SupplierContact supplierId={supplierId} defaultFilter={{supplierId}} />
    </SupplierWrapper>

  );

}

