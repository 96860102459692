/**
 * @flow
 * @relayHash 989eb4dd3f4c43584637cc229578d12c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TenantCreateInput = {|
  name: string,
  dialect: string,
  host: string,
  port: string,
  username: string,
  password?: ?string,
  database: string,
|};
export type CreateTenantMutationVariables = {|
  data: TenantCreateInput
|};
export type CreateTenantMutationResponse = {|
  +createTenant: {|
    +id: string
  |}
|};
export type CreateTenantMutation = {|
  variables: CreateTenantMutationVariables,
  response: CreateTenantMutationResponse,
|};
*/


/*
mutation CreateTenantMutation(
  $data: TenantCreateInput!
) {
  createTenant(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "TenantCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createTenant",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "TenantModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateTenantMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateTenantMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateTenantMutation",
    "id": null,
    "text": "mutation CreateTenantMutation(\n  $data: TenantCreateInput!\n) {\n  createTenant(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4109258a8abce56eec251fb6fc5afde3';
module.exports = node;
