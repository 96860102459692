/**
 * @flow
 * @relayHash 9544ed8c9b82aa4482355850a7b9bb23
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SettlementUpdateInput = {|
  id: string,
  date: any,
  ref?: ?string,
  amount: string,
  paymentModeId: string,
  accountId: string,
  currencyId: string,
  customerId: string,
  settlementItem: $ReadOnlyArray<SettlementItemCreateInput>,
|};
export type SettlementItemCreateInput = {|
  invoiceId?: ?string,
  amount: string,
  gapAmount?: ?string,
|};
export type UpdateSettlementMutationVariables = {|
  data: SettlementUpdateInput
|};
export type UpdateSettlementMutationResponse = {|
  +updateSettlement: {|
    +id: string
  |}
|};
export type UpdateSettlementMutation = {|
  variables: UpdateSettlementMutationVariables,
  response: UpdateSettlementMutationResponse,
|};
*/


/*
mutation UpdateSettlementMutation(
  $data: SettlementUpdateInput!
) {
  updateSettlement(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "SettlementUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateSettlement",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "SettlementModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateSettlementMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateSettlementMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateSettlementMutation",
    "id": null,
    "text": "mutation UpdateSettlementMutation(\n  $data: SettlementUpdateInput!\n) {\n  updateSettlement(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '783b5c20d1ede02914f7114300e5bb91';
module.exports = node;
