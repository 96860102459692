/**
 * @flow
 * @relayHash 08b40e5e74351978a71dd6fcf2d4531d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UnityUpdateInput = {|
  id: string,
  name: string,
  code: string,
|};
export type UpdateUnityMutationVariables = {|
  data: UnityUpdateInput
|};
export type UpdateUnityMutationResponse = {|
  +updateUnity: {|
    +id: string
  |}
|};
export type UpdateUnityMutation = {|
  variables: UpdateUnityMutationVariables,
  response: UpdateUnityMutationResponse,
|};
*/


/*
mutation UpdateUnityMutation(
  $data: UnityUpdateInput!
) {
  updateUnity(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "UnityUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateUnity",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "UnityModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateUnityMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateUnityMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateUnityMutation",
    "id": null,
    "text": "mutation UpdateUnityMutation(\n  $data: UnityUpdateInput!\n) {\n  updateUnity(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aa5bf9b23dae9cd37b1247047109c08a';
module.exports = node;
