import React, { useState, useEffect } from "react";

/** Graphql */
import UpdateTenantMutation from '../../../graphql/tenant/mutations/UpdateTenantMutation'
import { useForm } from 'react-hook-form';
import { ButtonSave, ButtonCancel } from '../../../components/Buttons';





const Form = (props) => {


    const { defaultData } = props;

    const {
        register,
        handleSubmit,
        errors
    } = useForm({
        mode: "onChange",
        defaultValues: defaultData
    });



    /**
* 
*/
    useEffect(() => { }, [register]);


    /**
     * 
     * @param {*} data 
     */
    const update = data => {
        UpdateTenantMutation({ ...data }, (response) => {
            if (response.status === 'OK') {
                props.doClose({
                    ...response, msgSnackbar: {
                        message: 'C bon c fait',
                        variant: 'success',
                        open: true,
                    }
                });
            } else {
                console.log({ error: 'Erreur' });
            }

        });
    }


    /**
     * 
     * @param {*} data 
     */
    const onSubmit = data => update(data);

    return (


        <form onSubmit={handleSubmit(onSubmit)} className="row p-5" >
            <input type="hidden" name="id" ref={register({})} />
            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Libelle:</label>
                <div className="">
                    <input
                        type="text"
                        name="name"
                        className={
                            errors.name
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"}
                        ref={register({ required: true })} />
                    {errors.name && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>
            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Type de serveur:</label>
                <div className="">
                    <input
                        type="text"
                        name="dialect"
                        className={
                            errors.dialect
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"}
                        ref={register({ required: true })} />
                    {errors.dialect && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>
            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Serveur:</label>
                <div className="">
                    <input
                        type="text"
                        name="host"
                        className={
                            errors.host
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"}
                        ref={register({ required: true })} />
                    {errors.host && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>
            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Port:</label>
                <div className="">
                    <input
                        type="text"
                        name="port"
                        className={
                            errors.port
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"}
                        ref={register({ required: true })} />
                    {errors.port && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>
            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Utilisateur:</label>
                <div className="">
                    <input
                        type="text"
                        name="username"
                        className={
                            errors.username
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"}
                        ref={register({ required: true })} />
                    {errors.username && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>
            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Mot de passe:</label>
                <div className="">
                    <input
                        type="text"
                        name="password"
                        className={
                            errors.password
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"}
                        ref={register({ required: false })} />
                    {errors.password && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>
            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Base des données:</label>
                <div className="">
                    <input
                        type="text"
                        name="database"
                        className={
                            errors.database
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"}
                        ref={register({ required: true })} />
                    {errors.database && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>

            <div className="form-group col-md-12 text-right mt-2">

                <ButtonCancel onClick={() => props.doClose()}></ButtonCancel>
                <ButtonSave type="submit"></ButtonSave>

            </div>
        </form>
    );
}


export default (props) => {




    const getDefaultData = () => {

        return {
            id: props.data.id,
            name: props.data.name,
            dialect: props.data.dialect,
            host: props.data.host,
            port: props.data.port,
            username: props.data.username,
            password: props.data.password,
            database: props.data.database
        };

    }



    return (
        <Form
            defaultData={getDefaultData()}
            {...props} />
    )

}