import { LOGIN, LOGOUT } from './constants';
import { GC_USER_ID } from '../../constants';

/**
 * 
 * @param {*} state 
 * @param {*} data 
 */
const login = (state, userId) => {
	return ({
		...state,
		loggedIn: true,
		userId: userId
	});
};

/**
 * 
 * @param {*} state 
 * @param {*} data 
 */
const logout = (state, data) => {
	return ({
		...state,
		loggedIn: false,
		userId: null
	});
};



let userId = localStorage.getItem(GC_USER_ID);
const initialState = userId ? { loggedIn: true, userId: userId } : {};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOGIN: {
			return login(state, action.payload);
		}
		case LOGOUT: {
			return logout(state, action.payload);
		}
		default: return state;
	}
}
