/**
 * @flow
 * @relayHash 20cd40065f2e1a0b1c1b7f0fb1010307
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupplierCreateInput = {|
  name: string,
  categoryId: string,
|};
export type CreateSupplierMutationVariables = {|
  data: SupplierCreateInput
|};
export type CreateSupplierMutationResponse = {|
  +createSupplier: {|
    +id: string
  |}
|};
export type CreateSupplierMutation = {|
  variables: CreateSupplierMutationVariables,
  response: CreateSupplierMutationResponse,
|};
*/


/*
mutation CreateSupplierMutation(
  $data: SupplierCreateInput!
) {
  createSupplier(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "SupplierCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createSupplier",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "SupplierModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateSupplierMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateSupplierMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateSupplierMutation",
    "id": null,
    "text": "mutation CreateSupplierMutation(\n  $data: SupplierCreateInput!\n) {\n  createSupplier(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8f83310566947a4a9ada5dae0700ef38';
module.exports = node;
