import {
    commitMutation,
    graphql
} from 'react-relay'
import environment from '../../Environment'


const mutation = graphql`
    mutation DeleteSupplierCategoryMutation($id: String!) {
        deleteSupplierCategory(id:$id) {
            id
      }
    }
  `

export default (id, callback) => {
    const variables = {
        id
    }

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback({ status: 'OK', pyload: response.deleteSupplierCategory })
            },
            onError: err => callback({ status: 'KO', message: err })
        },
    )
}