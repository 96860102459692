/**
 * @flow
 * @relayHash faedbb65404271c5a47f2e6ec43a1015
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaginationInput = {|
  limit: number,
  offset: number,
|};
export type OrderInput = {|
  modele?: ?string,
  field: string,
  direction: string,
|};
export type getAllContactQueryVariables = {|
  pagination?: ?PaginationInput,
  sorts?: ?$ReadOnlyArray<OrderInput>,
  where?: ?any,
|};
export type getAllContactQueryResponse = {|
  +getAllContact: {|
    +count: number,
    +items: $ReadOnlyArray<{|
      +id: string,
      +name: string,
      +customerId: ?string,
      +professionId: ?string,
      +customer: ?{|
        +id: string,
        +name: string,
      |},
      +profession: ?{|
        +id: string,
        +name: string,
      |},
    |}>,
  |}
|};
export type getAllContactQuery = {|
  variables: getAllContactQueryVariables,
  response: getAllContactQueryResponse,
|};
*/


/*
query getAllContactQuery(
  $pagination: PaginationInput
  $sorts: [OrderInput!]
  $where: JSON
) {
  getAllContact(pagination: $pagination, sorts: $sorts, where: $where) {
    count
    items {
      id
      name
      customerId
      professionId
      customer {
        id
        name
      }
      profession {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "pagination",
    "type": "PaginationInput",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sorts",
    "type": "[OrderInput!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "JSON",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getAllContact",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "pagination",
        "variableName": "pagination"
      },
      {
        "kind": "Variable",
        "name": "sorts",
        "variableName": "sorts"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "ContactWithCountModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "count",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "items",
        "storageKey": null,
        "args": null,
        "concreteType": "ContactModel",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "customerId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "professionId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customer",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerModel",
            "plural": false,
            "selections": (v3/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "profession",
            "storageKey": null,
            "args": null,
            "concreteType": "ProfessionModel",
            "plural": false,
            "selections": (v3/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getAllContactQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getAllContactQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getAllContactQuery",
    "id": null,
    "text": "query getAllContactQuery(\n  $pagination: PaginationInput\n  $sorts: [OrderInput!]\n  $where: JSON\n) {\n  getAllContact(pagination: $pagination, sorts: $sorts, where: $where) {\n    count\n    items {\n      id\n      name\n      customerId\n      professionId\n      customer {\n        id\n        name\n      }\n      profession {\n        id\n        name\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '45c2778403ae66f24debb32afcaee87f';
module.exports = node;
