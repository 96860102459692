
import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  NavLink
} from "react-router-dom";


const AdapterLink = React.forwardRef((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };


 

  render() {
    const { item } = this.props;
    const hasChildren = (item.children !== undefined && item.children.length > 0);
    const expandChildren = (hasChildren === false)
      ? ''
      : ((this.state.open) ? <ExpandLess /> : <ExpandMore />);
   
    return (
      <>
        <ListItem button
          component={AdapterLink}
          to={(hasChildren) ? '#' : item.layout + item.navLink}
          params={{id: ""}}
          onClick={() => this.handleClick()}
          activeClassName="active"
        >
        { item.icon }
          <ListItemText primary={item.name} style={{
            lineHeight: '0px',
            fontSize: '12px', marginLeft: '10px'
          }} />
          {expandChildren}
        </ListItem>
        {
          (hasChildren) ? (
            <Collapse in={this.state.open} timeout="auto" unmountOnExit style={{ paddingLeft: '20px' }}>
              <List component="div" disablePadding>
                {
                  item.children.filter(rItem => (rItem.layout === '/private'))
                  .map((child, index) => <MenuItem key={index} item={child} />)
                }
              </List>
            </Collapse>
          ) : ''

        }
      </>
    );
  }
}

export default MenuItem;
