import React, { useState, useEffect } from "react";

/** Graphql */
import { fetchQuery } from 'react-relay';
import getAllContactMediumQuery from '../../../graphql/contact-medium/queries/getAllContactMediumQuery';
import getAllProfessionQuery from '../../../graphql/profession/queries/getAllProfessionQuery';
import getAllCustomerQuery from '../../../graphql/customer/queries/getAllCustomerQuery';
import CreateContactMutation from '../../../graphql/contact/mutations/CreateContactMutation';
import environment from '../../../graphql/Environment';



import { useForm, useFieldArray } from 'react-hook-form';
import { ButtonSave, ButtonCancel } from '../../../components/Buttons';
import DeleteIcon from '@material-ui/icons/Delete';




const itemModel = {
    information: "",
    contactMediumId: null
};

const Form = (props) => {


    const { defaultData, contactMediums, professions, customers } = props;

    const {
        register,
        control,
        handleSubmit,
        watch, errors,
        getValues,
        setValue
    } = useForm({
        mode: "onChange",
        defaultValues: defaultData
    });


    const {
        fields,
        append,
        remove } = useFieldArray(
            {
                control,
                name: "contactItems"
            }
        );


    /**
* 
*/
    useEffect(() => { }, [register]);


    /**
     * 
     * @param {*} data 
     */
    const create = data => {
        CreateContactMutation({ ...data }, (response) => {
            if (response.status === 'OK') {
                props.doClose({
                    ...response, msgSnackbar: {
                        message: 'C bon c fait',
                        variant: 'success',
                        open: true,
                    }
                });
            } else {
                console.log({ error: 'Erreur' });
            }

        });
    }


    /**
     * 
     * @param {*} index 
     */
    const removeItem = (index) => {
        remove(index);
    }


    /**
     * 
     * @param {*} data 
     */
    const onSubmit = data => create(data);

    return (


        <form onSubmit={handleSubmit(onSubmit)} className="row p-5" >
            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Client:</label>
                <div className="">

                    <select
                        name="customerId"
                        className={
                            errors.customerId
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} >
                        <option value=""></option>
                        {
                            customers.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>

                    {errors.customerId && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>




            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Profession:</label>
                <div className="">
                    <select
                        name="professionId"
                        className={
                            errors.professionId
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} >

                        {
                            professions.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>

                    {errors.professionId && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>


            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Nom:</label>
                <div className="">
                    <input
                        type="text"
                        name="name"
                        className={
                            errors.name
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} />
                    {errors.name && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>


            <fieldset className="form-group col-md-12 border-top mt-3">
                <legend className="col-form-label text-secondary p-1" style={{ width: 'auto' }}>Les informations</legend>

                <table className="table table-default">
                    <thead className="">
                        <tr>
                            <th className="text-secondary text-capitalize">Media</th>
                            <th className="text-secondary text-capitalize">Information</th>
                            <th style={{ width: '4%' }} className="text-secondary text-capitalize"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((item, index) => {
                            return (
                                <tr key={item.id}>

                                    <td>
                                        <select
                                            name={`contactItems[${index}].contactMediumId`}
                                            className={
                                                errors?.contactItems && errors?.contactItems[index]?.contactMediumId
                                                    ? "form-control is-invalid"
                                                    : "form-control"}
                                            ref={register({ required: true })} >
                                            <option value=""></option>
                                            {
                                                contactMediums.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                            }
                                        </select>
                                    </td>

                                    <td>
                                        <input
                                            type="text"
                                            name={`contactItems[${index}].information`}
                                            className={
                                                errors?.contactItems && errors?.contactItems[index]?.information
                                                    ? "form-control is-invalid"
                                                    : "form-control"}
                                            ref={register({ required: true })} />
                                    </td>

                                    <td>
                                        <div className="">
                                            {fields.length > 1 && <button type="button" className="btn btn-sm btn-danger" onClick={() => removeItem(index)}>
                                                <DeleteIcon />
                                            </button>}
                                        </div>
                                    </td>


                                </tr>

                            );
                        })}


                        <tr className="border-0 pb-0">
                            <td className="border-0" colSpan={'3'}>
                                <button
                                    className="btn btn-sm btn-dark"
                                    type="button"
                                    onClick={() => append(itemModel)}
                                >
                                    Add
                                </button>
                            </td>
                        </tr>

                    </tbody>
                </table>

            </fieldset>

            <div>

            </div>


            <div className="form-group col-md-12 text-right mt-2">

                <ButtonCancel onClick={() => props.doClose()}></ButtonCancel>
                <ButtonSave type="submit"></ButtonSave>

            </div>
        </form>
    );
}


export default (props) => {

    const customer = props?.data?.customer || null;


    /**
    * 
    */
    const [state, setState] = useState({
        dataLoaded: false,
        defaultData: {},
        contactMediums: [],
        professions: [],
        customers: [],
    });


    useEffect(() => {
        loadData();
    }, []);



    /**
       * 
       */
    const loadData = () => {
        Promise.all([loadContactMediums(), loadPrefessions(), loadCustomers()])
            .then(data => {
                const [{ getAllContactMedium }, { getAllProfession }, { getAllCustomer }] = data;

                const defaultData = getDefaultData();
                setState({
                    ...state,
                    dataLoaded: true,
                    contactMediums: getAllContactMedium.items,
                    professions: getAllProfession.items,
                    customers: getAllCustomer.items,
                    defaultData: defaultData
                });
            })
            .catch(error => {
                console.log('error :', error.message);
            });
    }







    /**
* 
*/
    const loadContactMediums = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllContactMediumQuery, gqlQueryParam);

    }

    /**
    * 
    */
    const loadPrefessions = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllProfessionQuery, gqlQueryParam)
    }

    /**
      * 
      */
    const loadCustomers = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllCustomerQuery, gqlQueryParam)
    }



    const getDefaultData = () => {

        return {
            name: '',
            customerId: customer?.id,
            professionId: null,
            contactItems: [itemModel]
        };

    }



    return (
        <>
            {
                state.dataLoaded ?
                    (<Form
                        defaultData={state.defaultData}
                        contactMediums={state.contactMediums}
                        professions={state.professions}
                        customers={state.customers}
                        {...props} />)
                    : '...Loading'
            }
        </>
    )

}