import React, { useState, useEffect } from "react";

/** Graphql */
import CreateCountryMutation from '../../../graphql/country/mutations/CreateCountryMutation';
import { useForm } from 'react-hook-form';
import { ButtonSave, ButtonCancel } from '../../../components/Buttons';



const Form = (props) => {

    const { defaultData } = props;
    const {
        register,
        handleSubmit,
        errors,
    } = useForm({
        mode: "onChange",
        defaultValues: defaultData
    });





    /**
* 
*/
    useEffect(() => { }, [register]);


    /**
     * 
     * @param {*} data 
     */
    const create = data => {
        CreateCountryMutation({ ...data }, (response) => {
            if (response.status === 'OK') {
                props.doClose({
                    ...response, msgSnackbar: {
                        message: 'C bon c fait',
                        variant: 'success',
                        open: true,
                    }
                });
            } else {
                console.log({ error: 'Erreur' });
            }

        });
    }


    /**
     * 
     * @param {*} data 
     */
    const onSubmit = data => create(data);

    return (


        <form onSubmit={handleSubmit(onSubmit)} className="row p-5" >

            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Libelle:</label>
                <div className="">
                    <input
                        type="text"
                        name="name"
                        className={
                            errors.name
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"}
                        ref={register({ required: true })} />
                    {errors.name && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>


            <div className="form-group col-md-12 text-right mt-2">

                <ButtonCancel onClick={() => props.doClose()}></ButtonCancel>
                <ButtonSave type="submit"></ButtonSave>

            </div>
        </form>
    );
}


export default (props) => {


    const getDefaultData = () => {

        return { name: '' };

    }



    return (
        <Form
            defaultData={getDefaultData()}
            {...props} />

        )

}