/**
 * @flow
 * @relayHash 748db01aa1964e938d0f15b409dc4514
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getSupplierQueryVariables = {|
  id: string
|};
export type getSupplierQueryResponse = {|
  +getSupplier: {|
    +id: string,
    +name: string,
    +createdAt: any,
    +updatedAt: any,
  |}
|};
export type getSupplierQuery = {|
  variables: getSupplierQueryVariables,
  response: getSupplierQueryResponse,
|};
*/


/*
query getSupplierQuery(
  $id: String!
) {
  getSupplier(id: $id) {
    id
    name
    createdAt
    updatedAt
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getSupplier",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "SupplierModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "updatedAt",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getSupplierQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getSupplierQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getSupplierQuery",
    "id": null,
    "text": "query getSupplierQuery(\n  $id: String!\n) {\n  getSupplier(id: $id) {\n    id\n    name\n    createdAt\n    updatedAt\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fc925817c5aed041bfd1e8add0c12669';
module.exports = node;
