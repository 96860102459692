/**
 * @flow
 * @relayHash 52d168a8268440d73fb07330ea9ccb5f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InvoiceUpdateInput = {|
  id: string,
  title: string,
  description?: ?string,
  address: string,
  date: any,
  tax?: ?string,
  postStamp?: ?string,
  discountType?: ?string,
  discountValue?: ?string,
  total: string,
  totalTTC: string,
  billingAddressId?: ?string,
  currencyId: string,
  customerId: string,
  quotationId?: ?string,
  invoiceItem: $ReadOnlyArray<InvoiceItemCreateInput>,
|};
export type InvoiceItemCreateInput = {|
  projectId: string,
  designation: string,
  description?: ?string,
  quantity: string,
  unityId: string,
  discount?: ?string,
  unitPrice: string,
  total: string,
|};
export type UpdateInvoiceMutationVariables = {|
  data: InvoiceUpdateInput
|};
export type UpdateInvoiceMutationResponse = {|
  +updateInvoice: {|
    +id: string
  |}
|};
export type UpdateInvoiceMutation = {|
  variables: UpdateInvoiceMutationVariables,
  response: UpdateInvoiceMutationResponse,
|};
*/


/*
mutation UpdateInvoiceMutation(
  $data: InvoiceUpdateInput!
) {
  updateInvoice(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "InvoiceUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateInvoice",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "InvoiceModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateInvoiceMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateInvoiceMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateInvoiceMutation",
    "id": null,
    "text": "mutation UpdateInvoiceMutation(\n  $data: InvoiceUpdateInput!\n) {\n  updateInvoice(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3cff8a06c9a92f15d11ae10ce2da33d0';
module.exports = node;
