/**
 * @flow
 * @relayHash 807685c44fd01279620841a6a65ff3be
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteSupplierMutationVariables = {|
  id: string
|};
export type DeleteSupplierMutationResponse = {|
  +deleteSupplier: {|
    +id: string
  |}
|};
export type DeleteSupplierMutation = {|
  variables: DeleteSupplierMutationVariables,
  response: DeleteSupplierMutationResponse,
|};
*/


/*
mutation DeleteSupplierMutation(
  $id: String!
) {
  deleteSupplier(id: $id) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteSupplier",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "SupplierModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteSupplierMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteSupplierMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteSupplierMutation",
    "id": null,
    "text": "mutation DeleteSupplierMutation(\n  $id: String!\n) {\n  deleteSupplier(id: $id) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cfb24b15d27c8ffac6f83e9a9c7cb3dc';
module.exports = node;
