import React, { useState, useEffect } from "react";

/** Graphql */
import { fetchQuery } from 'react-relay';
import getAllUnityQuery from '../../../graphql/unity/queries/getAllUnityQuery';
import getAllCurrencyQuery from '../../../graphql/currency/queries/getAllCurrencyQuery';
import getAllProjectQuery from '../../../graphql/project/queries/getAllProjectQuery';
import getAllCustomerQuery from '../../../graphql/customer/queries/getAllCustomerQuery';
import getAllBillingAddressQuery from '../../../graphql/billing-address/queries/getAllBillingAddressQuery';
import UpdateQuotationMutation from '../../../graphql/quotation/mutations/UpdateQuotationMutation';
import getQuotationQuery from '../../../graphql/quotation/queries/getQuotationQuery';
import environment from '../../../graphql/Environment';



import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { ButtonSave, ButtonCancel } from '../../../components/Buttons';
import DeleteIcon from '@material-ui/icons/Delete';
import { DatePicker } from "@material-ui/pickers";
import moment from '../../../helpers/moment-helper';



const itemModel = {
    projectId: null,
    designation: "",
    description: "",
    quantity: 0,
    unityId: null,
    discount: null,
    unitPrice: null,
    total: 0,
};

const Form = (props) => {


    const {
        defaultData,
        unities,
        currencies,
        projects,
        discountTypes,
        getCustomers,
        getBillingAddresses,
        disabledFields
    } = props;

    /**
     * 
     */
    const [quotationPrice, setQuotationPrice] = useState({
        total: 0,
        tax: 0,
        taxValue: 0,
        discountValue: 0,
        globalDiscount: 0,
        totalFinal: 0
    })
    const [selectedDate, handleDateChange] = useState(moment(props?.defaultData?.date));
    const [customers, setCustomers] = useState(props.customers);
    const [billingAddresses, setBillingAddresses] = useState(props.billingAddresses);

    const {
        register,
        control,
        handleSubmit,
        watch, errors,
        getValues,
        setValue
    } = useForm({
        mode: "onChange",
        defaultValues: { ...defaultData }
    });


    const {
        fields,
        append,
        remove } = useFieldArray(
            {
                control,
                name: "quotationItem"
            }
        );


    /**
* 
*/
    useEffect(() => {
        register({ name: "date" });
        register({ name: "currencyId" });
        recalculatItemsPrice();
    }, [register]);


    const update = data => {
        UpdateQuotationMutation({ ...data }, (response) => {
            if (response.status === 'OK') {
                props.doClose({
                    ...response, msgSnackbar: {
                        message: 'C bon c fait',
                        variant: 'success',
                        open: true,
                    }
                });
            } else {
                console.log({ error: 'Erreur' });
            }

        });
    }

    /**
     * 
     */
    const discountType = watch("discountType");

    /**
     * 
     */
    const recalculatItemsPrice = () => {
        const { quotationItem, discountType } = getValues({ nest: true });
        (quotationItem || []).map((item, index) => {
            const { quantity, unitPrice, discount } = item;
            const itemDiscountValue = (discount && discountType === 'BY_ITEM') ? discount : 0;
            const initTotPriceItem = Number(quantity) * Number(unitPrice);
            const discountValue = itemDiscountValue ? initTotPriceItem * itemDiscountValue / 100 : 0;
            const total = initTotPriceItem - discountValue;
            setValue(`quotationItem[${index}].total`, Number.parseFloat(total).toFixed(3));
            setValue(`quotationItem[${index}].discount`, itemDiscountValue);
        });

        calculatorTotalPrice();
    }

    /**
     * 
     * @param {*} accumulator 
     * @param {*} currentValue 
     */
    const calculatorReuce = (accumulator, currentValue) => {
        return Number(accumulator) + Number(currentValue);
    }

    /**
     * 
     * @param {*} excludedIndexItem 
     */
    const calculatorTotalPrice = (excludedIndexItem = null) => {

        const { quotationItem, tax, discountValue, discountType } = getValues({ nest: true });
        const globalDiscountValue = (discountValue && discountType === 'TOTAL') ? discountValue : 0;
        const initGlobalTotal = (excludedIndexItem !== null)
            ? (quotationItem || []).filter((item, index) => index !== excludedIndexItem).map(item => item.total).reduce(calculatorReuce, 0)
            : (quotationItem || []).map(item => item.total).reduce(calculatorReuce, 0);
        const discountGlobalValue = globalDiscountValue ? initGlobalTotal * globalDiscountValue / 100 : 0;
        const taxValue = tax ? initGlobalTotal * tax / 100 : 0;
        const globalTotal = Number(initGlobalTotal) + Number(taxValue) - Number(discountGlobalValue);

        setValue('total', Number.parseFloat(initGlobalTotal).toFixed(3));
        setValue('totalTTC', Number.parseFloat(globalTotal).toFixed(3));

        setQuotationPrice({
            total: Number.parseFloat(initGlobalTotal).toFixed(3),
            tax: tax,
            taxValue: Number.parseFloat(taxValue).toFixed(3),
            discountValue: discountValue,
            globalDiscount: discountGlobalValue,
            totalFinal: Number.parseFloat(globalTotal).toFixed(3)
        });
    }

    /**
     * 
     * @param {*} index 
     */
    const removeItem = (index) => {
        calculatorTotalPrice(index);
        remove(index);
    }

    /**
     * 
     * @param {*} e 
     */
    const projectChanged = (projectId) => {
        if (disabledFields.indexOf('customerId') >= 0) return;
        setValue('customerId', '');
        getCustomers({ where: { '$projects.id$': projectId } })
            .then(data => {
                const { items } = data.getAllCustomer;
                setCustomers(items);
            }).catch(error => {
                console.log('error :', error.message);
            });
    }


    /**
    * 
    * @param {*} e 
    */
    const customerChanged = (customerId) => {
        setValue('billingAddressId', "");
        setValue('address', '');
        getBillingAddresses({ where: { customerId } })
            .then(data => {
                const { items } = data.getAllBillingAddress;
                setBillingAddresses(items);
            }).catch(error => {
                console.log('error :', error.message);
            });
    }

    /**
    * 
    */
    const billingAddressChanged = ({ target }) => {
        const { options, selectedIndex, value } = target;
        const address = options[selectedIndex].innerHTML;
        setValue('billingAddress', value);
        setValue('address', address);
    }


    /**
     * 
     * @param {*} data 
     */
    const onSubmit = data => update(data);

    return (


        <form onSubmit={handleSubmit(onSubmit)} className="row p-5" >
            <input type="hidden" name="id" ref={register({})} />
            <input type="hidden" name="total" ref={register({})} />
            <input type="hidden" name="totalTTC" ref={register({})} />

            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Client:</label>
                <div className="">
                    <select
                        name="customerId"
                        disabled={disabledFields.indexOf('customerId') >= 0}
                        onChange={(e) => customerChanged(e.target.value)}
                        className={
                            errors.customerId
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} >

                        {
                            customers.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>

                    {errors.customerId && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>


            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Titre:</label>
                <div className="">
                    <input
                        type="text"
                        name="title"
                        className={
                            errors.title
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} />
                    {errors.title && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>


            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Description:</label>
                <div className="">
                    <textarea
                        type="text"
                        name="description"
                        className={
                            errors.description
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({})} ></textarea>
                    {errors.description && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>

            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Les adresse du facturation:</label>
                <div className="">

                    <select
                        onChange={(e) => billingAddressChanged(e)}
                        name="billingAddressId"
                        className={
                            errors.billingAddressId
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register} >
                        <option value=""></option>
                        {
                            billingAddresses.map(item => <option key={item.id} value={item.id}>{item.address}</option>)
                        }
                    </select>

                    {errors.billingAddressId && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>


            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Addresse:</label>
                <div className="">
                    <input
                        type="text"
                        name="address"
                        className={
                            errors.address
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} />
                    {errors.address && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>


            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Date:</label>
                <div className="">

                    <DatePicker
                        autoOk
                        InputProps={{
                            disableUnderline: true,
                        }}
                        variant="inline"
                        format="DD/MM/YYYY"
                        value={selectedDate}
                        onChange={(date) => {
                            setValue(`date`, date);
                            handleDateChange(date);
                        }}

                        className={
                            errors.date
                                ? "is-invalid"
                                : ""}
                        id="date"
                        name="date"
                        inputRef={register({ required: true })}

                    />
                    {errors.date && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>



            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">TVA:</label>
                <div className="">
                    <input
                        type="number"
                        min="0"
                        name="tax"
                        onChange={recalculatItemsPrice}
                        className={
                            errors.tax
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({})} />
                    {errors.tax && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>



            <div className={discountType === 'TOTAL' ? 'form-group col-md-3' : 'form-group col-md-6'}>
                <label className="col-form-label text-secondary">Type de remise:</label>
                <div className="">

                    <select
                        name="discountType"
                        onChange={recalculatItemsPrice}
                        className={
                            errors.discountType
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({})} >
                        <option value=""></option>
                        {
                            discountTypes.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>

                    {errors.discountType && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>


            {discountType === 'TOTAL' && <div className="form-group col-md-3">
                <label className="col-form-label text-secondary">Remise:</label>
                <div className="">
                    <input
                        type="number"
                        min="0"
                        name="discountValue"
                        onChange={calculatorTotalPrice}
                        className={
                            errors.discountValue
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({})} />
                    {errors.discountValue && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>}


            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Devise:</label>
                <div className="">

                    <select
                        name="currencyId"
                        className={
                            errors.currencyId
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} >
                        <option value=""></option>
                        {
                            currencies.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>

                    {errors.currencyId && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>


            <fieldset className="form-group col-md-12 border-top mt-3 mb-3 p-0">
                <legend className="col-form-label text-secondary p-1" style={{ width: 'auto' }}>Les elements</legend>

                <table className="table table-striped mt-3 p-0">
                    <thead className="thead-dark p-0">
                        <tr>
                            <th className=" text-capitalize">Désignation</th>
                            <th style={{ width: '10%' }} className="text-capitalize">Qte</th>
                            <th style={{ width: '12%' }} className="text-capitalize">Unité</th>
                            {discountType === 'BY_ITEM' && <th style={{ width: '7%' }} className="text-capitalize">Remise</th>}
                            <th style={{ width: '10%' }} className="text-capitalize">Px.Unitaire</th>
                            <th style={{ width: '15%' }} className="text-capitalize">total</th>
                            <th style={{ width: '4%' }} className="text-capitalize"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((item, index) => {
                            return (
                                <tr key={item.id}>
                                    <td>
                                        <div className="row ml-1">
                                            <select
                                                name={`quotationItem[${index}].projectId`}
                                                className={
                                                    errors?.quotationItem && errors?.quotationItem[index]?.projectId
                                                        ? "form-control col-md-4 mr-2 is-invalid"
                                                        : "form-control col-md-4 mr-2"}
                                                ref={register({ required: true })} >
                                                <option value=""></option>
                                                {
                                                    projects.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                                }
                                            </select>

                                            <input
                                                type="text"
                                                name={`quotationItem[${index}].designation`}
                                                className={
                                                    errors?.quotationItem && errors?.quotationItem[index]?.designation
                                                        ? "form-control col-md-7 is-invalid"
                                                        : "form-control col-md-7"}
                                                ref={register({ required: true })} />

                                        </div>
                                    </td>

                                    <td>
                                        <input
                                            type="number"
                                            min="0"
                                            step="0.01"
                                            name={`quotationItem[${index}].quantity`}
                                            className={
                                                errors?.quotationItem && errors?.quotationItem[index]?.quantity
                                                    ? "form-control is-invalid"
                                                    : "form-control"}
                                            ref={register({ required: true })}
                                            onChange={recalculatItemsPrice} />
                                    </td>

                                    <td>
                                        <select
                                            name={`quotationItem[${index}].unityId`}
                                            className={
                                                errors?.quotationItem && errors?.quotationItem[index]?.unityId
                                                    ? "form-control is-invalid"
                                                    : "form-control"}
                                            ref={register({ required: true })} >
                                            <option value=""></option>
                                            {
                                                unities.map(item => <option key={item.id} value={item.id}>{item.code}</option>)
                                            }
                                        </select>
                                    </td>

                                    {discountType === 'BY_ITEM' &&
                                        <td>
                                            <input
                                                type="number"
                                                min="0"
                                                step="0.01"
                                                onChange={(e) => {
                                                    setValue(`quotationItem[${index}].discount`, e.target.value)
                                                    recalculatItemsPrice();
                                                }}
                                                name={`quotationItem[${index}].discount`}
                                                className={"form-control"}
                                                ref={register({})} />
                                        </td>
                                    }

                                    <td>
                                        <input
                                            type="number"
                                            min="0"
                                            step="0.01"
                                            onChange={recalculatItemsPrice}
                                            name={`quotationItem[${index}].unitPrice`}
                                            className={
                                                errors?.quotationItem && errors?.quotationItem[index]?.unitPrice
                                                    ? "form-control is-invalid"
                                                    : "form-control"}
                                            ref={register({ required: true })} />
                                    </td>


                                    <td>
                                        <input
                                            readOnly
                                            type="text"
                                            name={`quotationItem[${index}].total`}
                                            className={"form-control border-0"}
                                            ref={register({ total: true })} />
                                    </td>

                                    <td>
                                        <div className="">
                                            {fields.length > 1 && <button type="button" className="btn btn-sm btn-danger" onClick={() => removeItem(index)}>
                                                <DeleteIcon />
                                            </button>}
                                        </div>
                                    </td>


                                </tr>

                            );
                        })}



                        <tr className="table-light border-0 pb-0">
                            <td className="border-0 pl-0" colSpan={discountType === 'BY_ITEM' ? '3' : '2'}>
                                <button
                                    className="btn btn-sm btn-light mr-0"
                                    type="button"
                                    onClick={() => append(itemModel)}
                                >
                                    <i className="material-icons">library_add</i>
                                </button>
                            </td>
                            <td className="border-0 text-secondary text-capitalize text-right" colSpan="2"><b>Total:</b></td>
                            <td className="border-0 text-secondary text-capitalize pl-3"> {quotationPrice.total}</td>
                        </tr>
                        {quotationPrice.tax > 0 && (<tr className="table-light border-0">
                            <td className="border-0" colSpan={discountType === 'BY_ITEM' ? '3' : '2'}>
                            </td>
                            <td className="border-0 text-secondary text-capitalize text-right" colSpan="2"><b>TVA ({quotationPrice.tax} %):</b></td>
                            <td className="border-0 text-secondary text-capitalize pl-3"> {quotationPrice.taxValue}</td>
                        </tr>)}
                        {quotationPrice.globalDiscount > 0 && (<tr className="table-light border-0">
                            <td className="border-0" colSpan={discountType === 'BY_ITEM' ? '3' : '2'}>
                            </td>
                            <td className="border-0 text-secondary text-capitalize text-right" colSpan="2"><b>Remise ({quotationPrice.discountValue} %):</b></td>
                            <td className="border-0 text-secondary text-capitalize pl-3"> - {quotationPrice.globalDiscount}</td>
                        </tr>)}
                        <tr className="table-light border-0">
                            <td className="border-0" colSpan={discountType === 'BY_ITEM' ? '3' : '2'}>
                            </td>
                            <td className="border-0 text-secondary text-capitalize text-right" colSpan="2"><b>Total TTC :</b></td>
                            <td className="border-0 text-secondary text-capitalize pl-3"> {quotationPrice.totalFinal}</td>
                        </tr>

                    </tbody>
                </table>

            </fieldset>

            <div>

            </div>


            <div className="form-group col-md-12 text-right mt-2">

                <ButtonCancel onClick={() => props.doClose()}></ButtonCancel>
                <ButtonSave type="submit"></ButtonSave>

            </div>
        </form>
    );
}




export default (props) => {

    const customer = props?.data?.customer || null;


    /**
    * 
    */
    const [state, setState] = useState({
        dataLoaded: false,
        defaultData: {},
        unities: [],
        currencies: [],
        projects: [],
        customers: [],
        billingAddresses: [],
        discountTypes: [
            { id: 'BY_ITEM', name: 'Par Element' },
            { id: 'TOTAL', name: 'Sur Total' }
        ],
        disabledFields: (() => {
            const ret = [];
            if (customer) { ret.push('customerId') }
            return ret;
        })()
    });


    useEffect(() => {
        loadData();
    }, []);



    /**
       * 
       */
    const loadData = () => {
        Promise.all([
            getUnities(),
            getCurrencies(),
            getProjects(),
            getCustomers({}),
            getDataQuotation(),
            getBillingAddresses(customer ? { where: { customerId: customer.id } } : {})
        ])
            .then(data => {
                const [
                    { getAllUnity },
                    { getAllCurrency },
                    { getAllProject },
                    { getAllCustomer },
                    { getQuotation },
                    { getAllBillingAddress }
                ] = data;
                const defaultData = dataAdapter(getQuotation);
                setState({
                    ...state,
                    dataLoaded: true,
                    unities: getAllUnity.items,
                    currencies: getAllCurrency.items,
                    projects: getAllProject.items,
                    customers: getAllCustomer.items,
                    billingAddresses: getAllBillingAddress.items,
                    defaultData: defaultData
                });
            })
            .catch(error => {
                console.log('error :', error.message);
            });
    }


    /**
    * 
    */
    const getUnities = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllUnityQuery, gqlQueryParam);

    }

    /**
    * 
    */
    const getCurrencies = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllCurrencyQuery, gqlQueryParam)
    }


    /**
    * 
    */
    const getProjects = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllProjectQuery, gqlQueryParam)
    }



    /**
      * 
      */
    const getCustomers = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllCustomerQuery, gqlQueryParam)
    }



    /**
* 
*/
    const getDataQuotation = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getQuotationQuery, { ...gqlQueryParam, id: props.data.id });

    }

    /**
* 
*/
    const getBillingAddresses = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllBillingAddressQuery, gqlQueryParam);

    }

    const dataAdapter = (data) => {
        return {
            id: data.id,
            customerId: data.customerId,
            date: data.date,
            quotationId: data.quotationId,
            tax: data.tax,
            title: data.title,
            description: data.description,
            address: data.address,
            discountType: data.discountType,
            discountValue: data.discountValue,
            currencyId: data.currencyId,
            billingAddressId: data.billingAddressId,
            total: data.total,
            totalTTC: data.totalTTC,
            quotationItem: data.quotationItem.map(item => {
                return {
                    projectId: item?.projectId,
                    description: item.description,
                    designation: item.designation,
                    discount: item.discount,
                    quantity: item.quantity,
                    total: item.total,
                    unitPrice: item.unitPrice,
                    unityId: item.unityId,
                }
            })

        };

    }



    return (
        <>
            {
                state.dataLoaded ?
                    (<Form
                        defaultData={state.defaultData}
                        unities={state.unities}
                        currencies={state.currencies}
                        projects={state.projects}
                        customers={state.customers}
                        billingAddresses={state.billingAddresses}
                        discountTypes={state.discountTypes}
                        getCustomers={getCustomers}
                        getBillingAddresses={getBillingAddresses}
                        disabledFields={state.disabledFields}
                        {...props} />)
                    : '...Loading'
            }
        </>
    )

}