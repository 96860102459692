/**
 * @flow
 * @relayHash bd218e291319bee6cde767b97ea19ec7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InvoiceSupplierCreateInput = {|
  num: string,
  title: string,
  description?: ?string,
  address: string,
  date: any,
  tax?: ?string,
  postStamp?: ?string,
  discountType?: ?string,
  discountValue?: ?string,
  total: string,
  totalTTC: string,
  currencyId: string,
  supplierId: string,
  invoiceSupplierItem: $ReadOnlyArray<InvoiceSupplierItemCreateInput>,
|};
export type InvoiceSupplierItemCreateInput = {|
  designation: string,
  description?: ?string,
  quantity: string,
  unityId: string,
  discount?: ?string,
  unitPrice: string,
  total: string,
|};
export type CreateInvoiceSupplierMutationVariables = {|
  data: InvoiceSupplierCreateInput
|};
export type CreateInvoiceSupplierMutationResponse = {|
  +createInvoiceSupplier: {|
    +id: string
  |}
|};
export type CreateInvoiceSupplierMutation = {|
  variables: CreateInvoiceSupplierMutationVariables,
  response: CreateInvoiceSupplierMutationResponse,
|};
*/


/*
mutation CreateInvoiceSupplierMutation(
  $data: InvoiceSupplierCreateInput!
) {
  createInvoiceSupplier(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "InvoiceSupplierCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createInvoiceSupplier",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "InvoiceSupplierModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateInvoiceSupplierMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateInvoiceSupplierMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateInvoiceSupplierMutation",
    "id": null,
    "text": "mutation CreateInvoiceSupplierMutation(\n  $data: InvoiceSupplierCreateInput!\n) {\n  createInvoiceSupplier(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '09721f9a7f00f8658ed39a596da7301a';
module.exports = node;
