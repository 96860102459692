import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import { MdDeleteForever, MdAdd } from 'react-icons/md';
import { FaRegEdit, FaRegSave, FaBackspace, FaRegFilePdf } from 'react-icons/fa';
import { emphasize } from "@material-ui/core/styles/colorManipulator";

const useStylesBtn = makeStyles(theme => ({
  saveBtn: {
    color: theme.palette.white,
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }

  },
  cancelBtn: {
    backgroundColor: theme.palette.primary.light,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.white,
    }
  },
  confirmDeleteBtn: {
    color: theme.palette.error.contrastText,
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.error.main,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.white,
    }
  },
}));


const useStyles = makeStyles({
  root: {
    background: ({ backgroundColor }) => backgroundColor,
    marginRight: '5px',
    color: ({ color }) => color,
    "&:hover, &:focus": {
      background: ({ hoverBackgroundColor, backgroundColor }) =>
        hoverBackgroundColor
          ? hoverBackgroundColor
          : emphasize(backgroundColor, 0.09)
    },
  },
  extendedIcon: {
    marginRight: '5px',
  },
});

/**
 * 
 * @param {*} props 
 */
const ButtonAdd = (props) => {
  const classes = useStyles({ color: '#FFF', backgroundColor: '#54B8FF', hoverBackgroundColor: '#5493FF' });
  return (
    <Fab onClick={() => props.onClick()} size="small"><MdAdd size={17} /></Fab>
  );
};

/**
 * 
 * @param {*} props 
 */
const ButtonUpdate = (props) => {
  const classes = useStyles({ color: '#FFF', backgroundColor: '#A1571B' });
  return (
    <Fab onClick={() => props.onClick()} size="small"><FaRegEdit size={17} /></Fab>
  );
};


/**
 * 
 * @param {*} props 
 */
const ButtonDelete = (props) => {
  const classes = useStyles({ color: '#FFF', backgroundColor: '#FF6038' });
  return (
    <Fab onClick={() => props.onClick()} size="small"><MdDeleteForever size={17} /></Fab>
  );
};



/**
 * 
 * @param {*} props 
 */
const ButtonConfirmDelete = (props) => {
  const classes = useStylesBtn()
  return (

    <Button className={classes.confirmDeleteBtn} {...props} size="small">
      Supprimer
    </Button>
  );
};



/**
 * 
 * @param {*} props 
 */
const ButtonSave = (props) => {
  const classes = useStylesBtn()
  return (



    <Button className={classes.saveBtn} {...props} size="small">
      Enregistrer
      </Button>


  );
};


/**
 * 
 * @param {*} props 
 */
const ButtonCancel = (props) => {
  const classes = useStylesBtn()
  return (

    <Button className={classes.cancelBtn} {...props} size="small">
      Annuler
    </Button>
  );
};



/**
 * 
 * @param {*} props 
 */
const ButtonPdf = (props) => {
  return (
    <Fab onClick={() => props.onClick()} size="small" {...props} ><FaRegFilePdf size={17} /></Fab>
  );

};




export {
  ButtonAdd,
  ButtonUpdate,
  ButtonDelete,
  ButtonSave,
  ButtonCancel,
  ButtonConfirmDelete,
  ButtonPdf
};