
import React from "react";
import Invoice from '../../components/Invoice/Invoice';
/** Components */
import ProjectWrapper from '../../components/ProjectWrapper';


export default (props) => {
  const projectId = props.match.params.id;
  return (

    <ProjectWrapper {...props}>
      <Invoice projectId={projectId} defaultFilter={{projectId}} />
    </ProjectWrapper>

  );

}

