import {
  graphql
} from 'react-relay'






const query = graphql`
    query getAllInvoiceSupplierQuery($pagination: PaginationInput, $sorts: [OrderInput!], $where: JSON) {
        getAllInvoiceSupplier (pagination: $pagination, sorts: $sorts, where: $where){
            count,
            items{
                  id
                  title
                  description
                  date
                  num
                  tax
                  postStamp
                  discountType
                  discountValue
                  total
                  totalTTC
                  currencyId
                  supplierId
                  createdAt
                  updatedAt
                  deletedAt
                  supplier {id, name}
                }
      }
    }
  `

export default query;