import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './themes/mat-theme';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/store';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import "bootstrap/dist/css/bootstrap.css";
import PrivateLayout from "layouts-md/Private.js";
import PublicLayout from "layouts/Public";
const hist = createBrowserHistory();


ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router history={hist}>
        <Switch>
          <Route path="/private" render={props => <PrivateLayout {...props} />} />
          <Route path="/public" render={props => <PublicLayout {...props} />} />
          <Redirect to="/public/login" />
        </Switch>
      </Router>
      </MuiPickersUtilsProvider>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
