import React, { useEffect, useState } from "react";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const style = {
  marginRight: "2px",
  cursor: 'pointer'

};

export default (props) => {


  const [state, setState] = useState({
    direction: null
  })

  /**
 * 
 */
  useEffect(() => {
    const { modele, currentOrder, field } = props;
    if (currentOrder !== undefined) {
      const currentModele = currentOrder.modele;
      const currentField = currentOrder.field;
      const currentDirection = currentOrder.direction;
      if (currentModele === modele && currentField === field) {
        setState({ direction: currentDirection });
      } else {
        setState({ direction: null });
      }
    }

  }, [props.currentOrder]);

  /**
   * 
   */
  const toggleDirection = () => {
    return (state.direction === 'ASC') ? 'DESC' : 'ASC';
  }


  return (
    <>
      <a style={style} onClick={() => props.onClick({ modele: props.modele, field: props.field, direction: toggleDirection() })}>{props.children}</a>
      {state.direction === 'ASC' ? <ArrowUpwardIcon /> : (state.direction === 'DESC' ? <ArrowDownwardIcon /> : '')}
    </>
  );
}