/**
 * @flow
 * @relayHash 14ca85f5bb1cd58baf99a612454da03b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupplierUpdateInput = {|
  id: string,
  name: string,
  categoryId: string,
|};
export type UpdateSupplierMutationVariables = {|
  data: SupplierUpdateInput
|};
export type UpdateSupplierMutationResponse = {|
  +updateSupplier: {|
    +id: string
  |}
|};
export type UpdateSupplierMutation = {|
  variables: UpdateSupplierMutationVariables,
  response: UpdateSupplierMutationResponse,
|};
*/


/*
mutation UpdateSupplierMutation(
  $data: SupplierUpdateInput!
) {
  updateSupplier(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "SupplierUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateSupplier",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "SupplierModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateSupplierMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateSupplierMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateSupplierMutation",
    "id": null,
    "text": "mutation UpdateSupplierMutation(\n  $data: SupplierUpdateInput!\n) {\n  updateSupplier(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6529127fd6b05db0becabe994037db4f';
module.exports = node;
