
import React from "react";
import Invoice from '../../components/Invoice/Invoice';

import SalesWrapper from '../../components/SalesWrapper';

export default (props) => {

    return (
        <SalesWrapper {...props}>
            <Invoice borderCard={false} />
        </SalesWrapper>
    );

}

