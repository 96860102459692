/**
 * @flow
 * @relayHash 315745d2a32c8a8d6780b6285153b28c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupplierContactCreateInput = {|
  name: string,
  supplierId?: ?string,
  professionId?: ?string,
  supplierContactItems: $ReadOnlyArray<SupplierContactItemCreateInput>,
|};
export type SupplierContactItemCreateInput = {|
  information: string,
  contactMediumId: string,
|};
export type CreateSupplierContactMutationVariables = {|
  data: SupplierContactCreateInput
|};
export type CreateSupplierContactMutationResponse = {|
  +createSupplierContact: {|
    +id: string
  |}
|};
export type CreateSupplierContactMutation = {|
  variables: CreateSupplierContactMutationVariables,
  response: CreateSupplierContactMutationResponse,
|};
*/


/*
mutation CreateSupplierContactMutation(
  $data: SupplierContactCreateInput!
) {
  createSupplierContact(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "SupplierContactCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createSupplierContact",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "SupplierContactModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateSupplierContactMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateSupplierContactMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateSupplierContactMutation",
    "id": null,
    "text": "mutation CreateSupplierContactMutation(\n  $data: SupplierContactCreateInput!\n) {\n  createSupplierContact(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '812323f67f75a16c94f6e765843f11ca';
module.exports = node;
