
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from './MenuItem';
import SidebarBrand from './SidebarBrand';
import routes from "routes.js";


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'auto',
    maxWidth: 360,
    backgroundColor: theme.palette.background.sidebar,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const Sidebar = (props) => {

  

  return (

    <List
      component="nav"
      aria-label="main mailbox folders"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <SidebarBrand userInfo={props.state} />
        </ListSubheader>
      }
      
    >
      {routes
        .filter(rItem => (rItem.layout === '/private' && !rItem.hidden))
        .map((item, key) => <MenuItem key={key} level={1} item={item} />)}
    </List>

  );
}


const mapStateToProps = state => ({
  state: state.infoUserLoggedReducer,
});

export default connect(
  mapStateToProps
)(Sidebar);


