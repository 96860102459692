import {
  graphql
} from 'react-relay'






const query = graphql`
    query getAllContactQuery($pagination: PaginationInput, $sorts: [OrderInput!], $where: JSON) {
        getAllContact (pagination: $pagination, sorts: $sorts, where: $where){
            count,
            items{
                  id
                  name
                  customerId
                  professionId
                  customer {id, name}
                  profession{id, name}
                }
      }
    }
  `

export default query;