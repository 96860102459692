import React from "react";

/** DRAWER */
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

/** DIALOG 
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});*/

const drawerWidth = 1150;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: drawerWidth,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: theme.palette.white
    },
    title: {
        flexGrow: 1,
        color: theme.palette.white
    },
    appBar: {
        backgroundColor: theme.palette.primary.dark,
    },
    content: {
        width: drawerWidth,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentItem : {
        width: '95%',
        maxHeight: '95%'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
}));

export default (props) => {

    const classes = useStyles();
    return (

        <div className={classes.root}>
            <Drawer
                anchor="right"
                open={props.open}
                variant="temporary"
                onClose={() => props.onClose()}
                transitionDuration={900}
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: props.open,
                    [classes.drawerClose]: !props.open,
                })}
            >


                <AppBar position="static" className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {props.title}
                        </Typography>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                            onClick={() => props.onClose()}>
                            <i className="material-icons">close</i>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className={classes.content}>
                <div className={classes.contentItem}>
                    {props.children}
                    </div>
                </div>


            </Drawer>
            {/* <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={props.open}
            TransitionComponent={Transition}
            onClose={() => props.onClose()}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle className="border" id="alert-dialog-slide-title">{props.title}</DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>

        </Dialog> */}
        </div>
    );
}