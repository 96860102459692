import {
    graphql
} from 'react-relay'






const query = graphql`
    query getAllSupplierQuery($pagination: PaginationInput, $sorts: [OrderInput!], $where: JSON) {
        getAllSupplier (pagination: $pagination, sorts: $sorts, where: $where){
            count,
            items{
                id, 
                name, 
                categoryId,
                createdAt, 
                updatedAt, 
                category{id, name}
                }
      }
    }
  `

export default query;