
import React from "react";
import Settlement from '../../components/Settlement/Settlement';

import SalesWrapper from '../../components/SalesWrapper';

export default (props) => {

    return (
        <SalesWrapper {...props}>
            <Settlement borderCard={false} />
        </SalesWrapper>
    );

}

