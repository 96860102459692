
import React, { useState, useEffect } from "react";
/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';

/** GRAPHQL */
import { fetchQuery } from 'react-relay';
import getAllHtmlTemplateQuery from '../../graphql/html-template/queries/getAllHtmlTemplateQuery';
import UpdateHtmlTemplateMutation from '../../graphql/html-template/mutations/UpdateHtmlTemplateMutation';
import environment from '../../graphql/Environment';
import { OPERATOR } from '../../graphql/constants';

import Pagination from '../../components/Pagination';
import ModalDialog from '../../components/ModalDialog';
import MsgSnackbar from '../../components/MsgSnackbar';

/** COMPONENTS */
import TableToolbar from '../../components/TableToolbar';
import Update from './components/Update';
import Create from './components/Create';
import Delete from './components/Delete';
import moment from '../../helpers/moment-helper';
import SortableFieldWrapper from '../../components/SortableFieldWrapper';

import Switch from '@material-ui/core/Switch';



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  }
}));



const INIT_STATE_DIALOG = {
  openDialog: false,
  action: null,
  targetData: null,
};

const INIT_MSG_SNACKBAR = {
  message: '',
  variant: 'success',
  open: false,
};

const INIT_ORDER = {
  modele: null,
  field: 'name',
  direction: 'DESC'
};


export default (props) => {

  const classes = useStyles();
  const defaultFilter = props.defaultFilter || {};

  const [state, setState] = useState({
    itemsPerPage: 10,
    currentPage: 0,
    countTotal: 0,
    searchTerm: '',
    data: [],
    order: INIT_ORDER,
    dialog: INIT_STATE_DIALOG,
    msgSnackbar: INIT_MSG_SNACKBAR
  });




  /**
   * 
   */
  useEffect(() => {
    loadData();
  }, [state.itemsPerPage, state.currentPage, state.searchTerm, state.order, props.defaultFilter]);


  /**
   * 
   * @param {*} event 
   * @param {*} newPage 
   */
  const handleChangePage = (event, newPage) => {
    setState({ ...state, currentPage: newPage });
  };

  /**
   * 
   * @param {*} event 
   */
  const handleChangeRowsPerPage = event => {
    setState({ ...state, currentPage: 0, itemsPerPage: Number(event.target.value) });
  };

  /**
   * 
   * @param {*} action 
   * @param {*} data 
   */
  const openDialog = (action, data = null) => {
    setState({ ...state, dialog: { action: action, openDialog: true, targetData: data } });
  }

  /**
   * 
   * @param {*} event 
   */
  const handleCloseDialog = (event = null) => {
    if (event && event.status !== undefined && event.status === 'OK') {
      const msgSnackbar = event.msgSnackbar || {};
      loadData({ msgSnackbar });
    } else {
     setState({ ...state, dialog: { ...state.dialog, openDialog: false } });
    }
  }

  /**
   * 
   */
  const loadData = (propsState = {}) => {
    const gqlQueryParam = {
      pagination: {
        offset: state.currentPage,
        limit: state.itemsPerPage
      },
      sorts: [{ ...state.order }],
      where: (state.searchTerm !== '')
        ? {
          ...defaultFilter,
          [OPERATOR.or]: [
            { 'name': { [OPERATOR.substring]: state.searchTerm } },
            { '$categoryTemplate.name$': { [OPERATOR.substring]: state.searchTerm } },
            { '$categoryTemplate.code$': { [OPERATOR.substring]: state.searchTerm } }
          ]
        } : { ...defaultFilter }
    };

    console.log({ gqlQueryParam });

    fetchQuery(environment, getAllHtmlTemplateQuery, gqlQueryParam)
      .then(data => {
        const { count, items } = data.getAllHtmlTemplate;
        setState({
          ...state,
          ...propsState,
          countTotal: count,
          data: items,
          dialog: { ...state.dialog, openDialog: false }
        });
      }).catch(error => {
        console.log('error :', error.message);
      });
  }



  const hideMsgSnackbar = () => {

    setState({
      ...state,
      msgSnackbar: INIT_MSG_SNACKBAR
    })
  }

  /**
   * 
   * @param {*} value 
   */
  const handelSubmitSearch = (value) => {
    setState({ ...state, currentPage: 0, searchTerm: value });
  }

  /**
   * 
   * @param {*} field 
   */
  const handelClickSort = (ordering) => {
    setState({ ...state, order: { ...state.order, ...ordering } });
  }
  /**
  * 
  */
  const DialogViwer = () => {

    const { action, targetData, openDialog } = state.dialog;
    const mapActions = {
      'ADD': { component: Create, title: 'ADD' },
      'UPDATE': { component: Update, title: 'Update' },
      'DELETE': { component: Delete, title: 'Delete' }
    };

    const Component = (Object.keys(mapActions).indexOf(action) >= 0)
      ? mapActions[action].component
      : null;
    const title = (Object.keys(mapActions).indexOf(action) >= 0)
      ? mapActions[action].title
      : '';


    return (
      <ModalDialog
        title={title}
        open={openDialog}
        onClose={(event) => handleCloseDialog(event)}
      >
        {Component && <Component
          data={targetData}
          doClose={(event) => handleCloseDialog(event)} />}
      </ModalDialog>
    )
  }



  const setIsDefault = (item, isDefault) => {

    const data = {
      id: item.id,
      categoryTemplateId: item.categoryTemplateId,
      isDefault: isDefault,
      name: item.name,
      tpl: item.tpl
    }
    update(data);
  }


  /**
     * 
     * @param {*} data 
     */
  const update = data => {
    UpdateHtmlTemplateMutation({ ...data }, (response) => {
      if (response.status === 'OK') {
        loadData();
      } else {
        console.log({ error: 'Erreur' });
      }

    });
  }

  return (




    <>

     <div className={classes.root}>
        <Paper >
          <TableToolbar
            title='Les templates'
            countTotal={state.countTotal}
            handelSubmitSearch={handelSubmitSearch}
            handelAddClick={() => openDialog('ADD')}
          />
          <TableContainer>
            <Table  aria-label="customized table">
              <TableHead>
                <TableRow>


                  <TableCell align="left"><SortableFieldWrapper currentOrder={state.order} modele={null} field='name' onClick={(event) => handelClickSort(event)}>Nom</SortableFieldWrapper></TableCell>
                  <TableCell align="left"><SortableFieldWrapper currentOrder={state.order} modele='categoryTemplate' field='name' onClick={(event) => handelClickSort(event)}>Catégorie</SortableFieldWrapper></TableCell>
                  <TableCell align="left"><SortableFieldWrapper currentOrder={state.order} modele='categoryTemplate' field='code' onClick={(event) => handelClickSort(event)}>Code Catégorie</SortableFieldWrapper></TableCell>
                  <TableCell align="left"><SortableFieldWrapper currentOrder={state.order} modele={null} field='createdAt' onClick={(event) => handelClickSort(event)}>Date création</SortableFieldWrapper></TableCell>
                  <TableCell align="center">Par defaut</TableCell>
                  <TableCell align="center">Actions</TableCell>


                </TableRow>
              </TableHead>
              <TableBody>
                { state.data.length > 0 &&  (state.data || []).map(item => (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item?.categoryTemplate?.name}</TableCell>
                    <TableCell>{item?.categoryTemplate?.code}</TableCell>
                    <TableCell>{moment(item.createdAt).format('DD-MM-YYYY / HH:mm:ss ')}</TableCell>
                    <TableCell align="center">
                      <Switch
                        checked={item.isDefault}
                        onChange={(e) => { setIsDefault(item, e.target.checked) }}

                        name="isDefault"
                        color="primary"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton aria-label="filter list" onClick={() => openDialog('DELETE', item)}>
                        <i className="material-icons">delete</i>
                      </IconButton>
                      <IconButton aria-label="filter list" onClick={() => openDialog('UPDATE', item)}>
                        <i className="material-icons">create</i>
                      </IconButton>
                    </TableCell>
                  </TableRow>

                ))}

              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            countTotal={state.countTotal}
            itemsPerPage={state.itemsPerPage}
            currentPage={state.currentPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />


        </Paper>
      </div>

      { DialogViwer() }
      <MsgSnackbar {...state.msgSnackbar} onClose={hideMsgSnackbar} />

    </>
  );

}

