import {
    graphql
} from 'react-relay'






const query = graphql`
    query getQuotationQuery($id: String!) {
        getQuotation (id: $id){
            id
            title
            description
            address,
            date
            num
            tax
            discountType
            discountValue
            total
            totalTTC
            billingAddressId
            currencyId
            customerId
            createdAt
            updatedAt
            deletedAt
            currency {id, name, code}
            customer {id, name}
            quotationItem{
            id
            projectId
            designation
            description
            quantity
            unityId
            discount
            unitPrice
            total
            unity {id, name, code}
            project{id, name}
            }
            
      }
    }
  `

export default query;