import {
    commitMutation,
    graphql
} from 'react-relay'
import environment from '../../Environment'


const mutation = graphql`
    mutation LoginMutation($email: String!, $password: String!) {
        login(email:$email, password:$password) {
            id,
            appId,
            accessToken
      }
    }
  `

export default (email, password, callback) => {
    const variables = {
        email,
        password
    }

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback({status: 'OK', pyload: response.login})
            },
            onError: err =>  callback({status: 'KO', message: err})
        },
    )
}