export default {
    root: {
        fontSize: '1rem',
        color: '#66615b',
        border: '1px solid #DDDDDD',
        borderRadius: '4px',
        lineHeight: 'normal',
        boxShadow: 'none',
        transition: 'color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out',
        padding: '0.375rem 0.75rem;',
        width: '100%',
        height: 'calc(1.5em + 0.75rem + 2px)'
    },
    underline: {
        '&:hover': {
            borderBottom: 'none'
        },
        '&:before': {
            borderBottom: 'none'
        },
        '&:after': {
            borderBottom: 'none'
        },
    },
};