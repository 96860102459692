import {
    graphql
} from 'react-relay'






const query = graphql`
    query getAllCustomerQuery($pagination: PaginationInput, $sorts: [OrderInput!], $where: JSON) {
        getAllCustomer (pagination: $pagination, sorts: $sorts, where: $where){
            count,
            items{
                id, 
                name, 
                createdAt, 
                updatedAt, 
                category{id, name},
                origin{id, name}
                }
      }
    }
  `

export default query;