
import React from "react";

/** Components */
import Contact from '../../components/Contact/Contact';
import CustomerWrapper from '../../components/CustomerWrapper';



export default (props) => {
  const customerId = props.match.params.id;

  return (
    <CustomerWrapper {...props}>
      <Contact customerId={customerId} defaultFilter={{customerId}} />
    </CustomerWrapper>

  );

}

