import {
  graphql
} from 'react-relay'






const query = graphql`
    query getAllQuotationQuery($pagination: PaginationInput, $sorts: [OrderInput!], $where: JSON) {
        getAllQuotation (pagination: $pagination, sorts: $sorts, where: $where){
            count,
            items{
                  id
                  title
                  description
                  date
                  num
                  tax
                  discountType
                  discountValue
                  total
                  totalTTC
                  currencyId
                  customerId
                  createdAt
                  updatedAt
                  deletedAt
                  customer {id, name}
                }
      }
    }
  `

export default query;