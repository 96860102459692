import {
    graphql
} from 'react-relay'






const query = graphql`
    query getProjectQuery($id: String!) {
        getProject (id: $id){
            id, 
            name, 
            customerId,
            createdAt, 
            updatedAt, 
            customer{id, name}
            
      }
    }
  `

export default query;