import {
    graphql
} from 'react-relay'






const query = graphql`
    query getCustomerQuery($id: String!) {
        getCustomer (id: $id){
            id, 
            name, 
            createdAt, 
            updatedAt
      }
    }
  `

export default query;