
import React from "react";
import QuotationSupplier from '../../components/QuotationSupplier/QuotationSupplier';

import PurchasesWrapper from '../../components/PurchasesWrapper';

export default (props) => {

    return (
        <PurchasesWrapper {...props}>
            <QuotationSupplier />
        </PurchasesWrapper>
    );

}

