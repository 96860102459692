import React, { useState, useEffect } from "react";

/** Graphql */
import { fetchQuery } from 'react-relay';
import getAllCategoryTemplateQuery from '../../../graphql/category-template/queries/getAllCategoryTemplateQuery';
import CreateHtmlTemplateMutation from '../../../graphql/html-template/mutations/CreateHtmlTemplateMutation';
import environment from '../../../graphql/Environment';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-github";

import Switch from '@material-ui/core/Switch';

import { useForm } from 'react-hook-form';
import { ButtonSave, ButtonCancel } from '../../../components/Buttons';
const ace = require('ace-builds/src-noconflict/ace');
ace.config.set("basePath", "https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/");
ace.config.setModuleUrl('ace/mode/javascript_worker', "https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/worker-javascript.js");

const Form = (props) => {


    const { defaultData, categories } = props;

    const {
        register,
        handleSubmit,
        errors,
        setError,
        setValue,
        clearError
    } = useForm({
        mode: "onChange",
        defaultValues: defaultData
    });



    /**
* 
*/
    useEffect(() => {
        register({ name: "tpl" }, { required: true });
    }, [register]);
    const [isDefault, setIsDefault] = useState(defaultData?.isDefault || false);
    const [code, setCode] = useState(defaultData?.tpl || '');

    /**
     * 
     * @param {*} data 
     */
    const create = data => {
        CreateHtmlTemplateMutation({ ...data }, (response) => {
            if (response.status === 'OK') {
                props.doClose({
                    ...response, msgSnackbar: {
                        message: 'C bon c fait',
                        variant: 'success',
                        open: true,
                    }
                });
            } else {
                console.log({ error: 'Erreur' });
            }

        });
    }


    /**
     * 
     * @param {*} data 
     */
    const onSubmit = data => create(data);

    return (


        <form onSubmit={handleSubmit(onSubmit)} className="row p-5" >
            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Catégorie:</label>
                <div className="">

                    <select
                        name="categoryTemplateId"
                        className={
                            errors.categoryTemplateId
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"}
                        ref={register({ required: true })} >
                        <option value=""></option>
                        {
                            categories.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>

                    {errors.categoryTemplateId && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>


            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Nom:</label>
                <div className="">
                    <input
                        type="text"
                        name="name"
                        className={
                            errors.name
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"}
                        ref={register({ required: true })} />
                    {errors.name && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>


            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Par défaut:</label>
                <div className="">


                    <Switch
                        checked={isDefault}
                        onChange={(e) => {
                            setValue('isDefault', e.target.checked);
                            setIsDefault(e.target.checked)
                        }}

                        name="isDefault"
                        color="primary"
                        inputRef={register}
                    />
                </div>
            </div>



            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Template:</label>
                <div className="">


                    <AceEditor
                        mode="ejs"
                        theme="github"
                        value={code}
                        onChange={(value) => {
                            setValue('tpl', value);
                            if (value === "") {
                                setError('tpl', 'required', 'This field is required');
                            }
                            else {
                                clearError('tpl')
                            }
                            setCode(value)
                        }}
                        name="tpl"
                        width="100%"
                        editorProps={{ $blockScrolling: true }}
                        refs={register({ required: true })}
                        className={
                            errors.tpl
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"}
                    />
                    {errors.tpl && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>



            <div className="form-group col-md-12 text-right mt-2">

                <ButtonCancel onClick={() => props.doClose()}></ButtonCancel>
                <ButtonSave type="submit"></ButtonSave>

            </div>
        </form>
    );
}


export default (props) => {


    /**
    * 
    */
    const [state, setState] = useState({
        dataLoaded: false,
        defaultData: {},
        categories: []
    });


    useEffect(() => {
        loadData();
    }, []);



    /**
       * 
       */
    const loadData = () => {
        Promise.all([getCategories()])
            .then(data => {
                const [{ getAllCategoryTemplate }] = data;
                const defaultData = getDefaultData();
                setState({
                    ...state,
                    dataLoaded: true,
                    categories: getAllCategoryTemplate.items,
                    defaultData: defaultData
                });
            })
            .catch(error => {
                console.log('error :', error.message);
            });
    }


    /**
* 
*/
    const getCategories = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllCategoryTemplateQuery, gqlQueryParam);

    }



    const getDefaultData = () => {

        return {
            name: '',
            tpl: '',
            categoryTemplateId: null,
            isDefault: false,
        };

    }



    return (
        <>
            {
                state.dataLoaded ?
                    (<Form
                        defaultData={state.defaultData}
                        categories={state.categories}
                        {...props} />)
                    : '...Loading'
            }
        </>
    )

}