/**
 * @flow
 * @relayHash f091ca52eccbf5254cde90e8c8711441
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CountryCreateInput = {|
  name: string
|};
export type CreateCountryMutationVariables = {|
  data: CountryCreateInput
|};
export type CreateCountryMutationResponse = {|
  +createCountry: {|
    +id: string
  |}
|};
export type CreateCountryMutation = {|
  variables: CreateCountryMutationVariables,
  response: CreateCountryMutationResponse,
|};
*/


/*
mutation CreateCountryMutation(
  $data: CountryCreateInput!
) {
  createCountry(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "CountryCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createCountry",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "CountryModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateCountryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateCountryMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateCountryMutation",
    "id": null,
    "text": "mutation CreateCountryMutation(\n  $data: CountryCreateInput!\n) {\n  createCountry(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a0283d7d44f2fc9d681c0d092632a735';
module.exports = node;
