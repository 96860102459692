/**
 * @flow
 * @relayHash 76130b97bb0a2c03972046122782c4d1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getContactQueryVariables = {|
  id: string
|};
export type getContactQueryResponse = {|
  +getContact: {|
    +id: string,
    +name: string,
    +customerId: ?string,
    +professionId: ?string,
    +customer: ?{|
      +id: string,
      +name: string,
    |},
    +profession: ?{|
      +id: string,
      +name: string,
    |},
    +contactItems: ?$ReadOnlyArray<{|
      +id: string,
      +information: string,
      +contactMediumId: string,
      +contactMedium: {|
        +id: string,
        +name: string,
      |},
    |}>,
  |}
|};
export type getContactQuery = {|
  variables: getContactQueryVariables,
  response: getContactQueryResponse,
|};
*/


/*
query getContactQuery(
  $id: String!
) {
  getContact(id: $id) {
    id
    name
    customerId
    professionId
    customer {
      id
      name
    }
    profession {
      id
      name
    }
    contactItems {
      id
      information
      contactMediumId
      contactMedium {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getContact",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "ContactModel",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "customerId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "professionId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "customer",
        "storageKey": null,
        "args": null,
        "concreteType": "CustomerModel",
        "plural": false,
        "selections": (v3/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "profession",
        "storageKey": null,
        "args": null,
        "concreteType": "ProfessionModel",
        "plural": false,
        "selections": (v3/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contactItems",
        "storageKey": null,
        "args": null,
        "concreteType": "ContactItemModel",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "information",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "contactMediumId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contactMedium",
            "storageKey": null,
            "args": null,
            "concreteType": "ContactMediumModel",
            "plural": false,
            "selections": (v3/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getContactQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getContactQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getContactQuery",
    "id": null,
    "text": "query getContactQuery(\n  $id: String!\n) {\n  getContact(id: $id) {\n    id\n    name\n    customerId\n    professionId\n    customer {\n      id\n      name\n    }\n    profession {\n      id\n      name\n    }\n    contactItems {\n      id\n      information\n      contactMediumId\n      contactMedium {\n        id\n        name\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0744b9e4cea161aec970e423d28071d8';
module.exports = node;
