import React, { useState } from "react";



export default (props) => {


    const [searchTerm, setValue] = useState('');

    const handelChange = (event) => {
        setValue(event.target.value);
    }

    const submitSearch = () => {
        props.onSubmitSearch(searchTerm);
    }


    return (

        <input
            value={searchTerm}
            onChange={(event) => handelChange(event)}
            onKeyPress={event => {

                if (event.key === 'Enter' || searchTerm === '') {
                    submitSearch()
                }
            }}
            type="text" className="form-control form-control-sm" />

    )
}

