import React, { useState, useEffect } from "react";
import moment from '../../../helpers/moment-helper';



export default (props) => {

    const [invoices, setInvoices] = useState([]);
    const [selecetedInvoice, setSelecetedInvoice] = useState(null);
    const [appentedItems, setAppentedItems] = useState([]);


    /**
* 
*/
    useEffect(() => {
        setAppentedItems((props.appentedItems || []).map(item => item.invoiceId));
        setInvoices(props?.invoices ? props.invoices : [])
    }, [props.invoices, selecetedInvoice, props.appentedItems]);

    const handelClick = () => {
        if (selecetedInvoice) {
            props.handelAppendModel({
                invoiceId: selecetedInvoice.id,
                amount: Number.parseFloat(0).toFixed(3),
                gapAmount: Number.parseFloat(selecetedInvoice.resteToPayed).toFixed(3)
            })
        }
        setSelecetedInvoice(null);

    }

    const handelChange = (invoiceId) => {
        const selectedItem = (invoiceId)
            ? invoices.filter(item => item.id === invoiceId).shift()
            : null;
        setSelecetedInvoice(selectedItem);
    }


    return (
        <div className="row m-0 p-0" >

            <div className="form-group col-md-11 m-0 p-0">
                <div className="">

                    <select
                        name="invoiceId"
                        className="form-control"
                        onChange={(e) => handelChange(e.target.value)}
                        value={(selecetedInvoice) ? selecetedInvoice?.invoiceId : ''}
                    >
                        <option value=""></option>
                        {
                            invoices
                            .filter(item => appentedItems.includes(item.id) ? false : true)
                            .map(item => <option key={item.id} value={item.id}>  {moment(item.date).format('YYYY')}-{item.num}</option>)
                        }
                    </select>
                </div>

            </div>
            <div className="form-group col-md-1">
                
                    <button disabled={!selecetedInvoice} className="btn btn-light btn-block" onClick={(e) => { e.preventDefault(); handelClick(); }} >
                    <i className="material-icons">library_add</i>
                    </button>
                
            </div>

        </div>
    )
}

