import React, { Component } from "react";



class Header extends Component {
   

    render() {
        return (

            <div className="row">
                <div className="col-sm-2 mt-5 m-0 p-0 text-muted" style={{ textAlign: "center" }}>
                    Collaborateurs
                </div>
                <div className="col-sm-10 mt-5 m-0 p-0 row border-left border-secondary" style={{ textAlign: "center" }}>
                    {(this.props.items || []).map(
                        (item, index) => (
                            <div key={index} className="col-sm m-0 p-0 border-left" key={index} style={{ textAlign: "center" }}>
                                <small className="text-muted">{item.str}<br />{item.int}</small>
                            </div>
                        )
                    )}
                </div>
            </div>


        )
    }
}
Header.defaultProps = {
    items : []
};
export default Header;