import {SET_INFO_USER_LOGGED, REMOVE_INFO_USER_LOGGED} from './constants';

export const doSetInfoUserLogged = payload => ({
	type: SET_INFO_USER_LOGGED,
	payload : payload,
});

export const doRemoveUserInfoLogged = payload => ({
	type: REMOVE_INFO_USER_LOGGED,
	payload : payload,
});