import { graphql } from "react-relay";

const query = graphql`
  query getAllInvoiceQuery(
    $pagination: PaginationInput
    $sorts: [OrderInput!]
    $where: JSON
  ) {
    getAllInvoice(pagination: $pagination, sorts: $sorts, where: $where) {
      count
      items {
        id
        title
        description
        date
        num
        tax
        advancePayment
        postStamp
        discountType
        discountValue
        total
        totalTTC
        resteToPayed
        currencyId
        customerId
        quotationId
        createdAt
        updatedAt
        deletedAt
        currency {
          id
          code
        }
        customer {
          id
          name
        }
      }
    }
  }
`;

export default query;
