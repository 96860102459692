import React from 'react'
// import Dashboard from "views/Dashboard.jsx";
import Dashboard from "views/Dashboard/Dashboard";
import Icons from "views/Icons.jsx";
import Typography from "views/Typography.jsx";
import TableList from "views/Tables.jsx";
import Maps from "views/Map.jsx";
import UserPage from "views/User.jsx";
import UpgradeToPro from "views/Upgrade.jsx";
import Planning from "views/Planning/Planning.js";
import PurchasesInvoices from "views/Purchases-invoices/Purchases-invoices";
import PurchasesQuotations from "views/Purchases-quotations/Purchases-quotations";
import SalesInvoices from "views/Sales-invoices/Sales-invoices";
import SalesQuotations from "views/Sales-quotations/Sales-quotations";
import SalesSettlements from "views/Sales-settlements/Sales-settlements";

import ProjectTasksRevisions from "views/Projects-tasks-revisions/Projects-tasks-revisions";
import ProjectsVersions from "views/Projects-versions/Projects-versions";

import Projects from "views/Projects/Projects";
import ProjectDetails from "views/Project-details/Project-details";
import ProjectsPahses from "views/Projects-phases/Projects-phases";
import ProjectInvoice from "views/Project-invoice/Project-invoice";
import ProjectDashboard from "views/Project-dashboard/Project-dashboard";
import ProjectQuotation from "views/Project-quotation/Project-quotation";

import CustomerInvoice from "views/Customer-invoice/Customer-invoice";
import CustomerQuotation from "views/Customer-quotation/Customer-quotation";
import CustomerContact from "views/Customer-contact/Customer-contact";
import CustomerAddress from "views/Customer-address/Customer-address";
import CustomerProject from "views/Customer-project/Customer-project";
import CustomerDashboard from "views/Customer-dashboard/Customer-dashboard";
import CustomerCategory from "views/Customer-category/Customer-category";
import CustomerOrigin from "views/Customer-origin/Customer-origin";
import Customer from "views/Customer/Customer";
import CustomerDetails from "views/Customer-details/Customer-details";
import ContactMedium from "views/Contact-medium/Contact-medium";

import Country from "views/Country/Country";
import Tenant from "views/Tenant/Tenant";
import Governorate from "views/Governorate/Governorate";
import City from "views/City/City";

import CategoryTemplate from 'views/CategoryTemplate/CategoryTemplate';
import HtmlTemplate from 'views/HtmlTemplate';

import TasksStatus from "views/Tasks-status/Tasks-status";
import Profession from "views/Profession/Profession";


import SupplierCategory from "views/Supplier-category/Supplier-category";
import Supplier from 'views/Supplier/Supplier';

import Currency from "views/Currency/Currency";
import Unity from "views/Unity/Unity";

import Login from "views/Login/Login";
import { MdDashboard, MdSettings, MdLocalGasStation} from 'react-icons/md';
import { 
  FaCalendarAlt,
  FaRegFolderOpen,
  FaTasks,
  FaDonate,
  FaDolly,
  FaUserTie,
  FaPaste,
  FaRegWindowRestore,
  FaToolbox,
  FaRegObjectGroup,
  FaShapes,
  FaHtml5,
  FaCity,
  FaUserCog,
  FaBlenderPhone,
  FaUserTag,
  FaUsersCog,
  FaCashRegister,
  FaFileInvoiceDollar,
  FaFunnelDollar
 } from 'react-icons/fa';
import SupplierDashboard from 'views/Supplier-dashboard/Supplier-dashboard';
import SupplierContact from 'views/Supplier-contact/Supplier-contact';
import SupplierQuotation from 'views/Supplier-quotation/Supplier-quotation';
import SupplierInvoice from 'views/Supplier-invoice/Supplier-invoice';
import PaymentMode from 'views/Payment-mode/Payment-mode';
import Account from 'views/Account/Account';


var routes = [
  {
    path: "/login",
    navLink: "/login",
    name: "Authentification",
    icon: '',
    component: Login,
    layout: "/public"
  },
  {
    path: "/dashboard",
    navLink: "/dashboard",
    name: "Dashboard",
    icon: (<MdDashboard />),
    component: Dashboard,
    layout: "/private"
  },
  {
    path: "/planning",
    navLink: "/planning",
    name: "Planning",
    icon: (<FaCalendarAlt />),
    component: Planning,
    layout: "/private"
  },
  {
    path: "/sales/invoices",
    navLink: "/sales/invoices",
    name: "Les ventes",
    icon: (<FaDonate />),
    component: SalesInvoices,
    layout: "/private"
  },
  {
    path: "/sales/quotations",
    navLink: "/sales/quotations",
    name: "Les ventes",
    icon: (<FaCalendarAlt />),
    component: SalesQuotations,
    hidden: true,
    layout: "/private"
  },
  {
    path: "/sales/settlements",
    navLink: "/sales/settlements",
    name: "Les ventes",
    icon: (<FaCalendarAlt />),
    component: SalesSettlements,
    hidden: true,
    layout: "/private"
  },
  {
    path: "/purchases/invoices",
    navLink: "/purchases/invoices",
    name: "Les achats",
    icon: (<FaDolly />),
    component: PurchasesInvoices,
    layout: "/private"
  },
  {
    path: "/purchases/quotations",
    navLink: "/purchases/quotations",
    name: "Les achats",
    icon: (<FaDolly />),
    component: PurchasesQuotations,
    hidden: true,
    layout: "/private"
  },
  {
    path: "/clients",
    navLink: "/clients",
    name: "Les clients",
    icon: (<FaUserTie />),
    component: Customer,
    layout: "/private"
  },
  {
    path: "/suppliers",
    navLink: "/suppliers",
    name: "Les fournissuers",
    icon: (<FaUserTie />),
    component: Supplier,
    layout: "/private"
  },
  {
    path: "/supplier/details/:id/dashboard",
    navLink: "/supplier/details/:id/dashboard",
    name: "dashboard",
    icon: (<FaCalendarAlt />),
    component: SupplierDashboard,
    hidden: true,
    layout: "/private"
  },
  {
    path: "/supplier/details/:id/contact",
    navLink: "/supplier/details/:id/contact",
    name: "Les contacts",
    icon: (<FaCalendarAlt />),
    component: SupplierContact,
    hidden: true,
    layout: "/private"
  },
  {
    path: "/supplier/details/:id/quotation",
    navLink: "/supplier/details/:id/quotation",
    name: "Les devis",
    icon: (<FaCalendarAlt />),
    component: SupplierQuotation,
    hidden: true,
    layout: "/private"
  },
  {
    path: "/supplier/details/:id/invoice",
    navLink: "/supplier/details/:id/invoice",
    name: "Les facture",
    icon: (<FaCalendarAlt />),
    component: SupplierInvoice,
    hidden: true,
    layout: "/private"
  },
  {
    path: "/client/details/:id/dashboard",
    navLink: "/client/details/:id/dashboard",
    name: "Les factures",
    icon: (<FaCalendarAlt />),
    component: CustomerDashboard,
    hidden: true,
    layout: "/private"
  },
  {
    path: "/client/details/:id/invoice",
    navLink: "/client/details/:id/invoice",
    name: "Les factures",
    icon: (<FaCalendarAlt />),
    component: CustomerInvoice,
    hidden: true,
    layout: "/private"
  },
  {
    path: "/client/details/:id/quotation",
    navLink: "/client/details/:id/quotation",
    name: "Les devis",
    icon: (<FaCalendarAlt />),
    component: CustomerQuotation,
    hidden: true,
    layout: "/private"
  },
  {
    path: "/client/details/:id/contact",
    navLink: "/client/details/:id/contact",
    name: "Les contacts",
    icon: (<FaCalendarAlt />),
    component: CustomerContact,
    hidden: true,
    layout: "/private"
  },
  {
    path: "/client/details/:id/address",
    navLink: "/client/details/:id/address",
    name: "Les address",
    icon: (<FaCalendarAlt />),
    component: CustomerAddress,
    hidden: true,
    layout: "/private"
  },
  {
    path: "/client/details/:id/project",
    navLink: "/client/details/:id/project",
    name: "Les projets",
    icon: (<FaPaste />),
    component: CustomerProject,
    hidden: true,
    layout: "/private"
  },
  {
    path: "/client/details/:id?",
    navLink: "/client/details/",
    name: "Client",
    icon: (<FaCalendarAlt />),
    component: CustomerDetails,
    hidden: true,
    layout: "/private"
  },
 
  {
    path: "/projects",
    navLink: "/projects",
    name: "Les projets",
    icon: (<FaPaste />),
    component: Projects,
    layout: "/private"
  },
  {
    path: "/project/details/:id/dashboard",
    navLink: "/project/details/:id/dashboard",
    name: "Tableau de bord",
    icon: (<FaCalendarAlt />),
    component: ProjectDashboard,
    hidden: true,
    layout: "/private"
  },
  {
    path: "/project/details/:id/invoice",
    navLink: "/project/details/:id/invoice",
    name: "Les factures",
    icon: (<FaCalendarAlt />),
    component: ProjectInvoice,
    hidden: true,
    layout: "/private"
  },
  {
    path: "/project/details/:id/quotation",
    navLink: "/project/details/:id/quotation",
    name: "Les devis",
    icon: (<FaCalendarAlt />),
    component: ProjectQuotation,
    hidden: true,
    layout: "/private"
  },
  {
    path: "/project/details/:id?",
    navLink: "/project/details/:id?",
    name: "Projet",
    icon: (<FaCalendarAlt />),
    component: ProjectDetails,
    hidden: true,
    layout: "/private"
  },
  {
    name: "Paramètres",
    icon: (<MdSettings />),
    layout: "/private",
    children: [

      {
        name: "Application",
        icon: (<FaToolbox />),
        layout: "/private",
        children: [
          {
            path: "/settings/application/tenant",
            navLink: "/settings/application/tenant",
            name: "Les applications",
            icon: (<FaRegWindowRestore />),
            component: Tenant,
            layout: "/private"
          },
          {
            path: "/settings/application/tplDocs",
            navLink: "/settings/application/tplDocs",
            name: "Template Docs",
            icon: (<FaRegObjectGroup />),
            layout: "/private",
            children: [
              {
                path: "/settings/application/tplDocs/category",
                navLink: "/settings/application/tplDocs/category",
                name: "Les catégories",
                icon: (<FaShapes />),
                component: CategoryTemplate,
                layout: "/private"
              },
              {
                path: "/settings/application/tplDocs/template/:id?",
                navLink: "/settings/application/tplDocs/template/",
                name: "Les templates",
                icon: (<FaHtml5 />),
                component: HtmlTemplate,
                layout: "/private"
              }
            ]
          }
        ]
      },
      {
        name: "Général",
        icon: (<FaRegFolderOpen />),
        layout: "/private",
        children: [
          {
            path: "/settings/general/country",
            navLink: "/settings/general/country",
            name: "Pays",
            icon: (<FaCity />),
            component: Country,
            layout: "/private"
          },
          {
            path: "/settings/general/governorate",
            navLink: "/settings/general/governorate",
            name: "Gouvernorat",
            icon: (<FaCity />),
            component: Governorate,
            layout: "/private"
          },
          {
            path: "/settings/general/city",
            navLink: "/settings/general/city",
            name: "Ville",
            icon: (<FaCity />),
            component: City,
            layout: "/private"
          }
        ]
      },
      {
        name: "R.Humaine",
        icon: (<FaUserCog />),
        layout: "/private",
        children: [
          {
            path: "/settings/rh/contact-medium",
            navLink: "/settings/rh/contact-medium",
            name: "Moyen de contact",
            icon: (<FaBlenderPhone />),
            component: ContactMedium,
            layout: "/private"
          },
          {
            path: "/settings/rh/profession",
            navLink: "/settings/rh/profession",
            name: "Les Professions",
            icon: (<FaUserTag />),
            component: Profession,
            layout: "/private"
          }
        ]
      },
      {
        name: "Projets",
        icon: (<FaPaste />),
        layout: "/private",
        children: [
          {
            path: "/settings/projects/tasks-status",
            navLink: "/settings/projects/tasks-status",
            name: "États des tâches",
            icon: (<FaTasks />),
            component: TasksStatus,
            layout: "/private"
          },
          {
            path: "/settings/projects/phases",
            navLink: "/settings/projects/phases",
            name: "Phases des projets",
            icon: (<MdLocalGasStation />),
            component: ProjectsPahses,
            layout: "/private"
          },
        ]
      },
      {
        name: "Clients",
        icon: (<FaUserTie />),
        layout: "/private",
        children: [
          {
            path: "/settings/clients/categories",
            navLink: "/settings/clients/categories",
            name: "Catégories clients",
            icon: (<FaUsersCog />),
            component: CustomerCategory,
            layout: "/private"
          },
          {
            path: "/settings/clients/origines",
            navLink: "/settings/clients/origines",
            name: "Origines clients",
            icon: (<FaUserTag />),
            component: CustomerOrigin,
            layout: "/private"
          }
        ]
      },
      {
        name: "Fournisseur",
        icon: (<FaUserTie />),
        layout: "/private",
        children: [
          {
            path: "/settings/supplieres/categories",
            navLink: "/settings/supplieres/categories",
            name: "Catégories fournisseurs",
            icon: (<FaUsersCog />),
            component: SupplierCategory,
            layout: "/private"
          }
        ]
      },
      {
        name: "Comptabilité",
        icon: (<FaCashRegister />),
        layout: "/private",
        children: [
          {
            path: "/settings/accounting/currency",
            navLink: "/settings/accounting/currency",
            name: "Devise",
            icon: (<FaFileInvoiceDollar />),
            component: Currency,
            layout: "/private"
          },
          {
            path: "/settings/accounting/unity",
            navLink: "/settings/accounting/unity",
            name: "Les unités de vente",
            icon: (<FaFunnelDollar />),
            component: Unity,
            layout: "/private"
          },
          {
            path: "/settings/accounting/paymentmode",
            navLink: "/settings/accounting/paymentmode",
            name: "Modes de paiement",
            icon: (<FaFunnelDollar />),
            component: PaymentMode,
            layout: "/private"
          },
          {
            path: "/settings/accounting/account",
            navLink: "/settings/accounting/account",
            name: "Les comptes",
            icon: (<FaFunnelDollar />),
            component: Account,
            layout: "/private"
          }
        ]
      },
    ]
  }
];
export default routes;
