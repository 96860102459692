/**
 * @flow
 * @relayHash a3355dfe0f6904f22a2614d70dcc361e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProfessionCreateInput = {|
  name: string
|};
export type CreateProfessionMutationVariables = {|
  data: ProfessionCreateInput
|};
export type CreateProfessionMutationResponse = {|
  +createProfession: {|
    +id: string
  |}
|};
export type CreateProfessionMutation = {|
  variables: CreateProfessionMutationVariables,
  response: CreateProfessionMutationResponse,
|};
*/


/*
mutation CreateProfessionMutation(
  $data: ProfessionCreateInput!
) {
  createProfession(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "ProfessionCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createProfession",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "ProfessionModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateProfessionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateProfessionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateProfessionMutation",
    "id": null,
    "text": "mutation CreateProfessionMutation(\n  $data: ProfessionCreateInput!\n) {\n  createProfession(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f9545f58d1d1ed48a218d308761f49f0';
module.exports = node;
