
import React from "react";
import { NavLink } from "react-router-dom";

/** Material UI */
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {
    FaMoneyBill,
    FaMoneyCheckAlt,
    FaMoneyCheck,
} from 'react-icons/fa';


export default (props) => {

    

    return (
        <div >

            <Toolbar >
                <Typography variant="h5" id="Title">
                    Les achats
                </Typography>
            </Toolbar>

            <div className="row">

                <div className="col-md-12 p-10">
                    <p className="text-secondary"></p>
                </div>


                <div className="col-md-12 p-0">

                    <ul className="nav nav-tabs justify-content-end">

                        <li className="nav-item">
                            <NavLink
                                to={'/private/purchases/quotations'}
                                className="nav-link text-secondary">
                                <FaMoneyBill size={17} /> Les devis
            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={'/private/purchases/invoices'}
                                className="nav-link text-secondary">
                                <FaMoneyCheckAlt size={17} /> Les factures
            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={'/private/purchases/payments'}
                                className="nav-link text-secondary disabled">
                                <FaMoneyCheck size={17} /> Les reglements
            </NavLink>
                        </li>
                    </ul>

                </div>

                <div className="col-md-12 p-4">
                    {
                        React.cloneElement(props.children)
                    }

                </div>
            </div>
        </div>

    );
}