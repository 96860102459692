import React, { useState, useEffect } from "react";

/** Graphql */
import { fetchQuery } from 'react-relay';
import getAllSupplierCategoryQuery from '../../../graphql/supplier-category/queries/getAllSupplierCategoryQuery';
import UpdateSupplierMutation from '../../../graphql/supplier/mutations/UpdateSupplierMutation';
import environment from '../../../graphql/Environment';




import { useForm } from 'react-hook-form';
import { ButtonSave, ButtonCancel } from '../../../components/Buttons';




const Form = (props) => {


    const { defaultData, categories } = props;

    const {
        register,
        control,
        handleSubmit,
        errors
    } = useForm({
        mode: "onChange",
        defaultValues: defaultData
    });


    /**
* 
*/
    useEffect(() => { }, [register]);


    /**
     * 
     * @param {*} data 
     */
    const update = data => {
        UpdateSupplierMutation({ ...data }, (response) => {
            if (response.status === 'OK') {
                props.doClose({
                    ...response, msgSnackbar: {
                        message: 'C bon c fait',
                        variant: 'success',
                        open: true,
                    }
                });
            } else {
                console.log({ error: 'Erreur' });
            }

        });
    }



    /**
     * 
     * @param {*} data 
     */
    const onSubmit = data => update(data);

    return (


        <form onSubmit={handleSubmit(onSubmit)} className="row p-5" >
            <input type="hidden" name="id" ref={register({})} />
            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Catégorie:</label>
                <div className="">

                    <select
                        name="categoryId"
                        className={
                            errors.categoryId
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} >
                        <option value=""></option>
                        {
                            categories.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>

                    {errors.categoryId && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>


            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Libelle:</label>
                <div className="">
                    <input
                        type="text"
                        name="name"
                        className={
                            errors.name
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} />
                    {errors.name && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>

            <div className="form-group col-md-12 text-right mt-2">

                <ButtonCancel onClick={() => props.doClose()}></ButtonCancel>
                <ButtonSave type="submit"></ButtonSave>

            </div>
        </form>
    );
}


export default (props) => {

    /**
    * 
    */
    const [state, setState] = useState({
        dataLoaded: false,
        defaultData: {},
        categories: [],
    });


    useEffect(() => {
        loadData();
    }, []);



    /**
       * 
       */
    const loadData = () => {
        Promise.all([loadCategories()])
            .then(data => {
                const [{ getAllSupplierCategory }] = data;

                const defaultData = getDefaultData(props?.data);
                setState({
                    ...state,
                    dataLoaded: true,
                    categories: getAllSupplierCategory.items,
                    defaultData: defaultData
                });
            })
            .catch(error => {
                console.log('error :', error.message);
            });
    }







    /**
* 
*/
    const loadCategories = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllSupplierCategoryQuery, gqlQueryParam);

    }



    const getDefaultData = (data) => {

        return {
            id: data.id,
            name: data.name,
            categoryId: data.categoryId
        };

    }



    return (
        <>
            {
                state.dataLoaded ?
                    (<Form
                        defaultData={state.defaultData}
                        categories={state.categories}
                        {...props} />)
                    : '...Loading'
            }
        </>
    )

}