/**
 * @flow
 * @relayHash d699ec09b2bfc15d5e184d757aa02edf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SettlementCreateInput = {|
  date: any,
  ref?: ?string,
  amount: string,
  paymentModeId: string,
  accountId: string,
  currencyId: string,
  customerId: string,
  settlementItem: $ReadOnlyArray<SettlementItemCreateInput>,
|};
export type SettlementItemCreateInput = {|
  invoiceId?: ?string,
  amount: string,
  gapAmount?: ?string,
|};
export type CreateSettlementMutationVariables = {|
  data: SettlementCreateInput
|};
export type CreateSettlementMutationResponse = {|
  +createSettlement: {|
    +id: string
  |}
|};
export type CreateSettlementMutation = {|
  variables: CreateSettlementMutationVariables,
  response: CreateSettlementMutationResponse,
|};
*/


/*
mutation CreateSettlementMutation(
  $data: SettlementCreateInput!
) {
  createSettlement(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "SettlementCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createSettlement",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "SettlementModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateSettlementMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateSettlementMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateSettlementMutation",
    "id": null,
    "text": "mutation CreateSettlementMutation(\n  $data: SettlementCreateInput!\n) {\n  createSettlement(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6880c6bcfee6a8a74da78235f2d70d39';
module.exports = node;
