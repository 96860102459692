/**
 * @flow
 * @relayHash a27eebd5fe23331ffd8d4326a5ee6cfa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getInvoiceQueryVariables = {|
  id: string
|};
export type getInvoiceQueryResponse = {|
  +getInvoice: {|
    +id: string,
    +title: string,
    +description: ?string,
    +address: string,
    +date: any,
    +num: string,
    +tax: ?number,
    +advancePayment: ?number,
    +postStamp: ?number,
    +discountType: ?string,
    +discountValue: ?number,
    +total: number,
    +totalTTC: number,
    +resteToPayed: ?number,
    +billingAddressId: ?string,
    +currencyId: string,
    +customerId: string,
    +quotationId: ?string,
    +createdAt: any,
    +updatedAt: any,
    +deletedAt: ?any,
    +currency: ?{|
      +id: string,
      +name: string,
      +code: string,
    |},
    +customer: ?{|
      +id: string,
      +name: string,
    |},
    +invoiceItem: ?$ReadOnlyArray<{|
      +id: string,
      +projectId: ?string,
      +designation: string,
      +description: string,
      +quantity: number,
      +unityId: string,
      +discount: ?number,
      +unitPrice: number,
      +total: number,
      +unity: {|
        +id: string,
        +name: string,
        +code: string,
      |},
      +project: ?{|
        +id: string,
        +name: string,
      |},
    |}>,
  |}
|};
export type getInvoiceQuery = {|
  variables: getInvoiceQueryVariables,
  response: getInvoiceQueryResponse,
|};
*/


/*
query getInvoiceQuery(
  $id: String!
) {
  getInvoice(id: $id) {
    id
    title
    description
    address
    date
    num
    tax
    advancePayment
    postStamp
    discountType
    discountValue
    total
    totalTTC
    resteToPayed
    billingAddressId
    currencyId
    customerId
    quotationId
    createdAt
    updatedAt
    deletedAt
    currency {
      id
      name
      code
    }
    customer {
      id
      name
    }
    invoiceItem {
      id
      projectId
      designation
      description
      quantity
      unityId
      discount
      unitPrice
      total
      unity {
        id
        name
        code
      }
      project {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = [
  (v1/*: any*/),
  (v4/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "code",
    "args": null,
    "storageKey": null
  }
],
v6 = [
  (v1/*: any*/),
  (v4/*: any*/)
],
v7 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getInvoice",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "InvoiceModel",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "address",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "date",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "num",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "tax",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "advancePayment",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "postStamp",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "discountType",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "discountValue",
        "args": null,
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalTTC",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "resteToPayed",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "billingAddressId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "currencyId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "customerId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "quotationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "updatedAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currency",
        "storageKey": null,
        "args": null,
        "concreteType": "CurrencyModel",
        "plural": false,
        "selections": (v5/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "customer",
        "storageKey": null,
        "args": null,
        "concreteType": "CustomerModel",
        "plural": false,
        "selections": (v6/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "invoiceItem",
        "storageKey": null,
        "args": null,
        "concreteType": "InvoiceItemModel",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "projectId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "designation",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "quantity",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "unityId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "discount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "unitPrice",
            "args": null,
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "unity",
            "storageKey": null,
            "args": null,
            "concreteType": "UnityModel",
            "plural": false,
            "selections": (v5/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectModel",
            "plural": false,
            "selections": (v6/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getInvoiceQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v7/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getInvoiceQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v7/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getInvoiceQuery",
    "id": null,
    "text": "query getInvoiceQuery(\n  $id: String!\n) {\n  getInvoice(id: $id) {\n    id\n    title\n    description\n    address\n    date\n    num\n    tax\n    advancePayment\n    postStamp\n    discountType\n    discountValue\n    total\n    totalTTC\n    resteToPayed\n    billingAddressId\n    currencyId\n    customerId\n    quotationId\n    createdAt\n    updatedAt\n    deletedAt\n    currency {\n      id\n      name\n      code\n    }\n    customer {\n      id\n      name\n    }\n    invoiceItem {\n      id\n      projectId\n      designation\n      description\n      quantity\n      unityId\n      discount\n      unitPrice\n      total\n      unity {\n        id\n        name\n        code\n      }\n      project {\n        id\n        name\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ab85df7a63bdf122de3b71dd7ba70700';
module.exports = node;
