/**
 * @flow
 * @relayHash df0d375cb2ec8bf3af53c047ece021d9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaginationInput = {|
  limit: number,
  offset: number,
|};
export type OrderInput = {|
  modele?: ?string,
  field: string,
  direction: string,
|};
export type getAllSupplierContactQueryVariables = {|
  pagination?: ?PaginationInput,
  sorts?: ?$ReadOnlyArray<OrderInput>,
  where?: ?any,
|};
export type getAllSupplierContactQueryResponse = {|
  +getAllSupplierContact: {|
    +count: number,
    +items: $ReadOnlyArray<{|
      +id: string,
      +name: string,
      +supplierId: ?string,
      +professionId: ?string,
      +supplier: ?{|
        +id: string,
        +name: string,
      |},
      +profession: ?{|
        +id: string,
        +name: string,
      |},
    |}>,
  |}
|};
export type getAllSupplierContactQuery = {|
  variables: getAllSupplierContactQueryVariables,
  response: getAllSupplierContactQueryResponse,
|};
*/


/*
query getAllSupplierContactQuery(
  $pagination: PaginationInput
  $sorts: [OrderInput!]
  $where: JSON
) {
  getAllSupplierContact(pagination: $pagination, sorts: $sorts, where: $where) {
    count
    items {
      id
      name
      supplierId
      professionId
      supplier {
        id
        name
      }
      profession {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "pagination",
    "type": "PaginationInput",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sorts",
    "type": "[OrderInput!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "JSON",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getAllSupplierContact",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "pagination",
        "variableName": "pagination"
      },
      {
        "kind": "Variable",
        "name": "sorts",
        "variableName": "sorts"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "SupplierContactWithCountModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "count",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "items",
        "storageKey": null,
        "args": null,
        "concreteType": "SupplierContactModel",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "supplierId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "professionId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplier",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplierModel",
            "plural": false,
            "selections": (v3/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "profession",
            "storageKey": null,
            "args": null,
            "concreteType": "ProfessionModel",
            "plural": false,
            "selections": (v3/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getAllSupplierContactQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getAllSupplierContactQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getAllSupplierContactQuery",
    "id": null,
    "text": "query getAllSupplierContactQuery(\n  $pagination: PaginationInput\n  $sorts: [OrderInput!]\n  $where: JSON\n) {\n  getAllSupplierContact(pagination: $pagination, sorts: $sorts, where: $where) {\n    count\n    items {\n      id\n      name\n      supplierId\n      professionId\n      supplier {\n        id\n        name\n      }\n      profession {\n        id\n        name\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '589681d0b43d40297ff749a89b938b40';
module.exports = node;
