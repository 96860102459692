import React from "react";
/** MATERIAL */
import TablePagination from '@material-ui/core/TablePagination';

export default (props) => {
    return (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage={"Elements par page"}
            component="div"
            count={props.countTotal}
            rowsPerPage={props.itemsPerPage}
            page={props.currentPage}
            backIconButtonProps={{
                'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
                'aria-label': 'next page',
            }}
            labelDisplayedRows={({ from, to, count }) => (`${from}-${to === -1 ? count : to} / ${count}`)}
            onChangePage={props.onChangePage}
            onChangeRowsPerPage={props.onChangeRowsPerPage}
        />);
}