
import React, { useState, useEffect } from "react";
import { saveAs } from 'file-saver';
/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


/** GRAPHQL */
import { fetchQuery } from 'react-relay';
import getAllQuotationQuery from '../../graphql/quotation/queries/getAllQuotationQuery';
import getQuotationQuery from '../../graphql/quotation/queries/getQuotationQuery';
import environment from '../../graphql/Environment';
import { OPERATOR } from '../../graphql/constants';

import Pagination from '../../components/Pagination';
import ModalDialog from '../../components/ModalDialog';
import MsgSnackbar from '../../components/MsgSnackbar';

/** COMPONENTS */
import TableToolbar from '../TableToolbar'
import Update from './components/Update';
import Create from './components/Create';
import Delete from './components/Delete';
import BlocSearch from './components/BlocSearch';
import moment from '../../helpers/moment-helper';
import SortableFieldWrapper from '../../components/SortableFieldWrapper';
import { apiGeneratePdf } from '../../api';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  }
}));


const INIT_STATE_DIALOG = {
  openDialog: false,
  action: null,
  targetData: null,
};

const INIT_MSG_SNACKBAR = {
  message: '',
  variant: 'success',
  open: false,
};

const INIT_ORDER = {
  modele: null,
  field: 'date',
  direction: 'DESC'
};


export default (props) => {

  const classes = useStyles();
  const project = props?.project || null;
  const customer = props?.customer || null;
  const defaultFilter = props?.defaultFilter || {};

  const [state, setState] = useState({
    itemsPerPage: 10,
    currentPage: 0,
    countTotal: 0,
    searchTerm: '',
    data: [],
    order: INIT_ORDER,
    dialog: INIT_STATE_DIALOG,
    msgSnackbar: INIT_MSG_SNACKBAR
  });




  /**
   * 
   */
  useEffect(() => {
    loadData();
  }, [state.itemsPerPage, state.currentPage, state.searchTerm, state.order]);


  /**
   * 
   * @param {*} event 
   * @param {*} newPage 
   */
  const handleChangePage = (event, newPage) => {
    setState({ ...state, currentPage: newPage });
  };

  /**
   * 
   * @param {*} event 
   */
  const handleChangeRowsPerPage = event => {
    setState({ ...state, currentPage: 0, itemsPerPage: Number(event.target.value) });
  };

  /**
   * 
   * @param {*} action 
   * @param {*} data 
   */
  const openDialog = (action, data = null) => {
    setState({ ...state, dialog: { action: action, openDialog: true, targetData: data } });
  }


   /**
* 
*/
const getDataQuotation = (gqlQueryParam = {}) => {
  return fetchQuery(environment, getQuotationQuery, gqlQueryParam);
}

  const generatePdf = (item) => {
    getDataQuotation({id: item.id})
    .then(response => {
      const data = response.getQuotation;
      console.log({data});

      const params = {
        categoryCode: 'quotation',
        data: data
      }

      apiGeneratePdf(params)
      .then(response => {

        const blob = new Blob([response.data], {
          type: 'application/octet-stream'
        });

        saveAs(blob, 'devis-' + moment(item.data).format('YYYY') + '-' + item.num + '.pdf');

       // console.log({responseAPI});
      })

    })
  }

  /**
   * 
   * @param {*} event 
   */
  const handleCloseDialog = (event = null) => {
    if (event && event.status !== undefined && event.status === 'OK') {
      const msgSnackbar = event.msgSnackbar || {};
      loadData({ msgSnackbar });
    } else {
     setState({ ...state, dialog: { ...state.dialog, openDialog: false } });
    }
  }

  /**
   * 
   */
  const loadData = (propsState = {}) => {
    const gqlQueryParam = {
      pagination: {
        offset: state.currentPage,
        limit: state.itemsPerPage
      },
      sorts: [{ ...state.order }],
      where: (state.searchTerm !== '')
        ? {
          ...defaultFilter,
          [OPERATOR.or]: [
            { 'title': { [OPERATOR.substring]: state.searchTerm } },
            { 'description': { [OPERATOR.substring]: state.searchTerm } },
            { '$customer.name$': { [OPERATOR.substring]: state.searchTerm } }
          ]
        } : {...defaultFilter}
    };

    fetchQuery(environment, getAllQuotationQuery, gqlQueryParam)
      .then(data => {
        const { count, items } = data.getAllQuotation;
        setState({
          ...state,
          ...propsState,
          countTotal: count,
          data: items,
          dialog: { ...state.dialog, openDialog: false }
        });
      }).catch(error => {
        console.log('error :', error.message);
      });
  }



  const hideMsgSnackbar = () => {

    setState({
      ...state,
      msgSnackbar: INIT_MSG_SNACKBAR
    })
  }

  /**
   * 
   * @param {*} value 
   */
  const handelSubmitSearch = (value) => {
    setState({ ...state, currentPage: 0, searchTerm: value });
  }

  /**
   * 
   * @param {*} field 
   */
  const handelClickSort = (ordering) => {
    setState({ ...state, order: { ...state.order, ...ordering } });
  }
  /**
  * 
  */
  const DialogViwer = () => {

    const { action, targetData, openDialog } = state.dialog;
    const mapActions = {
      'ADD': { component: Create, title: 'ADD' },
      'UPDATE': { component: Update, title: 'Update' },
      'DELETE': { component: Delete, title: 'Delete' }
    };

    const Component = (Object.keys(mapActions).indexOf(action) >= 0)
      ? mapActions[action].component
      : null;
    const title = (Object.keys(mapActions).indexOf(action) >= 0)
      ? mapActions[action].title
      : '';


    return (
      <ModalDialog
        title={title}
        open={openDialog}
        onClose={(event) => handleCloseDialog(event)}
      >
        {Component && <Component
          data={targetData}
          doClose={(event) => handleCloseDialog(event)} />}
      </ModalDialog>
    )
  }




  return (




    <>

    <div className={classes.root}>
      <Paper >

      <TableToolbar 
          title='Les devis'
          countTotal={state.countTotal}
          handelSubmitSearch={handelSubmitSearch}
          handelAddClick={() => openDialog('ADD', { project, customer })}
          />
        <TableContainer>
          <Table  aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell><SortableFieldWrapper currentOrder={state.order} modele={null} field='num' onClick={(event) => handelClickSort(event)}>N°</SortableFieldWrapper></TableCell>
                <TableCell align="left"><SortableFieldWrapper currentOrder={state.order} modele={null} field='date' onClick={(event) => handelClickSort(event)}>Date</SortableFieldWrapper></TableCell>
                <TableCell align="left"><SortableFieldWrapper currentOrder={state.order} modele={null} field='title' onClick={(event) => handelClickSort(event)}>Titre</SortableFieldWrapper></TableCell>
                <TableCell align="left"><SortableFieldWrapper currentOrder={state.order} modele='customer' field='name' onClick={(event) => handelClickSort(event)}>Client</SortableFieldWrapper></TableCell>
                <TableCell align="left"><SortableFieldWrapper currentOrder={state.order} modele={null} field='total' onClick={(event) => handelClickSort(event)}>Prix HT</SortableFieldWrapper></TableCell>
                <TableCell align="left"><SortableFieldWrapper currentOrder={state.order} modele={null} field='totalTTC' onClick={(event) => handelClickSort(event)}>Prix TTC</SortableFieldWrapper></TableCell>
                <TableCell align="left"><SortableFieldWrapper currentOrder={state.order} modele={null} field='createdAt' onClick={(event) => handelClickSort(event)}>Date création</SortableFieldWrapper></TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { state.data.length > 0 &&  (state.data || []).map(item => (
                <TableRow key={item.id}>
                  <TableCell align="left">{item.num}</TableCell>
                  <TableCell align="left">{moment(item.date).format('DD-MM-YYYY')}</TableCell>
                  <TableCell align="left">{item.title}</TableCell>
                  <TableCell align="left">{item?.customer?.name}</TableCell>
                  <TableCell align="left">{Number.parseFloat(item.total).toFixed(3)}</TableCell>
                  <TableCell align="left">{Number.parseFloat(item.totalTTC).toFixed(3)}</TableCell>
                  <TableCell align="left">{moment(item.createdAt).format('DD-MM-YYYY / HH:mm:ss ')}</TableCell>
                  <TableCell align="right">

                    <IconButton aria-label="filter list" onClick={() => openDialog('DELETE', item)}>
                      <i className="material-icons">delete</i>
                    </IconButton>

                    <IconButton aria-label="filter list" onClick={() => openDialog('UPDATE', item)}>
                      <i className="material-icons">create</i>
                    </IconButton>

                    <IconButton aria-label="filter list" onClick={() => generatePdf(item)}>
                      <i className="material-icons">picture_as_pdf</i>
                    </IconButton>
                  </TableCell>
                </TableRow>

              ))}

            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          countTotal={state.countTotal}
          itemsPerPage={state.itemsPerPage}
          currentPage={state.currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />


      </Paper>
    </div>


    { DialogViwer() }
    <MsgSnackbar {...state.msgSnackbar} onClose={hideMsgSnackbar} />



  </>









  );

}

