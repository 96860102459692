import React, { Component } from "react";
import moment from 'moment';
import momentConfig from '../../../config/moment.config';
import '../assets/step.css';
moment.locale('fr', momentConfig);



class Step extends Component {
    constructor(props) {
        super(props)
        this.state = {};
        this.parentBounding = null;
        this.ref = React.createRef();
    }

    componentDidMount() {
        this.parentBounding = document.getElementById('CardBodyID').getBoundingClientRect();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.events !== this.props.events && this.props.runingManagerEvents === true) {
            this.doBound();
        }
    }


    doBound() {
        const { date, resource} = this.props;
        const boundingClientRect = this.ref.current.getBoundingClientRect();
        const boundDate =     { 
            x: (boundingClientRect.x - this.parentBounding.x), 
            y: (boundingClientRect.y - this.parentBounding.y),
            width : boundingClientRect.width 
        };

        this.props.refCallback(date, boundDate, resource);

    }

    render() {

        return (
            <div ref={this.ref}  className="step col-sm m-0 p-0 border-left" style={{ textAlign: "center" }}>

            </div>
        )
    }
}


Step.defaultProps = {
    onSelectDay: (event) => console.log(event)
};

export default Step;