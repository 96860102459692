/**
 * @flow
 * @relayHash dd7cd5d441b94320536f4ceac6ad3203
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getQuotationSupplierQueryVariables = {|
  id: string
|};
export type getQuotationSupplierQueryResponse = {|
  +getQuotationSupplier: {|
    +id: string,
    +title: string,
    +description: ?string,
    +address: string,
    +date: any,
    +num: string,
    +tax: ?number,
    +discountType: ?string,
    +discountValue: ?number,
    +total: number,
    +totalTTC: number,
    +currencyId: string,
    +supplierId: string,
    +createdAt: any,
    +updatedAt: any,
    +deletedAt: ?any,
    +currency: ?{|
      +id: string,
      +name: string,
      +code: string,
    |},
    +supplier: ?{|
      +id: string,
      +name: string,
    |},
    +quotationSupplierItem: ?$ReadOnlyArray<{|
      +id: string,
      +designation: string,
      +description: string,
      +quantity: number,
      +unityId: string,
      +discount: ?number,
      +unitPrice: number,
      +total: number,
      +unity: {|
        +id: string,
        +name: string,
        +code: string,
      |},
    |}>,
  |}
|};
export type getQuotationSupplierQuery = {|
  variables: getQuotationSupplierQueryVariables,
  response: getQuotationSupplierQueryResponse,
|};
*/


/*
query getQuotationSupplierQuery(
  $id: String!
) {
  getQuotationSupplier(id: $id) {
    id
    title
    description
    address
    date
    num
    tax
    discountType
    discountValue
    total
    totalTTC
    currencyId
    supplierId
    createdAt
    updatedAt
    deletedAt
    currency {
      id
      name
      code
    }
    supplier {
      id
      name
    }
    quotationSupplierItem {
      id
      designation
      description
      quantity
      unityId
      discount
      unitPrice
      total
      unity {
        id
        name
        code
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = [
  (v1/*: any*/),
  (v4/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "code",
    "args": null,
    "storageKey": null
  }
],
v6 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getQuotationSupplier",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "QuotationSupplierModel",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "address",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "date",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "num",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "tax",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "discountType",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "discountValue",
        "args": null,
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalTTC",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "currencyId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplierId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "updatedAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currency",
        "storageKey": null,
        "args": null,
        "concreteType": "CurrencyModel",
        "plural": false,
        "selections": (v5/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "supplier",
        "storageKey": null,
        "args": null,
        "concreteType": "SupplierModel",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "quotationSupplierItem",
        "storageKey": null,
        "args": null,
        "concreteType": "QuotationSupplierItemModel",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "designation",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "quantity",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "unityId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "discount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "unitPrice",
            "args": null,
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "unity",
            "storageKey": null,
            "args": null,
            "concreteType": "UnityModel",
            "plural": false,
            "selections": (v5/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getQuotationSupplierQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v6/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getQuotationSupplierQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v6/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getQuotationSupplierQuery",
    "id": null,
    "text": "query getQuotationSupplierQuery(\n  $id: String!\n) {\n  getQuotationSupplier(id: $id) {\n    id\n    title\n    description\n    address\n    date\n    num\n    tax\n    discountType\n    discountValue\n    total\n    totalTTC\n    currencyId\n    supplierId\n    createdAt\n    updatedAt\n    deletedAt\n    currency {\n      id\n      name\n      code\n    }\n    supplier {\n      id\n      name\n    }\n    quotationSupplierItem {\n      id\n      designation\n      description\n      quantity\n      unityId\n      discount\n      unitPrice\n      total\n      unity {\n        id\n        name\n        code\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bab6d2561837369a737c6e7bdb6eceec';
module.exports = node;
