import { SET_INFO_USER_LOGGED, REMOVE_INFO_USER_LOGGED } from './constants';


/**
 * 
 * @param {*} state 
 * @param {*} data 
 */
const setInfoUserLogged = (state, data) => {
	return ({
		...state,
		...data
	});
};

/**
 * 
 * @param {*} state 
 * @param {*} data 
 */
const removeInfoUserLogged = (state, data) => {
	return ({});
};





export default (state = {}, action) => {
	switch (action.type) {
		case SET_INFO_USER_LOGGED: {
			return setInfoUserLogged(state, action.payload);
		}
		case REMOVE_INFO_USER_LOGGED: {
			return removeInfoUserLogged(state, action.payload);
		}
		default: return state;
	}
}

