import {
    graphql
} from 'react-relay'


const query = graphql`
    query getAllCustomerCategoryQuery ($pagination: PaginationInput, $sorts: [OrderInput!], $where: JSON) {
        getAllCustomerCategory (pagination: $pagination, sorts: $sorts, where: $where) {
            count,
            items {
                id
                name
                createdAt
                updatedAt
            }
      }
    }
  `

  export default query;