import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${palette.divider}`,
    padding:12,
  },
  head: {
    backgroundColor: palette.primary.light,
    color: palette.primary.contrastText,
    padding:16,
  },
  body: {
    fontSize: 14,
  },
};