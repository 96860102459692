import React, { useState, useEffect } from "react";


/** Graphql */
import { fetchQuery } from 'react-relay';
import getAllPaymentModeQuery from '../../../graphql/payment-mode/queries/getAllPaymentModeQuery';
import getAllCurrencyQuery from '../../../graphql/currency/queries/getAllCurrencyQuery';
import getAllInvoiceQuery from '../../../graphql/invoice/queries/getAllInvoiceQuery';
import getAllCustomerQuery from '../../../graphql/customer/queries/getAllCustomerQuery';
import getAllAccountQuery from '../../../graphql/account/queries/getAllAccountQuery';
import CreateSettlementMutation from '../../../graphql/settlement/mutations/CreateSettlementMutation';
import environment from '../../../graphql/Environment';

import ProviderModelInvoices from './ProviderModelInvoices';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { ButtonSave, ButtonCancel } from '../../../components/Buttons';
import DeleteIcon from '@material-ui/icons/Delete';
import { DatePicker } from "@material-ui/pickers";
import moment from '../../../helpers/moment-helper';



const itemModel = {
    invoiceId: "",
    amount: "",
    gapAmount: 0
};

const Form = (props) => {


    const {
        defaultData,
        paymentModes,
        accounts,
        currencies,
        customers,
        getInvoices,
        disabledFields
    } = props;



    const {
        register,
        control,
        handleSubmit,
        watch, errors, clearError, triggerValidation, reset,
        getValues,
        setValue
    } = useForm({
        mode: "onChange",
        defaultValues: defaultData
    });


    const {
        fields,
        append,
        remove } = useFieldArray(
            {
                control,
                name: "settlementItem"
            }
        );


    const [selectedDate, handleDateChange] = useState(defaultData.date);
    const [invoices, setInvoices] = useState(props.invoices);
    const [sumAmountItems, setSumAmountItems] = useState(0);



    /**
* 
*/
    useEffect(() => {
        register({ name: "date" });
    }, [register, fields, sumAmountItems, remove]);


    /**
* 
*/
    useEffect(() => {
        register({ name: "date" });
    }, [sumAmountItems]);


    /**
* 
*/
    useEffect(() => {
        reset({ ...getValues({ nest: true }), settlementItem: [] });
    }, [invoices]);


    /**
     * 
     * @param {*} data 
     */
    const create = data => {
        CreateSettlementMutation({ ...data }, (response) => {
            if (response.status === 'OK') {
                props.doClose({
                    ...response, msgSnackbar: {
                        message: 'C bon c fait',
                        variant: 'success',
                        open: true,
                    }
                });
            } else {
                console.log({ error: 'Erreur' });
            }

        });
    }







    /**
     * 
     * @param {*} e 
     */
    const customerChanged = (customerId) => {

        getInvoices({ where: { customerId: customerId } })
            .then(data => {
                const { items } = data.getAllInvoice;
                setInvoices(items);
            }).catch(error => {
                console.log('error :', error.message);
            });
    }


    /**
     * 
     * @param {*} accumulator 
     * @param {*} currentValue 
     */
    const calculatorReuce = (accumulator, currentValue) => {
        return Number.parseFloat(accumulator) + Number.parseFloat(currentValue);
    }

    /**
     * 
     * @param {*} excludedIndexItem 
     */
    const checkSumAmountItems = async () => {

        // await triggerValidation(['settlementItem', 'amount']);
        const { settlementItem, amount } = getValues({ nest: true });
        const sumAmountItems = (settlementItem || []).map(item => item.amount).reduce(calculatorReuce, 0);
        setSumAmountItems(sumAmountItems);

    }



    const handelAppendModel = (invoiceModel) => {
        register({ name: 'settlementItem' });
        append(invoiceModel);
    }

    /**
     * 
     * @param {*} index 
     */
    const removeItem = (index) => {
        remove(index);
    }


    const getDataItem = (invoiceId) => {
        return (invoiceId)
            ? invoices.filter(item => item.id === invoiceId).shift()
            : null;
    }


    // console.log(getValues({nest:true}));
    /**
     * 
     * @param {*} data 
     */
    const onSubmit = data => create(data);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="row p-5" >





                <div className="form-group col-md-6">
                    <label className="col-form-label text-secondary">Client:</label>
                    <div className="">
                        <select
                            name="customerId"
                            disabled={disabledFields.indexOf('customerId') >= 0}
                            onChange={(e) => customerChanged(e.target.value)}
                            className={
                                errors.customerId
                                    ? "form-control is-invalid"
                                    : "form-control"}
                            ref={register({ required: true })} >
                            <option value=""></option>

                            {
                                customers.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                            }
                        </select>

                        {errors.customerId && (
                            <div className="invalid-feedback">This field is required</div>
                        )}
                    </div>

                </div>


                <div className="form-group col-md-6">
                    <label className="col-form-label text-secondary">Référence de transaction:</label>
                    <div className="">
                        <input
                            type="text"
                            name="ref"
                            className={
                                errors.ref
                                    ? "form-control is-invalid"
                                    : "form-control"}
                            ref={register({ required: true })} />
                        {errors.ref && <span className="invalid-feedback">This field is required</span>}
                    </div>

                </div>



                <div className="form-group col-md-4">
                    <label className="col-form-label text-secondary">Mode de paiement:</label>
                    <div className="">

                        <select
                            name="paymentModeId"
                            className={
                                errors.paymentModeId
                                    ? "form-control is-invalid"
                                    : "form-control"}
                            ref={register({ required: true })}>
                            <option value=""></option>
                            {
                                paymentModes.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                            }
                        </select>

                        {errors.paymentModeId && (
                            <div className="invalid-feedback">This field is required</div>
                        )}
                    </div>

                </div>


                <div className="form-group col-md-4">
                    <label className="col-form-label text-secondary">Compte:</label>
                    <div className="">

                        <select
                            name="accountId"
                            className={
                                errors.accountId
                                    ? "form-control is-invalid"
                                    : "form-control"}
                            ref={register({ required: true })}>
                            <option value=""></option>
                            {
                                accounts.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                            }
                        </select>

                        {errors.accountId && (
                            <div className="invalid-feedback">This field is required</div>
                        )}
                    </div>

                </div>


                <div className="form-group col-md-4">
                    <label className="col-form-label text-secondary">Date de règlement:</label>
                    <div className="">

                        <DatePicker
                            autoOk
                            InputProps={{
                                disableUnderline: true,
                            }}
                            variant="inline"
                            format="DD/MM/YYYY"
                            value={selectedDate}
                            onChange={(date) => {
                                setValue(`date`, date);
                                handleDateChange(date);
                            }}

                            className={
                                errors.date
                                    ? "is-invalid"
                                    : ""}
                            id="date"
                            name="date"
                            inputRef={register({ required: true })}

                        />
                        {errors.date && (
                            <div className="invalid-feedback">This field is required</div>
                        )}
                    </div>

                </div>



                <div className="form-group col-md-6">
                    <label className="col-form-label text-secondary">Montant reçu:</label>
                    <div className="">
                        <input
                            type="number"
                            step="0.001"
                            min="0"
                            name="amount"
                            onChange={async (e) => {
                                await triggerValidation('settlementItem')
                                //checkSumAmountItems();
                            }}
                            className={
                                errors.amount
                                    ? "form-control is-invalid"
                                    : "form-control"}
                            ref={register({
                                required: true,
                                validate: {
                                    positiveAmount: value => Number.parseFloat(value) > 0,
                                    upperThenSumItems: value => Number.parseFloat(value) >= Number.parseFloat(sumAmountItems)
                                }
                            })} />
                        {errors.amount && errors.amount.type === "required" && (
                            <div className="invalid-feedback">This field is required</div>
                        )}
                        {errors.amount && errors.amount.type === "positiveAmount" && (
                            <div className="invalid-feedback">Doit etre > 0 </div>
                        )}
                        {errors.amount && errors.amount.type === "upperThenSumItems" && (
                            <div className="invalid-feedback">Doit etre > somme des motants </div>
                        )}
                    </div>

                </div>


                <div className="form-group col-md-6">
                    <label className="col-form-label text-secondary">Devise:</label>
                    <div className="">

                        <select
                            name="currencyId"
                            className={
                                errors.currencyId
                                    ? "form-control is-invalid"
                                    : "form-control"}
                            ref={register({ required: true })} >
                            <option value=""></option>
                            {
                                currencies.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                            }
                        </select>

                        {errors.currencyId && (
                            <div className="invalid-feedback">This field is required</div>
                        )}
                    </div>

                </div>


                {invoices.length > 0 && <fieldset className="form-group col-md-12 border-top mt-3 mb-3 pb-1">
                    <legend className="col-form-label text-secondary p-1" style={{ width: 'auto' }}>Les Factures</legend>



                    <ProviderModelInvoices
                        invoices={invoices}
                        handelAppendModel={handelAppendModel}
                        appentedItems={fields}
                    />


                    <table className="table table-striped mt-1 p-0">
                        {fields.length > 0 && <thead className="thead-dark p-0">
                            <tr>
                                <th className=" text-capitalize">Facture N°</th>
                                <th className=" text-capitalize">Date</th>
                                <th className=" text-capitalize">Total TTC</th>
                                <th style={{ width: '20%' }} >Montant à régler</th>
                                <th style={{ width: '20%' }} >Reste à régler</th>
                                <th style={{ width: '4%' }} className="text-capitalize"></th>
                            </tr>
                        </thead>}
                        <tbody>
                            {(fields || []).map((item, index) => {
                                // console.log({ item });
                                const data = getDataItem(item.invoiceId);
                                return (
                                    <tr key={item.id}>

                                        <td>
                                            <input
                                                className="form-control"
                                                type="hidden"
                                                defaultValue={`${item.invoiceId}`}
                                                name={`settlementItem[${index}].invoiceId`}
                                                ref={register({ required: true })} />
                                            {moment(data?.date).format('YYYY')}-{data?.num}
                                        </td>

                                        <td>
                                            {moment(data?.date).format('DD-MM-YYYY')}
                                        </td>
                                        <td>
                                            {Number.parseFloat(data?.totalTTC).toFixed(3)} {data?.currency?.code}
                                        </td>



                                        <td>
                                            <input
                                                type="number"
                                                min="0"
                                                step="0.001"
                                                name={`settlementItem[${index}].amount`}
                                                defaultValue={`${item.amount}`}
                                                onChange={(e) => {
                                                    const gapAmount = Number.parseFloat(data.resteToPayed) - Number.parseFloat(e.target.value);
                                                    setValue(`settlementItem[${index}].gapAmount`, Number.parseFloat(gapAmount).toFixed(3));
                                                    checkSumAmountItems();
                                                }}
                                                className={
                                                    errors?.settlementItem && errors?.settlementItem[index]?.amount
                                                        ? "form-control is-invalid"
                                                        : "form-control"}
                                                ref={register({
                                                    validate: {
                                                        positiveInvoiceGap: value => Number.parseFloat(data.totalTTC) - Number.parseFloat(value) >= 0,
                                                        positiveTotalGap: value => {
                                                            // console.log(Number.parseFloat(getValues()?.amount) - Number.parseFloat(value) >= 0);
                                                            return Number.parseFloat(getValues()?.amount) - Number.parseFloat(value) >= 0
                                                        }
                                                    }
                                                })} />

                                            {errors?.settlementItem && errors?.settlementItem[index]?.amount?.type === "positiveInvoiceGap" && (
                                                <div className="invalid-feedback">Doit etre infer au somme du facture </div>
                                            )}
                                            {errors?.settlementItem && errors?.settlementItem[index]?.amount?.type === "positiveTotalGap" && (
                                                <div className="invalid-feedback">somme des motants doit etre infr au montant global</div>
                                            )}
                                        </td>


                                        <td>
                                            <input
                                                readOnly
                                                type="number"
                                                min="0"
                                                step="0.001"
                                                name={`settlementItem[${index}].gapAmount`}
                                                defaultValue={`${item.gapAmount}`}
                                                className={
                                                    errors?.settlementItem && errors?.settlementItem[index]?.gapAmount
                                                        ? "form-control is-invalid"
                                                        : "form-control"}
                                                ref={register({})} />
                                        </td>

                                        <td>
                                            <div className="">
                                                <button type="button" className="btn btn-sm btn-danger" onClick={() => removeItem(index)}>
                                                    <DeleteIcon />
                                                </button>
                                            </div>
                                        </td>


                                    </tr>

                                );
                            })}







                        </tbody>
                    </table>

                </fieldset>}

                <div>

                </div>


                <div className="form-group col-md-12 text-right mt-2">

                    <ButtonCancel onClick={() => props.doClose()}></ButtonCancel>
                    <ButtonSave type="submit"></ButtonSave>

                </div>
            </form>
        </>
    );
}


export default (props) => {



    const project = props?.data?.project || null;
    const customer = props?.data?.customer || null;


    /**
    * 
    */
    const [state, setState] = useState({
        dataLoaded: false,
        defaultData: {},
        paymentModes: [],
        accounts: [],
        currencies: [],
        invoices: [],
        customers: [],
        disabledFields: (() => {
            const ret = [];
            if (customer) { ret.push('customerId') }
            return ret;
        })()
    });


    useEffect(() => {
        loadData();
    }, []);



    /**
       * 
       */
    const loadData = () => {
        Promise.all([
            getPaymentModes(),
            getAccounts(),
            getCurrencies(),
            getCustomers()
        ])
            .then(data => {
                const [
                    { getAllPaymentMode },
                    { getAllAccount },
                    { getAllCurrency },
                    { getAllCustomer },

                ] = data;

                const defaultData = getDefaultData();
                setState({
                    ...state,
                    dataLoaded: true,
                    paymentModes: getAllPaymentMode.items,
                    currencies: getAllCurrency.items,
                    customers: getAllCustomer.items,
                    accounts: getAllAccount.items,
                    defaultData: defaultData
                });
            })
            .catch(error => {
                console.log('error :', error.message);
            });
    }



    /**
* 
*/
    const getPaymentModes = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllPaymentModeQuery, gqlQueryParam);

    }

    /**
    * 
    */
    const getCurrencies = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllCurrencyQuery, gqlQueryParam)
    }



    /**
  * 
  */
    const getAccounts = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllAccountQuery, gqlQueryParam)
    }


    /**
    * 
    */
    const getInvoices = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllInvoiceQuery, gqlQueryParam)
    }



    /**
      * 
      */
    const getCustomers = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllCustomerQuery, gqlQueryParam)
    }



    const getDefaultData = () => {

        return {

            ref: '',
            date: moment().toISOString(),
            amount: 0,
            paymentModeId: '',
            accountId: '',
            currencyId: '',
            customerId: customer ? customer?.id : null,
            settlementItem: []
        };

    }



    return (
        <>
            {
                state.dataLoaded ?
                    (<Form
                        defaultData={state.defaultData}
                        paymentModes={state.paymentModes}
                        currencies={state.currencies}
                        invoices={state.invoices}
                        customers={state.customers}
                        accounts={state.accounts}
                        getInvoices={getInvoices}
                        disabledFields={state.disabledFields}
                        {...props} />)
                    : '...Loading'
            }
        </>
    )

}