import React, { Component } from "react";
/** Icons */
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

class NavBtnGroup extends Component {
    

    render() {
        return (
            <div className="btn-group" role="group" aria-label="Basic example">
                <button type="button" onClick={this.props.prevPeriod} className="btn btn-sm btn-secondary"><ArrowLeftIcon /></button>
                <button type="button" style={{ width: "200px" }} className="btn btn-sm btn-secondary">{this.props.children}</button>
                <button type="button" onClick={this.props.nextPeriod} className="btn btn-sm btn-secondary"><ArrowRightIcon /></button>
            </div>
        )
    }
}
NavBtnGroup.defaultProps = {
    prevPeriod: (e) => console.log('prevPeriod'),
    nextPeriod:(e) => console.log('nextPeriod')
};
export default NavBtnGroup;