import React, { useState, useEffect } from "react";

/** Graphql */
import { fetchQuery } from 'react-relay';
import getAllUnityQuery from '../../../graphql/unity/queries/getAllUnityQuery';
import getAllCurrencyQuery from '../../../graphql/currency/queries/getAllCurrencyQuery';
import getAllSupplierQuery from '../../../graphql/supplier/queries/getAllSupplierQuery';
import CreateQuotationSupplierMutation from '../../../graphql/quotation-supplier/mutations/CreateQuotationSupplierMutation';
import environment from '../../../graphql/Environment';



import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { ButtonSave, ButtonCancel } from '../../../components/Buttons';
import DeleteIcon from '@material-ui/icons/Delete';
import { DatePicker } from "@material-ui/pickers";
import moment from '../../../helpers/moment-helper';



const itemModel = {
    designation: "",
    description: "",
    quantity: 0,
    unityId: null,
    discount: null,
    unitPrice: null,
    total: 0,
};

const Form = (props) => {


    const {
        defaultData,
        unities,
        currencies,
        discountTypes,
        disabledFields
    } = props;

    /**
     * 
     */
    const [quotationSupplierPrice, setQuotationSupplierPrice] = useState({
        total: 0,
        tax: 0,
        taxValue: 0,
        discountValue: 0,
        globalDiscount: 0,
        totalFinal: 0
    })
    const [selectedDate, handleDateChange] = useState(defaultData.date);
    const [suppliers, setSuppliers] = useState(props.suppliers);
    const [billingAddresses, setBillingAddresses] = useState(props.billingAddresses);


    const {
        register,
        control,
        handleSubmit,
        watch, errors,
        getValues,
        setValue
    } = useForm({
        mode: "onChange",
        defaultValues: defaultData
    });


    const {
        fields,
        append,
        remove } = useFieldArray(
            {
                control,
                name: "quotationSupplierItem"
            }
        );


    /**
* 
*/
    useEffect(() => {
        register({ name: "date" });
        recalculatItemsPrice();
    }, [register]);


    /**
     * 
     * @param {*} data 
     */
    const create = data => {
        CreateQuotationSupplierMutation({ ...data }, (response) => {
            if (response.status === 'OK') {
                props.doClose({
                    ...response, msgSnackbar: {
                        message: 'C bon c fait',
                        variant: 'success',
                        open: true,
                    }
                });
            } else {
                console.log({ error: 'Erreur' });
            }

        });
    }

    /**
     * 
     */
    const discountType = watch("discountType");

    /**
     * 
     */
    const recalculatItemsPrice = () => {
        const { quotationSupplierItem, discountType } = getValues({ nest: true });
        (quotationSupplierItem || []).map((item, index) => {
            const { quantity, unitPrice, discount } = item;
            const itemDiscountValue = (discount && discountType === 'BY_ITEM') ? discount : 0;
            const initTotPriceItem = Number(quantity) * Number(unitPrice);
            const discountValue = itemDiscountValue ? initTotPriceItem * itemDiscountValue / 100 : 0;
            const total = initTotPriceItem - discountValue;
            setValue(`quotationSupplierItem[${index}].total`, Number.parseFloat(total).toFixed(3));
            setValue(`quotationSupplierItem[${index}].discount`, itemDiscountValue);
        });

        calculatorTotalPrice();
    }

    /**
     * 
     * @param {*} accumulator 
     * @param {*} currentValue 
     */
    const calculatorReuce = (accumulator, currentValue) => {
        return Number(accumulator) + Number(currentValue);
    }

    /**
     * 
     * @param {*} excludedIndexItem 
     */
    const calculatorTotalPrice = (excludedIndexItem = null) => {

        const { quotationSupplierItem, tax, discountValue, discountType } = getValues({ nest: true });
        const globalDiscountValue = (discountValue && discountType === 'TOTAL') ? discountValue : 0;
        const initGlobalTotal = (excludedIndexItem !== null)
            ? (quotationSupplierItem || []).filter((item, index) => index !== excludedIndexItem).map(item => item.total).reduce(calculatorReuce, 0)
            : (quotationSupplierItem || []).map(item => item.total).reduce(calculatorReuce, 0);
        const discountGlobalValue = globalDiscountValue ? initGlobalTotal * globalDiscountValue / 100 : 0;
        const taxValue = tax ? initGlobalTotal * tax / 100 : 0;
        const globalTotal = Number(initGlobalTotal) + Number(taxValue) - Number(discountGlobalValue);

        setValue('total', Number.parseFloat(initGlobalTotal).toFixed(3));
        setValue('totalTTC', Number.parseFloat(globalTotal).toFixed(3));

        setQuotationSupplierPrice({
            total: Number.parseFloat(initGlobalTotal).toFixed(3),
            tax: tax,
            taxValue: Number.parseFloat(taxValue).toFixed(3),
            discountValue: discountValue,
            globalDiscount: discountGlobalValue,
            totalFinal: Number.parseFloat(globalTotal).toFixed(3)
        });
    }

    /**
     * 
     * @param {*} index 
     */
    const removeItem = (index) => {
        calculatorTotalPrice(index);
        remove(index);
    }








    /**
     * 
     * @param {*} data 
     */
    const onSubmit = data => create(data);

    return (


        <form onSubmit={handleSubmit(onSubmit)} className="row p-5" >
            <input type="hidden" name="total" ref={register({})} />
            <input type="hidden" name="totalTTC" ref={register({})} />


            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Num:</label>
                <div className="">
                    <input
                        type="text"
                        name="num"
                        className={
                            errors.num
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} />
                    {errors.num && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>

            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Fournisseur:</label>
                <div className="">
                    <select
                        name="supplierId"
                        disabled={disabledFields.indexOf('supplierId') >= 0}
                        className={
                            errors.supplierId
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} >
                        <option value=""></option>

                        {
                            suppliers.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>

                    {errors.supplierId && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>


            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Titre:</label>
                <div className="">
                    <input
                        type="text"
                        name="title"
                        className={
                            errors.title
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} />
                    {errors.title && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>


            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Description:</label>
                <div className="">
                    <textarea
                        type="text"
                        name="description"
                        className={
                            errors.description
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({})} ></textarea>
                    {errors.description && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>


            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Addresse:</label>
                <div className="">
                    <input
                        type="text"
                        name="address"
                        className={
                            errors.address
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} />
                    {errors.address && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>


            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Date:</label>
                <div className="">

                    <DatePicker
                        autoOk
                        InputProps={{
                            disableUnderline: true,
                        }}
                        variant="inline"
                        format="DD/MM/YYYY"
                        value={selectedDate}
                        onChange={(date) => {
                            setValue(`date`, date);
                            handleDateChange(date);
                        }}

                        className={
                            errors.date
                                ? "is-invalid"
                                : ""}
                        id="date"
                        name="date"
                        inputRef={register({ required: true })}

                    />
                    {errors.date && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>



            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">TVA:</label>
                <div className="">
                    <input
                        type="number"
                        min="0"
                        name="tax"
                        onChange={recalculatItemsPrice}
                        className={
                            errors.tax
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({})} />
                    {errors.tax && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>



            <div className={discountType === 'TOTAL' ? 'form-group col-md-3' : 'form-group col-md-6'}>
                <label className="col-form-label text-secondary">Type de remise:</label>
                <div className="">

                    <select
                        name="discountType"
                        onChange={recalculatItemsPrice}
                        className={
                            errors.discountType
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({})} >
                        <option value=""></option>
                        {
                            discountTypes.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>

                    {errors.discountType && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>


            {discountType === 'TOTAL' && <div className="form-group col-md-3">
                <label className="col-form-label text-secondary">Remise:</label>
                <div className="">
                    <input
                        type="number"
                        min="0"
                        name="discountValue"
                        onChange={calculatorTotalPrice}
                        className={
                            errors.discountValue
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({})} />
                    {errors.discountValue && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>}


            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Devise:</label>
                <div className="">

                    <select
                        name="currencyId"
                        className={
                            errors.currencyId
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} >
                        <option value=""></option>
                        {
                            currencies.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>

                    {errors.currencyId && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>


            <fieldset className="form-group col-md-12 border-top mt-3 mb-3 p-0">
                <legend className="col-form-label text-secondary p-1" style={{ width: 'auto' }}>Les elements</legend>

                <table className="table table-striped mt-3 p-0">
                    <thead className="thead-dark p-0">
                        <tr>
                            <th className=" text-capitalize">Désignation</th>
                            <th style={{ width: '10%' }} className="text-capitalize">Qte</th>
                            <th style={{ width: '12%' }} className="text-capitalize">Unité</th>
                            {discountType === 'BY_ITEM' && <th style={{ width: '7%' }} className="text-capitalize">Remise</th>}
                            <th style={{ width: '10%' }} className="text-capitalize">Px.Unitaire</th>
                            <th style={{ width: '15%' }} className="text-capitalize">total</th>
                            <th style={{ width: '4%' }} className="text-capitalize"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((item, index) => {
                            return (
                                <tr key={item.id}>
                                    <td>
                                        <input
                                            type="text"
                                            name={`quotationSupplierItem[${index}].designation`}
                                            className={
                                                errors?.quotationSupplierItem && errors?.quotationSupplierItem[index]?.designation
                                                    ? "form-control is-invalid"
                                                    : "form-control"}
                                            ref={register({ required: true })} />
                                    </td>

                                    <td>
                                        <input
                                            type="number"
                                            min="0"
                                            name={`quotationSupplierItem[${index}].quantity`}
                                            className={
                                                errors?.quotationSupplierItem && errors?.quotationSupplierItem[index]?.quantity
                                                    ? "form-control is-invalid"
                                                    : "form-control"}
                                            ref={register({ required: true })}
                                            onChange={recalculatItemsPrice} />
                                    </td>

                                    <td>
                                        <select
                                            name={`quotationSupplierItem[${index}].unityId`}
                                            className={
                                                errors?.quotationSupplierItem && errors?.quotationSupplierItem[index]?.unityId
                                                    ? "form-control is-invalid"
                                                    : "form-control"}
                                            ref={register({ required: true })} >
                                            <option value=""></option>
                                            {
                                                unities.map(item => <option key={item.id} value={item.id}>{item.code}</option>)
                                            }
                                        </select>
                                    </td>

                                    {discountType === 'BY_ITEM' &&
                                        <td>
                                            <input
                                                type="number"
                                                min="0"
                                                onChange={(e) => {
                                                    setValue(`quotationSupplierItem[${index}].discount`, e.target.value)
                                                    recalculatItemsPrice();
                                                }}
                                                name={`quotationSupplierItem[${index}].discount`}
                                                className={"form-control"}
                                                ref={register({})} />
                                        </td>
                                    }

                                    <td>
                                        <input
                                            type="number"
                                            min="0"
                                            onChange={recalculatItemsPrice}
                                            name={`quotationSupplierItem[${index}].unitPrice`}
                                            className={
                                                errors?.quotationSupplierItem && errors?.quotationSupplierItem[index]?.unitPrice
                                                    ? "form-control is-invalid"
                                                    : "form-control"}
                                            ref={register({ required: true })} />
                                    </td>


                                    <td>
                                        <input
                                            readOnly
                                            type="text"
                                            name={`quotationSupplierItem[${index}].total`}
                                            className={"form-control border-0"}
                                            ref={register({ total: true })} />
                                    </td>

                                    <td>
                                        <div className="">
                                            {fields.length > 1 && <button type="button" className="btn btn-sm btn-danger" onClick={() => removeItem(index)}>
                                                <DeleteIcon />
                                            </button>}
                                        </div>
                                    </td>


                                </tr>

                            );
                        })}



                        <tr className="table-light border-0 pb-0">
                            <td className="border-0 pl-0" colSpan={discountType === 'BY_ITEM' ? '3' : '2'}>
                                <button
                                    className="btn btn-sm btn-light mr-0"
                                    type="button"
                                    onClick={() => append(itemModel)}
                                >
                                    <i className="material-icons">library_add</i>
                                </button>
                            </td>
                            <td className="border-0 text-secondary text-capitalize text-right" colSpan="2"><b>Total:</b></td>
                            <td className="border-0 text-secondary text-capitalize pl-3"> {quotationSupplierPrice.total}</td>
                        </tr>
                        {quotationSupplierPrice.tax > 0 && (<tr className="table-light border-0">
                            <td className="border-0" colSpan={discountType === 'BY_ITEM' ? '3' : '2'}>
                            </td>
                            <td className="border-0 text-secondary text-capitalize text-right" colSpan="2"><b>TVA ({quotationSupplierPrice.tax} %):</b></td>
                            <td className="border-0 text-secondary text-capitalize pl-3"> {quotationSupplierPrice.taxValue}</td>
                        </tr>)}
                        {quotationSupplierPrice.globalDiscount > 0 && (<tr className="table-light border-0">
                            <td className="border-0" colSpan={discountType === 'BY_ITEM' ? '3' : '2'}>
                            </td>
                            <td className="border-0 text-secondary text-capitalize text-right" colSpan="2"><b>Remise ({quotationSupplierPrice.discountValue} %):</b></td>
                            <td className="border-0 text-secondary text-capitalize pl-3"> - {quotationSupplierPrice.globalDiscount}</td>
                        </tr>)}
                        <tr className="table-light border-0">
                            <td className="border-0" colSpan={discountType === 'BY_ITEM' ? '3' : '2'}>
                            </td>
                            <td className="border-0 text-secondary text-capitalize text-right" colSpan="2"><b>Total TTC :</b></td>
                            <td className="border-0 text-secondary text-capitalize pl-3"> {quotationSupplierPrice.totalFinal}</td>
                        </tr>

                    </tbody>
                </table>

            </fieldset>

            <div>

            </div>


            <div className="form-group col-md-12 text-right mt-2">

                <ButtonCancel onClick={() => props.doClose()}></ButtonCancel>
                <ButtonSave type="submit"></ButtonSave>

            </div>
        </form>
    );
}


export default (props) => {

    const supplier = props?.data?.supplier || null;


    /**
    * 
    */
    const [state, setState] = useState({
        dataLoaded: false,
        defaultData: {},
        unities: [],
        currencies: [],
        suppliers: [],
        discountTypes: [
            { id: 'BY_ITEM', name: 'Par Element' },
            { id: 'TOTAL', name: 'Sur Total' }
        ],
        disabledFields: (() => {
            const ret = [];
            if (supplier) { ret.push('supplierId') }
            return ret;
        })()
    });


    useEffect(() => {
        loadData();
    }, []);



    /**
       * 
       */
    const loadData = () => {
        Promise.all([
            getUnities(),
            getCurrencies(),
            getSuppliers({}),
        ])
            .then(data => {
                const [
                    { getAllUnity },
                    { getAllCurrency },
                    { getAllSupplier },
                ] = data;

                const defaultData = getDefaultData();
                setState({
                    ...state,
                    dataLoaded: true,
                    unities: getAllUnity.items,
                    currencies: getAllCurrency.items,
                    suppliers: getAllSupplier.items,
                    defaultData: defaultData
                });
            })
            .catch(error => {
                console.log('error :', error.message);
            });
    }


    /**
* 
*/
    const getUnities = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllUnityQuery, gqlQueryParam);

    }

    /**
    * 
    */
    const getCurrencies = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllCurrencyQuery, gqlQueryParam)
    }



    /**
      * 
      */
    const getSuppliers = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllSupplierQuery, gqlQueryParam)
    }



    const getDefaultData = (defaultAddress) => {

        return {
            num: '',
            title: 'Facture : ' + moment().format('DD/MM/YYYY').toString(),
            description: '',
            address: '',
            date: moment().toISOString(),
            tax: 19,
            discountType: '',
            discountValue: null,
            total: 0,
            currencyId: '',
            supplierId: supplier ? supplier?.id : null,
            quotationSupplierId: '',
            quotationSupplierItem: [itemModel]
        };

    }



    return (
        <>
            {
                state.dataLoaded ?
                    (<Form
                        defaultData={state.defaultData}
                        unities={state.unities}
                        currencies={state.currencies}
                        suppliers={state.suppliers}
                        discountTypes={state.discountTypes}
                        disabledFields={state.disabledFields}
                        {...props} />)
                    : '...Loading'
            }
        </>
    )

}