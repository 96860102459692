import React, { Component } from "react";

class ToglPeriodBtnGroup extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="btn-group" role="group" aria-label="Basic example">
            <button type="button" onClick={() => this.props.setPeriod('day')} className="btn btn-sm btn-default p-2">Jour</button>
            <button type="button" onClick={() => this.props.setPeriod('week')} className="btn btn-sm btn-default p-2">Semaine</button>
            <button type="button" onClick={() => this.props.setPeriod('month')} className="btn btn-sm btn-default p-2">Mois</button>
            <button type="button" onClick={() => this.props.setPeriod('year')} className="btn btn-sm btn-default p-2">Année</button>
          </div>
        )
    }
}
ToglPeriodBtnGroup.defaultProps = {
    setPeriod:(e) => console.log('period :', e)
};
export default ToglPeriodBtnGroup;