import {LOGIN, LOGOUT} from './constants';

export const doLogIn = payload => ({
	type: LOGIN,
	payload : payload,
});

export const doLogOut = payload => ({
	type: LOGOUT,
	payload : payload,
});