
import React, { useState, useEffect } from "react";

/** Material UI */
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
/** GRAPHQL */
import { fetchQuery } from 'react-relay';
import getProjectQuery from '../../graphql/project/queries/getProjectQuery';
import environment from '../../graphql/Environment';
import { NavLink } from "react-router-dom";
import {
    FaTrello,
    FaRegChartBar,
    FaMoneyBill,
    FaMoneyCheckAlt,
    FaMoneyCheck,
    FaHourglassHalf
} from 'react-icons/fa';



export default (props) => {
    const [state, setState] = useState({
        data: null
    });


    /**
     * 
     */
    useEffect(() => {

        if (state.data === null && props.match.params.id) {
            loadData(props.match.params.id);
        }

    });


    /**
     * 
     */
    const loadData = (id) => {

        fetchQuery(environment, getProjectQuery, { id })
            .then(data => {
                setState({
                    ...state,
                    data: data.getProject
                });
            }).catch(error => {
                console.log('error :', error.message);
            });
    }


    return (
        <div >


            <Toolbar>
                <Typography variant="h5" id="Title">
                    {state.data?.name}
                </Typography>
            </Toolbar>



            <div className="row">
                <div className="col-md-12 p-0">




                    <ul className="nav nav-tabs justify-content-end">
                        <li className="nav-item">
                            <NavLink
                                to={`/private/project/details/${state.data?.id}/dashboard`}
                                className="nav-link text-secondary">
                                <FaRegChartBar size={17} /> Tableau de board
                                                </NavLink>

                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="#pablo"
                                className="nav-link text-secondary disabled">
                                <FaTrello size={17} /> Activités
                                                </NavLink>

                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={`/private/project/details/${state.data?.id}/quotation`}
                                className="nav-link text-secondary">
                                <FaMoneyBill size={17} /> Devis
                                                </NavLink>

                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={`/private/project/details/${state.data?.id}/invoice`}
                                className="nav-link text-secondary">
                                <FaMoneyCheckAlt size={17} />  Factures
                                                </NavLink>

                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="#pablo"
                                className="nav-link text-secondary disabled">
                                <FaMoneyCheck size={17} /> Réglements
                                                </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="#pablo"
                                className="nav-link text-secondary disabled">
                                <FaHourglassHalf size={17} /> Historiques
                                                </NavLink>
                        </li>
                    </ul>

                </div>

                <div className="col-md-12 p-4">
                    {
                        props.children && React.cloneElement(props.children,
                            { project: state?.data, customer: state?.data?.customer })
                    }
                </div>
            </div>
        </div>
    );
}