/**
 * @flow
 * @relayHash a72b7eb7f07074533d30197fdd60400d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CustomerCategoryCreateInput = {|
  name: string
|};
export type CreateCustomerCategoryMutationVariables = {|
  data: CustomerCategoryCreateInput
|};
export type CreateCustomerCategoryMutationResponse = {|
  +createCustomerCategory: {|
    +id: string
  |}
|};
export type CreateCustomerCategoryMutation = {|
  variables: CreateCustomerCategoryMutationVariables,
  response: CreateCustomerCategoryMutationResponse,
|};
*/


/*
mutation CreateCustomerCategoryMutation(
  $data: CustomerCategoryCreateInput!
) {
  createCustomerCategory(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "CustomerCategoryCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createCustomerCategory",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "CustomerCategoryModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateCustomerCategoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateCustomerCategoryMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateCustomerCategoryMutation",
    "id": null,
    "text": "mutation CreateCustomerCategoryMutation(\n  $data: CustomerCategoryCreateInput!\n) {\n  createCustomerCategory(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ace41cc79c8bc35c9f148b27a1e5892e';
module.exports = node;
