
import React from "react";
/** Components */
import Quotation from '../../components/Quotation/Quotation';
import CustomerWrapper from '../../components/CustomerWrapper';


export default (props) => {
  const customerId = props.match.params.id;
  return (

    <CustomerWrapper {...props}>
      <Quotation customerId={customerId} defaultFilter={{customerId}} />
    </CustomerWrapper>

  );

}

