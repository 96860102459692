/**
 * @flow
 * @relayHash dfee04e1cbdc681151225e083edc3ca2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getProjectQueryVariables = {|
  id: string
|};
export type getProjectQueryResponse = {|
  +getProject: {|
    +id: string,
    +name: string,
    +customerId: ?string,
    +createdAt: any,
    +updatedAt: any,
    +customer: ?{|
      +id: string,
      +name: string,
    |},
  |}
|};
export type getProjectQuery = {|
  variables: getProjectQueryVariables,
  response: getProjectQueryResponse,
|};
*/


/*
query getProjectQuery(
  $id: String!
) {
  getProject(id: $id) {
    id
    name
    customerId
    createdAt
    updatedAt
    customer {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getProject",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "ProjectModel",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "customerId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "updatedAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "customer",
        "storageKey": null,
        "args": null,
        "concreteType": "CustomerModel",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getProjectQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getProjectQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getProjectQuery",
    "id": null,
    "text": "query getProjectQuery(\n  $id: String!\n) {\n  getProject(id: $id) {\n    id\n    name\n    customerId\n    createdAt\n    updatedAt\n    customer {\n      id\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd5d8518bd9207c648539d2e29b4b650d';
module.exports = node;
