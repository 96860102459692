import React, { useState, useEffect } from "react";

/** Graphql */
import { fetchQuery } from 'react-relay';
import getAllCountryQuery from '../../../graphql/country/queries/getAllCountryQuery';
import getAllGovernorateQuery from '../../../graphql/governorate/queries/getAllGovernorateQuery';
import getAllCityQuery from '../../../graphql/city/queries/getAllCityQuery';
import CreateBillingAddressMutation from '../../../graphql/billing-address/mutations/CreateBillingAddressMutation';
import environment from '../../../graphql/Environment';


import Switch from '@material-ui/core/Switch';

import { useForm } from 'react-hook-form';
import { ButtonSave, ButtonCancel } from '../../../components/Buttons';


const Form = (props) => {


    const { defaultData, countries, getGovernorates, getCities } = props;

    const {
        register,
        handleSubmit,
        errors,
        setValue
    } = useForm({
        mode: "onChange",
        defaultValues: defaultData
    });



    /**
* 
*/
    useEffect(() => { }, [register]);

    const [governorates, setGovernorates] = useState(props?.governorates || []);
    const [cities, setCities] = useState(props?.cities || []);
    const [isDefault, setIsDefault] = useState(defaultData?.isDefault || false);



   

    /**
     * 
     * @param {*} e 
     */
    const countryChanged = (countryId) => {
        setValue('governorateId', '');
        setValue('cityId', '');
        getGovernorates({ where: { 'countryId': countryId } })
            .then(data => {
                const { items } = data.getAllGovernorate;
                setGovernorates(items);
            }).catch(error => {
                console.log('error :', error.message);
            });
    }


    /**
     * 
     * @param {*} e 
     */
    const governorateChanged = (governorateId) => {
        setValue('cityId', '');
        getCities({ where: { 'governorateId': governorateId } })
            .then(data => {
                const { items } = data.getAllCity;
                setCities(items);
            }).catch(error => {
                console.log('error :', error.message);
            });
    }


    /**
     * 
     * @param {*} data 
     */
    const create = data => {
        CreateBillingAddressMutation({ ...data }, (response) => {
            if (response.status === 'OK') {
                props.doClose({
                    ...response, msgSnackbar: {
                        message: 'C bon c fait',
                        variant: 'success',
                        open: true,
                    }
                });
            } else {
                console.log({ error: 'Erreur' });
            }

        });
    }


    /**
     * 
     * @param {*} data 
     */
    const onSubmit = data => create(data);

    return (


        <form onSubmit={handleSubmit(onSubmit)} className="row p-5" >
            <input type="hidden" name="customerId" ref={register({})} />
            <div className="form-group col-md-4">
                <label className="col-form-label text-secondary">Pays:</label>
                <div className="">

                    <select
                        name="countryId"
                        onChange={(e) => countryChanged(e.target.value)}
                        className={
                            errors.countryId
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} >
                        <option value=""></option>
                        {
                            countries.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>

                    {errors.countryId && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>

            <div className="form-group col-md-4">
                <label className="col-form-label text-secondary">Gouvernorat:</label>
                <div className="">

                    <select
                        name="governorateId"
                        onChange={(e) => governorateChanged(e.target.value)}
                        className={
                            errors.governorateId
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} >
                        <option value=""></option>
                        {
                            governorates.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>

                    {errors.governorateId && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>



            <div className="form-group col-md-4">
                <label className="col-form-label text-secondary">Ville:</label>
                <div className="">

                    <select
                        name="cityId"
                        className={
                            errors.cityId
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} >
                        <option value=""></option>
                        {
                            cities.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>

                    {errors.cityId && (
                        <div className="invalid-feedback">This field is required</div>
                    )}
                </div>

            </div>

            <div className="form-group col-md-12">
                <label className="col-form-label text-secondary">Addresse:</label>
                <div className="">
                    <input
                        type="text"
                        name="address"
                        className={
                            errors.address
                                ? "form-control is-invalid"
                                : "form-control"}
                        ref={register({ required: true })} />
                    {errors.address && <span className="invalid-feedback">This field is required</span>}
                </div>

            </div>

            <div className="form-group col-md-6">
                <label className="col-form-label text-secondary">Par défaut:</label>
                <div className="">


                    <Switch
                        checked={isDefault}
                        onChange={(e) => { setValue('isDefault', e.target.checked); setIsDefault(e.target.checked)}}
                       
                        name="isDefault"
                        color="primary"
                        inputRef={register}
                    />


                </div>

            </div>


            <div className="form-group col-md-12 text-right mt-2">

                <ButtonCancel onClick={() => props.doClose()}></ButtonCancel>
                <ButtonSave type="submit"></ButtonSave>

            </div>
        </form>
    );
}


export default (props) => {

    const customer = props?.data?.customer || null;
    /**
    * 
    */
    const [state, setState] = useState({
        dataLoaded: false,
        defaultData: {},
        countries: [],
        governorates: [],
        cities: []
    });


    useEffect(() => {
        loadData();
    }, []);



    /**
       * 
       */
    const loadData = () => {
        Promise.all([getCountries()])
            .then(data => {
                const [{ getAllCountry }] = data;
                const defaultData = getDefaultData();
                setState({
                    ...state,
                    dataLoaded: true,
                    countries: getAllCountry.items,
                    defaultData: defaultData
                });
            })
            .catch(error => {
                console.log('error :', error.message);
            });
    }


    /**
* 
*/
    const getCountries = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllCountryQuery, gqlQueryParam);

    }


    /**
* 
*/
    const getGovernorates = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllGovernorateQuery, gqlQueryParam);

    }


    /**
* 
*/
    const getCities = (gqlQueryParam = {}) => {
        return fetchQuery(environment, getAllCityQuery, gqlQueryParam);

    }



    const getDefaultData = () => {

        return {
            customerId: customer?.id,
            address: '',
            isDefault: false,
            countryId: null,
            governorateId: null,
            cityId: null,
        };

    }



    return (
        <>
            {
                state.dataLoaded ?
                    (<Form
                        defaultData={state.defaultData}
                        countries={state.countries}
                        getGovernorates={getGovernorates}
                        getCities={getCities}
                        {...props} />)
                    : '...Loading'
            }
        </>
    )

}