/**
 * @flow
 * @relayHash 45867cd1e994632121f7e664efcfc965
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactMediumCreateInput = {|
  name: string
|};
export type CreateContactMediumMutationVariables = {|
  data: ContactMediumCreateInput
|};
export type CreateContactMediumMutationResponse = {|
  +createContactMedium: {|
    +id: string
  |}
|};
export type CreateContactMediumMutation = {|
  variables: CreateContactMediumMutationVariables,
  response: CreateContactMediumMutationResponse,
|};
*/


/*
mutation CreateContactMediumMutation(
  $data: ContactMediumCreateInput!
) {
  createContactMedium(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "ContactMediumCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createContactMedium",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "ContactMediumModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateContactMediumMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateContactMediumMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateContactMediumMutation",
    "id": null,
    "text": "mutation CreateContactMediumMutation(\n  $data: ContactMediumCreateInput!\n) {\n  createContactMedium(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8d4725024db54e8dd70a86bc59201e9c';
module.exports = node;
