/**
 * @flow
 * @relayHash 43faa1c05699573c903a1454af0647da
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TaskStatusCreateInput = {|
  name: string,
  color: string,
|};
export type CreateTaskStatusMutationVariables = {|
  data: TaskStatusCreateInput
|};
export type CreateTaskStatusMutationResponse = {|
  +createTaskStatus: {|
    +id: string
  |}
|};
export type CreateTaskStatusMutation = {|
  variables: CreateTaskStatusMutationVariables,
  response: CreateTaskStatusMutationResponse,
|};
*/


/*
mutation CreateTaskStatusMutation(
  $data: TaskStatusCreateInput!
) {
  createTaskStatus(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "TaskStatusCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createTaskStatus",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "TaskStatusModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateTaskStatusMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateTaskStatusMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateTaskStatusMutation",
    "id": null,
    "text": "mutation CreateTaskStatusMutation(\n  $data: TaskStatusCreateInput!\n) {\n  createTaskStatus(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd7d836e7624e5d2534cfadf2d7cb7342';
module.exports = node;
