
import React from "react";

/** Components */
import BillingAddress from '../../components/Billing-address/Billing-address';
import CustomerWrapper from '../../components/CustomerWrapper';



export default (props) => {
  const customerId = props.match.params.id;

  return (
    <CustomerWrapper {...props}>
      <BillingAddress customerId={customerId} defaultFilter={{customerId}} />
    </CustomerWrapper>
  );

}

