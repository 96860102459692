/**
 * @flow
 * @relayHash b0588c080465230e6df1f989476d364a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WhoamiQueryVariables = {||};
export type WhoamiQueryResponse = {|
  +whoami: {|
    +id: string,
    +firstName: string,
    +lastName: string,
    +email: string,
    +tenant: ?{|
      +id: string,
      +name: string,
    |},
  |}
|};
export type WhoamiQuery = {|
  variables: WhoamiQueryVariables,
  response: WhoamiQueryResponse,
|};
*/


/*
query WhoamiQuery {
  whoami {
    id
    firstName
    lastName
    email
    tenant {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "whoami",
    "storageKey": null,
    "args": null,
    "concreteType": "UserModel",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tenant",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantModel",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "WhoamiQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "WhoamiQuery",
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "WhoamiQuery",
    "id": null,
    "text": "query WhoamiQuery {\n  whoami {\n    id\n    firstName\n    lastName\n    email\n    tenant {\n      id\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eed95902171b5d771f751b605a7b0497';
module.exports = node;
