import {
    graphql
} from 'react-relay'


const query = graphql`
    query getAllCategoryTemplateQuery ($pagination: PaginationInput, $sorts: [OrderInput!], $where: JSON) {
        getAllCategoryTemplate (pagination: $pagination, sorts: $sorts, where: $where) {
            count,
            items {
                id
                name
                code
                createdAt
                updatedAt
            }
      }
    }
  `

  export default query;