import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  widthDrawer: {
    width: 600
  }
});

export default () => {

  const classes = useStyles();

 

  useEffect(() => {

  }, []);

 

  return (
    <>
      <h1>Tableau de bord</h1>
     fvfvfvfvfv

    </>
  );

}

