/**
 * @flow
 * @relayHash a1282bbf7ef354caa1af0548b8937e69
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CustomerCreateInput = {|
  name: string,
  categoryId: string,
  originId: string,
|};
export type CreateCustomerMutationVariables = {|
  data: CustomerCreateInput
|};
export type CreateCustomerMutationResponse = {|
  +createCustomer: {|
    +id: string
  |}
|};
export type CreateCustomerMutation = {|
  variables: CreateCustomerMutationVariables,
  response: CreateCustomerMutationResponse,
|};
*/


/*
mutation CreateCustomerMutation(
  $data: CustomerCreateInput!
) {
  createCustomer(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "CustomerCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createCustomer",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "CustomerModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateCustomerMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateCustomerMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateCustomerMutation",
    "id": null,
    "text": "mutation CreateCustomerMutation(\n  $data: CustomerCreateInput!\n) {\n  createCustomer(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cc1bf03d425b81d37866a9f77015bb34';
module.exports = node;
