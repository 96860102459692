
import React from "react";
import InvoiceSupplier from '../../components/InvoiceSupplier/InvoiceSupplier';

import PurchasesWrapper from '../../components/PurchasesWrapper';

export default (props) => {

    return (
        <PurchasesWrapper {...props}>
            <InvoiceSupplier />
        </PurchasesWrapper>
    );

}

