import {
  Environment,
  Network,
  RecordSource,
  Store,
} from 'relay-runtime';
import { GC_AUTH_TOKEN, GC_APP_ID } from '../constants';

function fetchQuery(
  operation,
  variables,
  cacheConfig,
  uploadables
) {
  const REACT_APP_GRAPHQL = process.env.REACT_APP_GRAPHQL || 'http://localhost:3100/graphql';

  return fetch(REACT_APP_GRAPHQL, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem(GC_AUTH_TOKEN)}`,
      'X-APP-ID': localStorage.getItem(GC_APP_ID)
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  }).then(response => {
    return response.json();
  });
}

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
});

export default environment;