/**
 * @flow
 * @relayHash f444d725342856a7408863cf30f6b9a7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaginationInput = {|
  limit: number,
  offset: number,
|};
export type OrderInput = {|
  modele?: ?string,
  field: string,
  direction: string,
|};
export type getAllBillingAddressQueryVariables = {|
  pagination?: ?PaginationInput,
  sorts?: ?$ReadOnlyArray<OrderInput>,
  where?: ?any,
|};
export type getAllBillingAddressQueryResponse = {|
  +getAllBillingAddress: {|
    +count: number,
    +items: $ReadOnlyArray<{|
      +id: string,
      +customerId: ?string,
      +address: string,
      +isDefault: boolean,
      +countryId: string,
      +governorateId: string,
      +cityId: string,
      +country: {|
        +id: string,
        +name: string,
      |},
      +governorate: {|
        +id: string,
        +name: string,
      |},
      +city: {|
        +id: string,
        +name: string,
      |},
      +createdAt: any,
      +updatedAt: any,
    |}>,
  |}
|};
export type getAllBillingAddressQuery = {|
  variables: getAllBillingAddressQueryVariables,
  response: getAllBillingAddressQueryResponse,
|};
*/


/*
query getAllBillingAddressQuery(
  $pagination: PaginationInput
  $sorts: [OrderInput!]
  $where: JSON
) {
  getAllBillingAddress(pagination: $pagination, sorts: $sorts, where: $where) {
    count
    items {
      id
      customerId
      address
      isDefault
      countryId
      governorateId
      cityId
      country {
        id
        name
      }
      governorate {
        id
        name
      }
      city {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "pagination",
    "type": "PaginationInput",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sorts",
    "type": "[OrderInput!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "JSON",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getAllBillingAddress",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "pagination",
        "variableName": "pagination"
      },
      {
        "kind": "Variable",
        "name": "sorts",
        "variableName": "sorts"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "BillingAddressWithCountModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "count",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "items",
        "storageKey": null,
        "args": null,
        "concreteType": "BillingAddressModel",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "customerId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "address",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isDefault",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "countryId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "governorateId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "cityId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "country",
            "storageKey": null,
            "args": null,
            "concreteType": "CountryModel",
            "plural": false,
            "selections": (v2/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "governorate",
            "storageKey": null,
            "args": null,
            "concreteType": "GovernorateModel",
            "plural": false,
            "selections": (v2/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "city",
            "storageKey": null,
            "args": null,
            "concreteType": "CityModel",
            "plural": false,
            "selections": (v2/*: any*/)
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getAllBillingAddressQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getAllBillingAddressQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getAllBillingAddressQuery",
    "id": null,
    "text": "query getAllBillingAddressQuery(\n  $pagination: PaginationInput\n  $sorts: [OrderInput!]\n  $where: JSON\n) {\n  getAllBillingAddress(pagination: $pagination, sorts: $sorts, where: $where) {\n    count\n    items {\n      id\n      customerId\n      address\n      isDefault\n      countryId\n      governorateId\n      cityId\n      country {\n        id\n        name\n      }\n      governorate {\n        id\n        name\n      }\n      city {\n        id\n        name\n      }\n      createdAt\n      updatedAt\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '66921b775d84e2775173f01a8ac3c65d';
module.exports = node;
