import React from "react";
import DeleteCustomerMutation from '../../../graphql/customer/mutations/DeleteCustomerMutation';
import { ButtonConfirmDelete, ButtonCancel } from '../../../components/Buttons';

export default (props) => {



    const remove = id => {
        DeleteCustomerMutation(id, (response) => {
            if (response.status === 'OK') {
                props.doClose({
                    ...response, msgSnackbar: {
                        message: 'C bon c fait',
                        variant: 'success',
                        open: true,
                    }
                });
            } else {
                console.log({ error: 'Erreur' });
            }

        });
    }

    return (

        <div className="form-group row">
            <div className="col-md-12">
                <p>Êtes-vous sûr de vouloir supprimer : {props.data.name} ?? </p>
            </div>
            <div className="col-md-12 text-right">
                <ButtonCancel onClick={() => props.doClose()}></ButtonCancel>
                <ButtonConfirmDelete onClick={() => remove(props.data.id)}></ButtonConfirmDelete>
            </div>
        </div>



    );
}

