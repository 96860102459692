/**
 * @flow
 * @relayHash 3125ea1406d800d006a48f7008df83a9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UnityCreateInput = {|
  name: string,
  code: string,
|};
export type CreateUnityMutationVariables = {|
  data: UnityCreateInput
|};
export type CreateUnityMutationResponse = {|
  +createUnity: {|
    +id: string
  |}
|};
export type CreateUnityMutation = {|
  variables: CreateUnityMutationVariables,
  response: CreateUnityMutationResponse,
|};
*/


/*
mutation CreateUnityMutation(
  $data: UnityCreateInput!
) {
  createUnity(data: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "UnityCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createUnity",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "UnityModel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateUnityMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateUnityMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateUnityMutation",
    "id": null,
    "text": "mutation CreateUnityMutation(\n  $data: UnityCreateInput!\n) {\n  createUnity(data: $data) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c11bb2642be5419a32f9f81aed770c8';
module.exports = node;
