
import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

/** Components */
import CustomerWrapper from '../../components/CustomerWrapper';

const useStyles = makeStyles({
  root: {
    minWidth: '100%',
  },
  title: {
    fontSize: 17,
  },
});


export default (props) => {
  const customerId = props.match.params.id;
  const classes = useStyles();

  return (

    <CustomerWrapper {...props}>


      <Card className={classes.root}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            coming som
        </Typography>
        </CardContent>
      </Card>

    </CustomerWrapper>

  );

}

