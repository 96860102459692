import {
    graphql
} from 'react-relay'


const query = graphql`
    query getAllCustomerOriginQuery($pagination: PaginationInput, $sorts: [OrderInput!], $where: JSON) {
        getAllCustomerOrigin (pagination: $pagination, sorts: $sorts, where: $where){
            count,
            items{id, name, createdAt, updatedAt}
      }
    }
  `

  export default query;