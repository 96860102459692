import {
  graphql
} from 'react-relay'






const query = graphql`
    query getAllSettlementQuery($pagination: PaginationInput, $sorts: [OrderInput!], $where: JSON) {
        getAllSettlement (pagination: $pagination, sorts: $sorts, where: $where){
            count,
            items{
                  id
                  date
                  ref
                  amount
                  paymentModeId
                  accountId
                  currencyId
                  customerId
                  createdAt
                  updatedAt
                  deletedAt
                  customer {id, name}
                  paymentMode{id, name}
                  account{id, name}
                  currency{id, name}
                }
      }
    }
  `

export default query;