import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import { Route, Switch } from "react-router-dom";

/** Redux */
import { connect } from 'react-redux';
import { doSetInfoUserLogged } from '../redux/info-user-logged/actions';

/** Components */
import Navbar from "./components/Navbars/Navbar.js";
import Sidebar from "./components/Sidebar/Sidebar.jsx";

/** Graphql */
import { QueryRenderer } from 'react-relay';
import WhoamiQuery from '../graphql/commun/queries/WhoamiQuery';
import environment from '../graphql/Environment';


import routes from "routes.js";



const drawerWidth = 260;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#fff'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.sidebar,
    marginTop: 0
  },
  drawerHeader: {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    minHeight: `calc(100vh - 100px)`,
    fontSize: '13px'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  title: {
    flexGrow: 1,
  },
  titleDrawerHeader: {
    textAlign: 'center',
    width: '100%'
  },
}));

const Private = (props) => {

  const classes = useStyles();
  const { doSetInfoUserLogged } = props;

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };


  const routesMap = (routeItems) => {
    return routeItems.filter(route => (route.layout === '/private')).map((prop, key) => {
      return (prop.children !== undefined && prop.children.length > 0)
        ? routesMap(prop.children)
        : (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
    })
  }

  return (
    <QueryRenderer
      environment={environment}
      query={WhoamiQuery}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (props) {
          doSetInfoUserLogged(props.whoami);
          return (
            <div className={classes.root}>
              <CssBaseline />
              <Navbar whoami={props.whoami} toggleDrawer={toggleDrawer} />

              <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <div className={classes.drawerHeader}>
                  <Typography variant="h6" className={classes.titleDrawerHeader}>
                    <InboxIcon /> {props?.whoami?.tenant?.name}
                  </Typography>

                  <IconButton onClick={toggleDrawer}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                  </IconButton>
                </div>

                <Divider />
                <Sidebar
                  {...props}
                />
              </Drawer>

              <main
                className={clsx(classes.content, { [classes.contentShift]: open })}
              >
                <div className={classes.drawerHeader} />
                <Switch>
                  {routesMap(routes)}
                </Switch>
              </main>
            </div>
          );
        }
        return <div>Loading</div>;
      }}

    />

  );
}




const mapDispatchToProps = dispatch => ({
  doSetInfoUserLogged: data => dispatch(doSetInfoUserLogged(data)),
});

export default connect(
  null,
  mapDispatchToProps
)(Private);


